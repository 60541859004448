import React, { useState, useEffect, useContext } from 'react';
import { SelectInput } from 'react-admin';
import { range, sortBy, uniqBy } from 'lodash';
import { ThemeProvider } from '@material-ui/core/styles';
import { dropDownEllipsisStyle, modalFormStyle } from '../../Layout/styles';
import {
	modalFormTheme,
	customAutoCompleteSearchBarTheme,
} from '../../Layout/Theme.component';
import { Form } from 'react-final-form';
import {
	Box,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { UserProfileContext } from '../../App';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	useGetAttendanceYearQuery,
	useGetMyTeamViewInAttendanceQuery,
} from '../../generated/graphql';
import { monthOptions } from '../constant';
import dayjs from 'dayjs';
import { ExportReportIcon } from '../../assets/SvgIcons';
import LeaveBalanceExportDrawer from './LeaveBalanceExportDrawer.component';
import { leaveBalanceExportStyles } from './Style';

interface Year {
	id: number;
	name: number;
}
interface User {
	id: string;
	name: string;
	user_type: string;
	employee: {
		id: string;
	} | null;
}
interface Props {
	setSelectedDate: (startDate: string, endDate: string) => void;
	setUserId: (userId: string) => void;
	userId: string;
	setSelectedAttendanceMonth: (month: string) => void;
	selectedAttendanceMonth: string;
	setEmployeeId: (id: string) => void;
	isReportingManagerView: boolean;
}

const AttendanceMyTeamSearch = (props: Props) => {
	const {
		setUserId,
		setSelectedDate,
		userId,
		setSelectedAttendanceMonth,
		selectedAttendanceMonth,
		setEmployeeId,
		isReportingManagerView,
	} = props;
	const [attendanceYear, setAttendanceYear] = useState<Year[]>([]);
	const [selectedAttendanceYear, setSelectedAttendanceYear] = useState(
		dayjs().year()
	);
	const {
		id: Id,
		permissions,
		accountEmployeesDropdownOptions,
	} = useContext<any>(UserProfileContext);

	const [monthsOption, setMonthsOption] = useState(monthOptions);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const classes = modalFormStyle();
	const ellipsis = dropDownEllipsisStyle();
	const leaveStyles = leaveBalanceExportStyles();
	const { data: myTeam } = useGetMyTeamViewInAttendanceQuery({
		variables: {
			userId: Id,
		},
		fetchPolicy: 'network-only',
	});

	const { data: attendanceYears } = useGetAttendanceYearQuery();

	useEffect(() => {
		if (dayjs().year() === selectedAttendanceYear) {
			const monthOption = range(dayjs().month() + 1).map((value: number) => {
				return {
					id: value,
					name: dayjs().month(value).format('MMM'),
				};
			});
			setMonthsOption(monthOption);
		} else {
			setMonthsOption(monthOptions);
		}
	}, [selectedAttendanceYear]);

	useEffect(() => {
		if (!attendanceYears || !attendanceYears?.attendance_aggregate?.aggregate) {
			return;
		}
		const attendanceYear = attendanceYears?.attendance_aggregate?.aggregate;
		const maxYear = dayjs(attendanceYear?.max?.date).year() + 1;
		const minYear = dayjs(attendanceYear?.min?.date).year();
		const yearDropDownOptions = range(minYear, maxYear).map((value: number) => {
			return {
				id: value,
				name: value,
			};
		});
		setAttendanceYear(yearDropDownOptions);
	}, [attendanceYears]);

	const onSearchChange = (event: any, newValue: any) => {
		setEmployeeId('');
		if (newValue === null) {
			setUserId('');
			setEmployeeId('');
		}
		if (newValue?.user_type === 'employee') {
			setEmployeeId(newValue?.employee?.id);
		}
		setUserId(newValue?.id);
	};

	const calculateWithYear = (value: string) => {
		const startDate = `${value}-${selectedAttendanceMonth}-01`;
		const totalDays = dayjs(startDate).daysInMonth();
		const endDate = `${value}-${selectedAttendanceMonth}-${totalDays}`;
		setSelectedDate(startDate, endDate);
	};

	const calculateWithMonth = (value: string) => {
		const startDate = `${selectedAttendanceYear}-${value}-01`;
		const totalDays = dayjs(startDate).daysInMonth();
		const endDate = `${selectedAttendanceYear}-${value}-${totalDays}`;
		setSelectedDate(startDate, endDate);
	};

	const getUsersDropdown = () => {
		if (!myTeam?.user) {
			return [];
		}

		// Add sub account users only if the permissions are there
		if (permissions?.appPermissions?.ui?.viewSubAccountDetails) {
			const subAccountEmployees = accountEmployeesDropdownOptions
				.map((usr: User) => ({
					id: usr.id,
					full_name: usr.name,
					user_type: usr.user_type,
					employee: usr?.employee || null,
				}))
				.filter((usr: User) => usr.id !== Id);

			// Reportees
			const reportees = myTeam.user.map((usr) => ({
				id: usr.id,
				full_name: usr.full_name || '- -',
				user_type: usr?.user_type || '- -',
				employee: usr?.employee || null,
			}));

			const finalUserList = [...reportees, ...subAccountEmployees];
			return sortBy(
				uniqBy(finalUserList, (usr) => usr.id),
				(val) => val.full_name
			);
		}

		return myTeam?.user;
	};

	return (
		<>
			<Box display='flex' alignItems='center' padding='5px 14px 0px 20px'>
				<Box marginRight='15px' marginTop='-16px'>
					<Box className={classes.label}>Choose employee or consultant</Box>
					<Autocomplete
						onChange={onSearchChange}
						options={getUsersDropdown() || []}
						style={{ width: 250 }}
						renderOption={(option: any) => (
							<Tooltip title={`${option?.full_name}`} placement='right'>
								<Typography className={`${ellipsis.ellipsis}`}>
									{`${option?.full_name}`}
								</Typography>
							</Tooltip>
						)}
						getOptionLabel={(option: any) => `${option?.full_name}`}
						renderInput={(params) => (
							<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
								<TextField
									{...params}
									label=''
									placeholder='Search employee or consultant'
								/>
							</ThemeProvider>
						)}
					/>
				</Box>
				<Box>
					<Box>
						<Form onSubmit={() => {}}>
							{(pristine) => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.multipleInputContainer}>
											<Box className={classes.multipleInput}>
												<Box className={classes.label}>Choose Year</Box>
												<SelectInput
													source='year'
													label=''
													name='year'
													choices={attendanceYear}
													onChange={(event: any) => {
														setSelectedAttendanceYear(event?.target?.value);
														calculateWithYear(event?.target?.value);
													}}
													defaultValue={selectedAttendanceYear}
												/>
											</Box>
											<Box className={classes.multipleInput}>
												<Box className={classes.label}>Choose Month</Box>
												<SelectInput
													placeholder='Choose month'
													source='month'
													label=''
													choices={monthsOption}
													name='month'
													disabled={!userId}
													onChange={(event: any) => {
														const month = dayjs()
															.month(event?.target?.value)
															.format('MM');
														setSelectedAttendanceMonth(month);
														calculateWithMonth(month);
													}}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>

				{!isReportingManagerView && (
					<Box display={'flex'} marginLeft={'auto'} marginTop='24px'>
						<Box marginBottom='15px'>
							<Tooltip title='Leave balance export'>
								<IconButton
									className={leaveStyles.filterButton}
									onClick={() => setIsDrawerOpen(true)}
								>
									<ExportReportIcon className={leaveStyles.icon} />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				)}
			</Box>
			<LeaveBalanceExportDrawer
				open={isDrawerOpen}
				onClose={() => setIsDrawerOpen(false)}
			/>
		</>
	);
};

export default AttendanceMyTeamSearch;
