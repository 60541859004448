import React, { useState, useEffect, useCallback } from 'react';
import {
	Button,
	Box,
	Dialog,
	makeStyles,
	CircularProgress,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import { head, isEmpty, isEqual } from 'lodash';
import { useGetEmployeeOrgLeaveTypeQuery } from '../../../generated/graphql';
import { SkipNext } from '@material-ui/icons';
import { useNotify } from 'react-admin';
import dayjs from 'dayjs';
import {
	DEFAULT_VALUE_VALIDATION_INFO,
	LEAVE_POLICY_UPDATE_SUCCESS_MESSAGE,
	LEAVE_POLICY_UPDATE_WARNING_MESSAGE,
	validateNumberWith3pointDecimal,
} from '../constant';

const ratingStyle = makeStyles({
	ratingOuterContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	ratingContainer: {
		padding: '10px',
		marginBottom: '10px',
		display: 'flex',
		alignItems: 'center',
		width: '400px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		justifyContent: 'space-between',
	},
	ratingLabel: {
		fontSize: '14px',
		fontFamily: 'Manrope-medium',
		maxWidth: '150px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	ratingValueContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '150px',
		justifyContent: 'space-between',
	},
	ratingRangeInput: {
		width: '30px',
		border: '1px solid',
		padding: '0px 0px 0px 4px !important',
		borderRadius: '4px',
	},
	disabledRangeInput: {
		width: '28px',
		border: 'none',
		padding: '0px 0px 0px 4px !important',
	},
	disabledLabelInput: {
		width: '150px',
		border: 'none',
		padding: '0px 0px 0px 4px !important',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '50px',
	},
	loading: {
		fontSize: '17px',
		fontFamily: 'Manrope-medium',
		marginRight: '5px',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
		textTransform: 'uppercase',
		width: '350px',
	},
	noLeaveType: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	info: {
		fontFamily: 'Manrope',
		color: '#C50000',
		fontSize: '12px !important',
		marginLeft: '25px',
	},
	dialogContent: {
		display: 'flex',
		justifyContent: 'center',
	},
	buttonContainer: {
		marginRight: '20px',
		marginBottom: '20px',
	},
	startIcon: {
		height: '20px',
		width: '20px',
		color: '#4285F4',
	},
	endIcon: {
		height: '20px',
		width: '20px',
		color: '#FFFFFF',
	},
});
interface Props {
	open: boolean;
	onClose: () => void;
	leavePolicyId: string | null;
	onSubmit: (value: Leave[]) => void;
	onCancel: () => void;
}
interface Leave {
	id: string;
	isEditable: boolean;
	leaveType: string;
	defaultAmount: string;
	renewalDate: string | null;
}
const EmployeeLeaveUpdate = (props: Props) => {
	const { open, onClose, leavePolicyId, onSubmit, onCancel } = props;
	const [isSaved, setIsSaved] = useState(false);
	const [isModified, setIsModified] = useState(false);
	const notify = useNotify();
	const [orgLeaveTypeData, setOrgLeaveTypeData] = useState<Leave[]>([]);
	const styles = ratingStyle();
	const classes = modalFormStyle();
	const ellipsisStyles = ellipsisStyle();

	const handleClose = (reason: string) => {
		if (reason === 'backdropClick') return;
		setIsSaved(false);
		setOrgLeaveTypeData([]);
		onClose();
	};
	const handleSubmit = () => {
		const errorList = checkIfDefaultAmountHasError();
		if (errorList.includes(true)) {
			notify(DEFAULT_VALUE_VALIDATION_INFO, 'warning');
			return;
		}
		setIsSaved(true);
		onSubmit(orgLeaveTypeData);
		notify(LEAVE_POLICY_UPDATE_SUCCESS_MESSAGE);
		setIsSaved(false);
		setOrgLeaveTypeData([]);
		onClose();
	};
	const { data: orgLeaveTypes, loading } = useGetEmployeeOrgLeaveTypeQuery({
		variables: {
			leavePolicyId: leavePolicyId,
		},
		fetchPolicy: 'network-only',
	});
	const calculateRenewalDate = (
		is_leave_credit_on_start_of_month: boolean,
		leave_increments: number,
		interval: number
	) => {
		if (is_leave_credit_on_start_of_month) {
			const startOfNextMonth = dayjs()
				.add(1, 'month')
				.startOf('month')
				.format('YYYY-MM-DD');
			return startOfNextMonth;
		}
		if (leave_increments > 0) {
			return dayjs().add(interval, 'days').format('YYYY-MM-DD');
		}
		return null;
	};
	useEffect(() => {
		if (!orgLeaveTypes?.org_leave_types) {
			setOrgLeaveTypeData([]);
			return;
		}
		const leave = orgLeaveTypes?.org_leave_types?.map((item) => {
			return {
				id: item?.id,
				isEditable: false,
				leaveType: item?.leave_type,
				defaultAmount: item?.default_amount,
				renewalDate: calculateRenewalDate(
					item?.is_leave_credit_on_start_of_month,
					item?.leave_increments,
					item?.interval
				),
			};
		});
		setOrgLeaveTypeData(leave);
	}, [orgLeaveTypes]);

	const onDefaultAmountChange = (id: string, value: string) => {
		setOrgLeaveTypeData((prevData) =>
			prevData.map((leave) =>
				leave.id === id ? { ...leave, defaultAmount: value } : leave
			)
		);
	};

	const checkIfDefaultAmountModified = useCallback(() => {
		const defaultAmountList = orgLeaveTypes?.org_leave_types?.map((leaveType) =>
			Number(leaveType?.default_amount)
		);
		const currentDefaultAmount = orgLeaveTypeData?.map((leaveType) =>
			Number(leaveType?.defaultAmount)
		);
		if (isEqual(defaultAmountList, currentDefaultAmount)) {
			setIsModified(false);
			return;
		}
		setIsModified(true);
		return;
	}, [orgLeaveTypes, orgLeaveTypeData]);

	const checkIfDefaultAmountHasError = () => {
		const currentDefaultAmount = orgLeaveTypeData?.map((leaveType) => {
			const numericValue = parseFloat(leaveType?.defaultAmount);
			if (
				!validateNumberWith3pointDecimal.test(leaveType?.defaultAmount) ||
				numericValue < 0
			) {
				return true;
			} else {
				return false;
			}
		});
		return currentDefaultAmount || [];
	};

	useEffect(() => {
		checkIfDefaultAmountModified();
	}, [orgLeaveTypeData, checkIfDefaultAmountModified]);

	const handleCancel = () => {
		onClose();
		onCancel();
	};
	return (
		<Dialog
			open={open}
			onClose={(event, reason) => handleClose(reason)}
			maxWidth={'md'}
		>
			<Box minWidth='500px' minHeight='400px'>
				{loading ? (
					<>
						<Box className={styles.noData}>
							<Box className={styles.loading}>Loading</Box>
							<CircularProgress />
						</Box>
					</>
				) : (
					<>
						<>
							<DialogTitle>
								<Box className={classes.headerContainer}>
									<Box
										className={`${styles.heading} ${ellipsisStyles.ellipsis}`}
									>
										{`Leave Policy: ${
											head(orgLeaveTypes?.org_leave_group)?.label || '--'
										}`}
									</Box>
									<CloseIcon
										onClick={handleCancel}
										className={classes.closeIcon}
									/>
								</Box>
							</DialogTitle>
							<Box>
								{isEmpty(orgLeaveTypeData) ? (
									<Box display='flex' justifyContent='center'>
										<Box className={styles.noLeaveType}>
											No Leave types are available under this policy
										</Box>
										<Box minHeight='280px'></Box>
									</Box>
								) : (
									<>
										<Box width='450px' marginBottom='10px'>
											<p className={styles.info}>
												{LEAVE_POLICY_UPDATE_WARNING_MESSAGE}
											</p>
										</Box>
										<DialogContent className={styles.dialogContent}>
											<Box maxHeight='390px' minHeight='250px'>
												{orgLeaveTypeData &&
													orgLeaveTypeData?.map((value: any) => (
														<form>
															<div className={styles.ratingOuterContainer}>
																<div className={styles.ratingContainer}>
																	<div className={styles.ratingLabel}>
																		<input
																			value={value?.leaveType}
																			className={styles.disabledLabelInput}
																			disabled={true}
																		/>
																	</div>
																	<div className={styles.ratingValueContainer}>
																		<div>
																			<input
																				className={
																					value.isEditable
																						? styles.ratingRangeInput
																						: styles.disabledRangeInput
																				}
																				type='text'
																				value={value.defaultAmount}
																				onChange={(e) => {
																					onDefaultAmountChange(
																						value?.id,
																						e.target?.value
																					);
																				}}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													))}
											</Box>
										</DialogContent>
									</>
								)}
								<DialogActions className={styles.buttonContainer}>
									<Button
										onClick={() => handleCancel()}
										className={classes.cancelButton}
									>
										Cancel
									</Button>
									<Button
										className={
											!isModified || isEmpty(orgLeaveTypeData)
												? `${classes.disabledButton}`
												: `${classes.saveButton}`
										}
										disabled={!isModified || isEmpty(orgLeaveTypeData)}
										startIcon={
											isSaved && (
												<CircularProgress className={styles.startIcon} />
											)
										}
										onClick={handleSubmit}
									>
										Save
									</Button>
									<Button
										className={
											isModified || isEmpty(orgLeaveTypeData)
												? `${classes.disabledButton}`
												: `${classes.saveButton}`
										}
										disabled={isModified || isEmpty(orgLeaveTypeData)}
										endIcon={<SkipNext className={styles.endIcon} />}
										onClick={handleSubmit}
									>
										Skip
									</Button>
								</DialogActions>
							</Box>
						</>
					</>
				)}
			</Box>
		</Dialog>
	);
};

export default EmployeeLeaveUpdate;
