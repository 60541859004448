export const VISION_CREATED = 'Vision Created successfully';
export const VISION_UPDATED = 'Vision Updated successfully';

export const GOAL_CREATED = 'Goal Created successfully';
export const GOAL_UPDATED = 'Goal Updated successfully';

export const APPRAISAL_PERIOD_DATE_RANGE_ERROR =
	'Date range should within a appraisal period';

export const APPRAISAL_CREATED = 'Appraisal Created successfully';
export const APPRAISAL_UPDATED = 'Appraisal Updated successfully';
export const APPRAISAL_UPDATION_FAILED = 'Appraisal Updation failed';
export const APPRAISAL_CREATION_FAILED = 'Appraisal Creation failed';

export const REQUIRED_ERROR_TEXT = 'Required';
export const APPRAISAL_NAME_REQUIRED = 'Required';
export const END_DATE_ERROR = 'End date should be greater than start date';

export const EMPLOYEES_ADDED_SUCCESSFULLY = 'Employee(s) added Successfully';
export const EMPLOYEES_REMOVED_SUCCESSFULLY =
	'Employee(s) removed Successfully';

export const APPRAISAL_REVIEWER_SAME_ERROR =
	'User and Reviewer should not be a same person';
export const APPRAISAL_REVIEWER_UPDATED = 'Reviewer Updated Successfully';
export const USER_APPRAISAL_STATUS_UPDATED = 'Status Updated Successfully';

export const GOAL_MAPPING_SUCCESS_TEXT = 'Goals are mapped successfully';

export const EMPLOYEE_TYPE = [
	{
		id: 'all',
		name: 'All',
	},
	{
		id: 'employee',
		name: 'Employee',
	},
	{
		id: 'contract_employee',
		name: 'Contractor',
	},
];

export const DEFAULT_RATING_AND_SCORE_RANGE = [
	{
		id: 'outStanding',
		label: 'Outstanding',
		min: 4.1,
		max: 5.0,
		isEditable: false,
	},
	{
		id: 'excellent',
		label: 'Excellent',
		min: 3.1,
		max: 4.0,
		isEditable: false,
	},
	{
		id: 'satisfactory',
		label: 'Satisfactory',
		min: 2.1,
		max: 3.0,
		isEditable: false,
	},
	{
		id: 'needs_improvement',
		label: 'Needs Improvement',
		min: 1.1,
		max: 2.0,
		isEditable: false,
	},
	{
		id: 'unSatisfactory',
		label: 'UnSatisfactory',
		min: 0.1,
		max: 1.0,
		isEditable: false,
	},
];
export const DEFAULT_GOAL_RATING_RANGE = [
	{
		id: 'outStanding',
		label: 'Outstanding',
		value: 4,
		isEditable: false,
	},
	{
		id: 'exceeds',
		label: 'Exceeds',
		value: 3,
		isEditable: false,
	},
	{
		id: 'meets',
		label: 'Meets',
		value: 2,
		isEditable: false,
	},
	{
		id: 'doesNotMeet',
		label: 'Does Not Meet',
		value: 1,
		isEditable: false,
	},
];
export const INITIAL_APPRAISAL_FORM_DATE_ERROR_STATE = {
	endDate: {
		isError: false,
		errorText: '',
	},
	selfReviewEndDate: {
		isError: false,
		errorText: '',
	},
	reviewEndDate: {
		isError: false,
		errorText: '',
	},
	selfReviewDateRangeError: {
		isError: false,
		errorText: '',
	},
	reviewDateRangeError: {
		isError: false,
		errorText: '',
	},
};

export const ONLY_NUMBERS = /^([1-9]\d*(\.)\d*|0?(\.)\d*[1-9]\d*|[1-9]\d*)$/;

export const GOAL_UNIT_OPTIONS = [
	{
		id: 'number',
		name: 'Number',
	},
	{
		id: 'percentage',
		name: 'Percentage',
	},
];

export const GOAL_TARGET_PREFIX = [
	{
		id: '_eq',
		name: 'Equal to',
	},
	{
		id: '_gte',
		name: 'Greater than or equal to',
	},
	{
		id: '_lte',
		name: 'Less than or equal to',
	},
];

export const SELF_REVIEW_COMPLETED_STATUS = 'self_review_completed';

export const VALUE_WITH_IN_0_TO_100 = 'Value must be 0 to 100';

export const NUMBER_WITH_ONE_DECIMAL = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

export const RATING_UPDATED = 'Rating Updated';
export const SUBMITTED_SELF_EVALUATION =
	'Self Evaluation Submitted Successfully';
export const SUBMITTED_MANAGER_EVALUATION =
	'Manager Review Submitted Successfully';

export const PMS_USER_APPRAISAL_STATUS = {
	APPRAISAL_INITIATED: 'initiated',
	APPRAISAL_CLOSED: 'closed',
	APPRAISAL_MANAGER_REVIEW_COMPLETED: 'manger_review_completed',
	APPRAISAL_SELF_REVIEW_COMPLETED: 'self_review_completed',
	APPRAISAL_GOAL_SETTING_COMPLETED: 'goal_setting_completed',
};

export const PMS_APPRAISAL_STATUS = {
	APPRAISAL_INITIATED: 'initiated',
	APPRAISAL_CLOSED: 'closed',
	APPRAISAL_IN_PROGRESS: 'in_progress',
};

export const PMS_ACTIONS = {
	LOAD_GOALS: 'load_goals',
	UPDATE_GOAL_RATING_BY_MANAGER: 'update_goal_rating_by_manager',
	LOAD_GOALS_FOR_SELF_REVIEW: 'load_goals_for_self_review',
	UPDATE_GOAL_RATING_BY_USER: 'update_goal_rating_by_user',
	UPDATE_TARGET_ACHIEVED_BY_USER: 'update_target_achieved_by_user',
};

export const viewGoalsInitialState = {
	userDetails: {
		userName: '- -',
		experience: '- -',
		reviewer: '- -',
		jobLevel: '- -',
		department: '- -',
		status: '- -',
		userAppraisalStatus: '- -',
	},
	goals: [],
};

export const chartColorCodes = [
	'#EA4335',
	'#FFBB28',
	'#00C49F',
	'#FF8042',
	'#A728FF',
	'#FF85F9',
	'#00B11E',
	'#1600B2',
	'#8ECC00',
	'#838A88',
];

export const APPRAISAL_CYCLE_PATH = {
	name: 'Appraisal Cycle',
	path: '/admin-pms',
};

export const TEAM_PMS_PATH = {
	name: 'Team PMS',
	path: '/team-pms',
};

export const MY_PMS_PATH = {
	name: 'My PMS',
	path: '/my-pms',
};

export const PMS_DATE_FORMAT = 'MMM DD, YYYY';

export const COMMENT_UPDATED_TEXT = 'Comment updated successfully.';
export const COMMENT_AND_DOCUMENT_UPDATED_TEXT =
	'The comment and document have been updated successfully';

export const COMMENT_UPDATED_FAILED_TEXT = 'Comment updation failed.';

export const GOAL_REMOVE_SUCCESS_MSG = 'Goal removed successfully';

export const GOAL_ERROR_MSG = 'Failed to remove goal';

export const GOAL_IS_MANDATORY_MESSAGE = 'All goals should include comments.';

export const GOAL_IS_MANDATORY_INFO_MESSAGE =
	'Please click on the comment icon to add the comments';

export const GOAL_ASSIGNMENT_SUCCESS_MESSAGE = 'Goals are assigned...';

export const GOAL_ASSIGNMENT_CONFIRMATION =
	'Goal will be assigned to the employee and Self review will be initiated';

export const DOCUMENT_DELETED_SUCCESS = 'Document deleted successfully.';

export const DOCUMENT_DELETED_FAILED = 'Document deletion failed.';

export const UNSUPPORTED_FILE_ERROR = 'File format is not supported.';

export const BUTTON_TEXT_SAVING = 'Saving';

export const BUTTON_TEXT_SAVE = 'Save';

// TODO Need to remove and create from UI
export const DEFAULT_APPRAISAL_GOAL_RATING = [
	{
		name: 'Outstanding',
		value: 4,
	},
	{
		name: 'Exceeds',
		value: 3,
	},
	{
		name: 'Meets',
		value: 2,
	},
	{
		name: 'Does Not Meet',
		value: 1,
	},
];

export const DOCUMENT_UPLOAD_ACCEPTED_FILES =
	'.pdf,.doc,.docx,.xls,.csv,.xlsx,.odt,.ods,.ppt,.pptx,.txt,.eml';

export const RECIPIENT_SAVED_SUCCESS = 'Recipient saved successfully !';
export const EMAIL_RECIPIENT_NOT_EDITABLE =
	'Email recipients cannot be edited !';
export const EMAIL_RECIPIENT_SAVE_FAILED = 'Failed to save recipient!';
export const GOAL_CATEGORY_CREATED_MESSAGE =
	'Goal Category Created Successfully';
export const GOAL_CATEGORY_UPDATED_MESSAGE =
	'Goal Category updated Successfully';
export const GOAL_CATEGORY_DELETE_MESSAGE = 'Goal Category deleted Successfully';
export const GOAL_CATEGORY_DELETE_FAILED = 'Delete failed due to dependency';