import {
	Box,
	Button,
	IconButton,
	TextField,
	ThemeProvider,
	Tooltip,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { SearchIcon } from '../../../../assets/SvgIcons';
import {
	modalFormTheme,
	searchBarTheme,
} from '../../../../Layout/Theme.component';
import { ellipsisStyle, modalFormStyle } from '../../../../Layout/styles';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import MUIDataTable from 'mui-datatables';
import { SettingsTableTheme } from '../../../ProjectTask/dataGridThemes';

import CustomFieldForm from './CustomFieldForm.component';
import {
	useDeleteCustomFieldsMutation,
	useGetNonNullCustomFieldQuery,
	useGetSettingsCustomFieldDataQuery,
} from '../../../../generated/graphql';

import { SelectInput, useNotify, useRefresh } from 'react-admin';
import DeleteModal from '../../../../SharedComponents/DeleteModal.component';
import WarningModal from '../../../../SharedComponents/WarningModal.component';
import { WARNING_MODAL_MESSAGE } from '../../Project/constant';
import {
	CUSTOM_FIELD_DELETE_FAILED,
	CUSTOM_FIELD_DELETE_MESSAGE,
	CUSTOM_FIELD_DELETE_WARNING,
	NON_NULL_EDIT_FIELD_ERROR,
} from './constant';
import { capitalize } from 'lodash';

const useStyles = makeStyles(() => ({
	name: {
		marginTop: '15px',
		marginLeft: '-80px',
		marginRight: '40px',
	},
	value: {
		textAlign: 'left',
		font: 'normal normal medium Manrope',
		letterSpacing: '0px',
		color: ' #5C5C5C',
		opacity: 1,
		fontSize: '12px !important',
	},
	notes: {
		fontSize: '11px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '100px',
	},
	noData: {
		marginTop: '8px',
		fontSize: '12px',
	},
	listEditIconContainer: {
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	deleteIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	heading: {
		marginTop: '-24px',
		marginLeft: '5px',
	},
	table: {
		position: 'relative',
	},
}));

export interface CustomData {
	custom_field: CustomField[];
	lastIndexCustomField: LastIndexCustomField;
}

export interface CustomField {
	id: string;
	label: string;
	index: number;
	validation: any;
	options: any;
	type: string;
	is_active: boolean;
	sub_module_id: string;
	master_sub_module?: {
		label: string;
		value: string;
		id: string;
	};
}

export interface LastIndexCustomField {
	aggregate: Aggregate;
}

export interface Aggregate {
	max: Max;
}

export interface Max {
	index: number;
}

export const CustomFieldList = () => {
	const modalFormStyles = modalFormStyle();

	const style = useStyles();
	const ellipsisStyles = ellipsisStyle();

	const [isFormOpen, setIsFormOpen] = useState(false);
	const [fieldData, setFieldData] = useState<any>();
	const [row, setRow] = useState();
	const [isEdit, setIsEdit] = useState(false);
	const [deleteId, setDeleteId] = useState('');
	const [module, setModule] = useState('');
	const [index, setIndex] = useState('');
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [fieldEditable, setFieldEditable] = useState<number>();

	const [employeeModuleId, setEmployeeModuleId] = useState('');

	const [contractorModuleId, setContractorModuleId] = useState('');
	const [projectModuleId, setProjectModuleId] = useState('');

	const [selectedModuleId, setSelectedModuleId] = useState<string>('All');

	const [searchName, setSearchName] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState<number>(5);
	const [page, setPage] = useState<number>(0);
	const refresh = useRefresh();
	const notify = useNotify();

	const [deleteCustomFields] = useDeleteCustomFieldsMutation();

	const { refetch: refetchNonNullValue } = useGetNonNullCustomFieldQuery({
		variables: {
			employeeCustomField: {
				[`custom_field_${index}`]: { _is_null: false },
			},
			contractorCustomField: {
				[`custom_field_${index}`]: { _is_null: false },
			},
			projectCustomField: {
				[`custom_field_${index}`]: { _is_null: false },
			},
		},
		fetchPolicy: 'network-only',
	});

	const { data: customFieldSettings, refetch: refetchCustomFields } =
		useGetSettingsCustomFieldDataQuery({
			variables: {
				name: `%${searchName || ''}%`,
				moduleId: selectedModuleId === 'All' ? {} : { _eq: selectedModuleId },
			},
			fetchPolicy: 'network-only',
		});

	const deleteSettingsCustomField = () => {
		deleteCustomFields({
			variables: {
				id: deleteId,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(CUSTOM_FIELD_DELETE_MESSAGE);
					refetchCustomFields();
					refresh();

					setIsDeleteModalShown(false);
					setIsWarningModalShown(false);
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
				setIsDeleteModalShown(false);
				setIsWarningModalShown(false);
				notify(CUSTOM_FIELD_DELETE_FAILED, 'warning');
			});
	};

	useEffect(() => {
		if (!customFieldSettings) {
			return;
		}
		const data = customFieldSettings?.custom_field?.map((field) => ({
			id: field?.id,
			label: field?.label,
			index: field?.index,
			type: field?.type,
			options:
				field?.options?.map((e: { name: string }) => e?.name).join(',') || null,
			module: field?.master_sub_module?.label,
			sub_module_id: field?.sub_module_id,
		}));

		setFieldData(data);
	}, [customFieldSettings]);

	const getNextIndex = (fieldData: any[]): number => {
		const filteredData = fieldData?.filter((e) => e?.sub_module_id === module);

		const index = filteredData?.map((e) => e?.index) || [];
		if (index.length === 0) return 1;

		index.sort((a: number, b: number) => a - b);
		for (let i = 0; i < index.length; i++) {
			if (index[i] !== i + 1) {
				return i + 1;
			}
		}

		return index.length < 9 ? index.length + 1 : 10;
	};

	const nextIndex = getNextIndex(fieldData);

	const getNonNulData = async (rowData: { index: number; module: string }) => {
		const nonNullData = await refetchNonNullValue({
			employeeCustomField: {
				[`custom_field_${rowData?.index}`]: { _is_null: false },
			},
			contractorCustomField: {
				[`custom_field_${rowData?.index}`]: { _is_null: false },
			},
			projectCustomField: {
				[`custom_field_${rowData?.index}`]: { _is_null: false },
			},
		});

		if (
			rowData?.module === 'Employee' &&
			nonNullData?.data?.employee_aggregate?.aggregate?.count! > 0
		) {
			setIsWarningModalShown(true);
			setIsDeleteModalShown(false);

			return;
		} else if (
			rowData?.module === 'Contractor' &&
			nonNullData?.data?.contractor_aggregate?.aggregate?.count! > 0
		) {
			setIsWarningModalShown(true);
			setIsDeleteModalShown(false);

			return;
		} else if (
			rowData?.module === 'Project' &&
			nonNullData?.data?.project_aggregate?.aggregate?.count! > 0
		) {
			setIsWarningModalShown(true);
			setIsDeleteModalShown(false);

			return;
		} else {
			setIsDeleteModalShown(true);
			setIsWarningModalShown(false);
		}
	};

	const getNonNullEditableData = async (rowData: {
		index: number;
		module: string;
	}) => {
		try {
			const nonNullData = await refetchNonNullValue({
				employeeCustomField: {
					[`custom_field_${rowData?.index}`]: { _is_null: false },
				},
				contractorCustomField: {
					[`custom_field_${rowData?.index}`]: { _is_null: false },
				},
				projectCustomField: {
					[`custom_field_${rowData?.index}`]: { _is_null: false },
				},
			});

			const editableField =
				nonNullData?.data?.project_aggregate?.aggregate?.count;

			setFieldEditable(editableField!);
		} catch (error) {
			notify(NON_NULL_EDIT_FIELD_ERROR);

			setFieldEditable(0);
		}
	};
	const columns = [
		{
			name: 'label',
			label: 'NAME',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${style.notes} ${ellipsisStyles.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},

		{
			name: 'type',
			label: 'TYPE',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={capitalize(value) || ''} placement='left'>
							<Box className={`${style.notes} ${ellipsisStyles.ellipsis}`}>
								{capitalize(value) || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},

		{
			name: 'options',
			label: 'OPTIONS',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${style.notes} ${ellipsisStyles.ellipsis}`}>
								{value || '--'}
							</Box>
						</Tooltip>
					);
				},
			},
		},

		{
			name: 'module',
			label: 'MODULE',
			options: {
				sort: false,
				filter: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value || ''} placement='left'>
							<Box className={`${style.notes} ${ellipsisStyles.ellipsis}`}>
								{value || ''}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'action',
			label: 'ACTION',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const rowData = fieldData[tableMeta.rowIndex];

					const id = rowData?.id;

					const { module, ...rowValue } = rowData;

					return (
						<Box className={style.listEditIconContainer}>
							<IconButton
								className={style.listEditIconButton}
								onClick={() => {
									setRow(rowValue);
									setIsFormOpen(true);
									setIsEdit(true);
									getNonNullEditableData(rowData);
								}}
							>
								<EditIcon className={style.editIcon} />
							</IconButton>
							<IconButton
								style={{ marginLeft: '4px' }}
								className={style.deleteIconButton}
								onClick={async (e) => {
									setDeleteId(id);
									setIndex(rowData?.index);

									getNonNulData(rowData);
								}}
							>
								<DeleteIcon className={style.editIcon} />
							</IconButton>
						</Box>
					);
				},
			},
		},
	];

	const options: any = {
		filter: false,
		responsive: 'standard',
		print: false,
		searchAlwaysOpen: false,
		search: false,
		serverSide: false,
		viewColumns: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		selectableRowsHeader: false,
		selectableRowsHideCheckboxes: true,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [5, 10, 20, 30],
		download: false,

		onChangePage: (newPage: React.SetStateAction<number>) => setPage(newPage),
		onChangeRowsPerPage: (numberOfRows: React.SetStateAction<number>) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		pagination: true,
		page: page,
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},
	};

	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<Box display={'flex'}>
					<Box>
						<ThemeProvider theme={searchBarTheme}>
							<form onSubmit={(event) => event.preventDefault()}>
								<Box width='200px'>
									<TextField
										placeholder='Search Custom Field'
										label={''}
										fullWidth
										InputLabelProps={{ style: { fontSize: 0 } }}
										InputProps={{
											startAdornment: <SearchIcon />,
										}}
										onChange={(e) => setSearchName(e?.target?.value)}
									/>
								</Box>
							</form>
						</ThemeProvider>
					</Box>

					<Box marginLeft={'7px'}>
						<Box className={`${modalFormStyles.label} ${style.heading}`}>
							Modules
						</Box>
						<Form onSubmit={() => {}}>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<SelectInput
											source='sub_module_id'
											label=''
											choices={[
												{ id: 'All', name: 'All' },
												{ id: employeeModuleId, name: 'Employee' },
												{ id: contractorModuleId, name: 'Contractor' },
												{ id: projectModuleId, name: 'Project' },
											]}
											onChange={(event) => {
												if (!event) {
													return;
												}

												setSelectedModuleId(event?.target?.value);
											}}
											defaultValue={'All'}
										/>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
				<Box display='flex' justifyContent='flex-end'>
					<Button
						variant='contained'
						className={modalFormStyles.saveButton}
						onClick={() => {
							setIsFormOpen(true);
							setIsEdit(false);
						}}
					>
						Add Custom Fields
					</Button>
				</Box>
			</Box>

			<Box className={style.table}>
				<ThemeProvider theme={SettingsTableTheme}>
					<MUIDataTable
						title={'Custom Field'}
						data={fieldData}
						columns={columns}
						options={options}
					/>
				</ThemeProvider>
			</Box>
			<CustomFieldForm
				open={isFormOpen}
				onClose={() => {
					setIsFormOpen(false);
				}}
				refetch={refetchCustomFields}
				maxValue={nextIndex}
				initialValues={row}
				isEdit={isEdit}
				setModule={setModule}
				setEmployeeModuleId={setEmployeeModuleId}
				setContractorModuleId={setContractorModuleId}
				setProjectModuleId={setProjectModuleId}
				fieldEditable={fieldEditable!}
			/>

			{isDeleteModalShown && (
				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => {
						setIsDeleteModalShown(false);
						setIsWarningModalShown(false);
						setIndex('');
					}}
					confirmationMessage={CUSTOM_FIELD_DELETE_WARNING}
					onDelete={() => {
						deleteSettingsCustomField();
					}}
				/>
			)}

			<WarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
					setIsDeleteModalShown(false);

					setIndex('');
				}}
				warningMessage={WARNING_MODAL_MESSAGE}
			/>
		</>
	);
};

export default CustomFieldList;
