import React, { useContext, useState } from 'react';
import { List, ReferenceField, Datagrid, FunctionField } from 'react-admin';
import { useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	Box,
	TextField,
	Button,
	Tooltip,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { useQuery as useApolloQuery } from '@apollo/client';
import ProjectSkillForm from './ProjectSkillForm.component';
import { GET_PROJECT_SKILL } from '../gqlQueries';
import ProjectSkillEditPopOver from './ProjectSkillEditPopover.component';
import { UserProfileContext } from '../../../App';
import { SearchIcon } from '../../../assets/SvgIcons';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { ellipsisStyle } from '../../../Layout/styles';

const useStyles = makeStyles((theme) => ({
	skillName: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '70px',
	},
	addButton: {
		textTransform: 'none',
		minWidth: '140px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#FFFFFF',
		padding: '10px 16px',
		'&:hover': {
			backgroundColor: '#4285F4',
		},
	},
	tableList: {
		height: '442px',
		overflowY: 'auto',
		marginTop: '30px',
	},
}));

interface SkillMaster {
	name: string;
	id: string;
}

export interface SkillLevel {
	level: string;
	id: string;
}

export interface Skill {
	project_id: string;
	skill_id: string;
	skill_level_id: string;
	count: number;
	id: string;
	skill_master: SkillMaster;
	skill_level: SkillLevel;
	request_status_id?: string;
	notes?: string;
}
const ProjectSkill = () => {
	const [searchSkill, setSearchSkill] = useState('');
	const { permissions } = useContext<any>(UserProfileContext);
	const classes = useStyles();
	const { id }: { id: string } = useParams();
	const [isProjectSkillModalOpen, setIsProjectSkillModalOpen] = useState(false);

	const { data: projectSkill, refetch: refetchProjectSkillList } =
		useApolloQuery(GET_PROJECT_SKILL, {
			variables: {
				filter: {
					project_id: { _eq: id },
					skill_master: {
						name: {
							_ilike: `%${searchSkill}%`,
						},
					},
				},
			},
			fetchPolicy: 'network-only',
		});
	const styles = ellipsisStyle();

	return (
		<>
			<Box display={'flex'} justifyContent='space-between'>
				<ThemeProvider theme={searchBarTheme}>
					<form onSubmit={(event) => event.preventDefault()}>
						<Box width='250px'>
							<TextField
								placeholder='Search Demand'
								label={false}
								fullWidth
								InputLabelProps={{ style: { fontSize: 0 } }}
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
								onChange={(e) => setSearchSkill(e?.target?.value)}
							/>
						</Box>
					</form>
				</ThemeProvider>
				<Box>
					{permissions?.project_skill_requirement?.insert_permissions && (
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							className={classes.addButton}
							onClick={() => setIsProjectSkillModalOpen(true)}
						>
							Add Demand
						</Button>
					)}
				</Box>
			</Box>
			<Box className={classes.tableList}>
				<List
					component='div'
					title={' '}
					basePath={'/resource-planner'}
					bulkActionButtons={false}
					actions={false}
					resource={'project_skill_requirement'}
					filter={{
						id:
							projectSkill?.project_skill_requirement?.length > 0
								? projectSkill?.project_skill_requirement?.map(
										(value: any) => value?.id
								  )
								: [],
					}}
				>
					<Datagrid>
						<ReferenceField
							link={false}
							source='skill_id'
							reference='skill_master'
							label='Name'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Tooltip title={`${skill?.name || '- -'}`} placement='left'>
											<Typography
												className={`${classes.skillName} ${styles.ellipsis}`}
											>
												{`${skill?.name || '- -'}`}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
						<ReferenceField
							link={false}
							source='skill_level_id'
							reference='skill_level'
							label='Level'
						>
							<FunctionField
								render={(skill: any) => {
									return (
										<Tooltip
											title={`${skill?.level || '- -'}`}
											placement='left'
										>
											<Typography
												className={`${classes.skillName} ${styles.ellipsis}`}
											>
												{skill?.level}
											</Typography>
										</Tooltip>
									);
								}}
							/>
						</ReferenceField>
						<FunctionField
							label={'Count'}
							render={(skill: any) => {
								return <div className={classes.skillName}>{skill?.count}</div>;
							}}
						/>

						<ReferenceField
							link={false}
							source='request_status_id'
							reference='project_skill_request_status'
							label='Status'
						>
							<FunctionField
								render={(skill: any) => {
									return <div className={classes.skillName}>{skill.label}</div>;
								}}
							/>
						</ReferenceField>

						<FunctionField
							label={'Notes'}
							render={(skill: any) => {
								return (
									<Tooltip title={`${skill?.notes || '- -'}`} placement='left'>
										<Typography
											className={`${classes.skillName} ${styles.ellipsis}`}
										>
											{skill?.notes}
										</Typography>
									</Tooltip>
								);
							}}
						/>

						{permissions?.project_skill_requirement?.update_permissions && (
							<FunctionField
								render={(skill: any) => (
									<ProjectSkillEditPopOver
										projectSkillId={skill?.id}
										refetch={() => refetchProjectSkillList()}
									/>
								)}
							/>
						)}
					</Datagrid>
				</List>
				<ProjectSkillForm
					open={isProjectSkillModalOpen}
					onClose={() => setIsProjectSkillModalOpen(false)}
					refetch={() => refetchProjectSkillList()}
				/>
			</Box>
		</>
	);
};

export default ProjectSkill;
