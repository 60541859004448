import React, { useContext, useState, useEffect } from 'react';

import { useNotify, useRefresh } from 'react-admin';

import { UserProfileContext } from '../../../App';
import {
	Box,
	Typography,
	Button,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import {
	datagridStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import LeavePolicyForm from './LeavePolicyForm.component';
import { Loading } from 'ra-ui-materialui';
import StarIcon from '@material-ui/icons/Star';
import {
	useDeleteOrgLeavePolicyMutation,
	useGetLeaveGroupsQuery,
	useOrgLeaveTypesQuery,
} from '../../../generated/graphql';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import dayjs from 'dayjs';
import EmployeesMappingForm from './EmployeesMappingForm.component';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import EmployeeListModal from './EmployeeListModal.component';
import { tableListStyle } from '../settingsStyle';
import ProjectDeleteModal from '../../Projects/Details/projectDeleteModal';
import { LEAVE_POLICY_DELETE_CONFIRMATION_MESSAGE } from '../constant';

interface LeaveDeptMapping {
	leaveDeptMappingId: any;
	department_id: any;
}
interface LeaveDeptMappings {
	leaveDeptMapping?: LeaveDeptMapping[];
	department_id?: any[];
	leaveTypes: {
		__typename?: 'org_leave_types';
		id: any;
		leave_type: string;
		leave_increments?: any | null | undefined;
		interval?: any | null | undefined;
		default_amount: any;
		is_leave_credit_on_start_of_month: boolean
	}[];
}

export const LeavePolicyManagement = () => {
	const { orgId: organisationId, dateFormat } =
		useContext<any>(UserProfileContext);
	const [isLeavePolicyFormShown, setIsLeavePolicyFormShown] = useState(false);
	const [selectedLeavePolicy, setSelectedLeavePolicy] = useState('');
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [selectedLeaveDeptMappings, setSelectedLeaveDeptMappings] =
		useState<LeaveDeptMappings>();
	const [selectedLeavePolicyEmployees, setSelectedLeavePolicyEmployees] =
		useState<{ name: string; email: string }[]>([]);
	const [searchedEmployee, setSearchedEmployee] = useState('');
	const [isEmployeesListModalShown, setIsEmployeesListModalShown] =
		useState(false);
	const [isEmployeeMappingFormShown, setIsEmployeeMappingFormShown] =
		useState(false);
	const [leavePolicyName, setLeavePolicyName] = useState('');
	const [leaveTypes, setLeaveTypes] = useState('');
	const [leavePolicyLabel, setLeavePolicyLabel] = useState([]);
	const leavePolicyStyles = tableListStyle();
	const history = useHistory();
	const modalFormStyles = modalFormStyle();
	const ellipsisStyles = ellipsisStyle();
	const datagridStyles = datagridStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const {
		data: leavePolicies,
		loading,
		refetch: refetchLeaveGroups,
	} = useGetLeaveGroupsQuery({
		variables: {
			orgId: organisationId,
			employeeName: `%${searchedEmployee}%`,
		},
		fetchPolicy: 'network-only',
	});
	const { data: orgLeaveTypes } = useOrgLeaveTypesQuery({
		variables: { id: selectedLeavePolicy },
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!orgLeaveTypes) {
			return;
		}
		const leaveTypesId: any =
			orgLeaveTypes?.org_leave_group_by_pk?.org_leave_types?.map(
				(leave: any) => {
					return leave?.id;
				}
			);
		setLeaveTypes(leaveTypesId);
	}, [orgLeaveTypes]);

	useEffect(() => {
		if (!leavePolicies) {
			return;
		}
		const policyLabel: any = leavePolicies?.org_leave_group?.map((value) => {
			return value?.label;
		});
		setLeavePolicyLabel(policyLabel);
	}, [leavePolicies]);

	const [
		deleteOrgLeavePolicy,
		{ loading: isDeleteLeavePolicyMutationLoading },
	] = useDeleteOrgLeavePolicyMutation();

	useEffect(() => {
		if (!selectedLeavePolicy || !leavePolicies) {
			return;
		}
		const selectedLeaveGroup = leavePolicies.org_leave_group.find(
			(leavePolicy) => leavePolicy.id === selectedLeavePolicy
		);

		if (selectedLeaveGroup) {
			const employees = selectedLeaveGroup?.employees.map((employee) => ({
				name: `${employee.user?.full_name || ''} `,
				email: `${employee.user?.email || ''}`,
			}));

			setSelectedLeavePolicyEmployees(employees ? employees : []);
		}
	}, [leavePolicies, selectedLeavePolicy]);

	const navigateToDetailsPage = (leavePolicyId: string) => {
		if (!leavePolicyId) {
			return;
		}
		history.push({
			pathname: `/settings/leave-policy/${leavePolicyId}`,
		});
	};

	if (!isEmployeesListModalShown && loading) {
		return <Loading />;
	}

	const handleDeleteLeavePolicy = () => {
		if (!selectedLeavePolicy) {
			return;
		}
		deleteOrgLeavePolicy({
			variables: {
				leaveGroupId: selectedLeavePolicy,
				leaveTypeIds: leaveTypes,
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify('Leave Policy has been removed');
					refetchLeaveGroups();
					refresh();
					setSelectedLeavePolicy('');
					setIsDeleteModalShown(false);
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	return (
		<>
			<Box mb={2} display='flex' justifyContent='flex-end'>
				<Button
					variant='contained'
					className={modalFormStyles.saveButton}
					onClick={() => {
						setIsLeavePolicyFormShown(true);
					}}
				>
					Add Leave Policy
				</Button>
			</Box>
			<Box>
				{leavePolicies && leavePolicies?.org_leave_group?.length > 0
					? leavePolicies.org_leave_group.map((leavePolicy) => (
							<Box className={leavePolicyStyles.Container} key={leavePolicy.id}>
								<Box display='flex' alignItems='center'>
									<Box display='flex' mr={1}>
										<StarIcon
											className={
												leavePolicy.is_default
													? leavePolicyStyles.defaultColor
													: leavePolicyStyles.nonDefaultColor
											}
										/>
									</Box>
									<Box>
										<Tooltip title={`${leavePolicy.label}`} placement='right'>
											<Typography
												className={`${leavePolicyStyles.name} ${ellipsisStyles.ellipsis}`}
											>
												{leavePolicy.label}
											</Typography>
										</Tooltip>
									</Box>
								</Box>

								<Box>
									<Typography className={leavePolicyStyles.label}>
										Period
									</Typography>
									{leavePolicy.start_date && leavePolicy.end_date ? (
										<Typography className={`${leavePolicyStyles.rowContent}`}>
											{`${dayjs(leavePolicy.start_date).format(
												dateFormat
											)} to ${dayjs(leavePolicy.end_date).format(dateFormat)}`}
										</Typography>
									) : (
										<Typography className={`${leavePolicyStyles.rowContent}`}>
											- -
										</Typography>
									)}
								</Box>

								<Box>
									<Typography className={leavePolicyStyles.label}>
										Applicable from
									</Typography>
									{leavePolicy.applicable_from ? (
										<Typography className={`${leavePolicyStyles.rowContent}`}>
											{`${dayjs(leavePolicy.applicable_from).format(
												dateFormat
											)}`}
										</Typography>
									) : (
										<Typography className={`${leavePolicyStyles.rowContent}`}>
											- -
										</Typography>
									)}
								</Box>
								<Box>
									<div
										className={leavePolicyStyles.employeesContainer}
										onClick={() => {
											if (leavePolicy?.employees) {
												setSelectedLeavePolicy(leavePolicy.id);
												const employees = leavePolicy?.employees.map(
													(employee) => ({
														name: `${employee.user?.first_name || ''} ${
															employee.user?.last_name || ''
														}`,
														email: `${employee.user?.email || ''}`,
													})
												);
												setSelectedLeavePolicyEmployees(
													employees ? employees : []
												);
											}
											setIsEmployeesListModalShown(true);
										}}
									>
										<PeopleOutlineIcon
											className={leavePolicyStyles.peoplesIcon}
										/>
										<Typography
											className={`${leavePolicyStyles.rowContent} ${leavePolicyStyles.employeesCount}`}
										>
											{leavePolicy.employees_aggregate.aggregate?.count}
										</Typography>
									</div>
								</Box>
								<Box className={leavePolicyStyles.actionContainer}>
									<Box className={leavePolicyStyles.actions}>
										<IconButton
											className={`${datagridStyles.listEditIconButton} ${leavePolicyStyles.IconButton}`}
											onClick={() => {
												setSelectedLeavePolicy(leavePolicy.id);
												setIsEmployeeMappingFormShown(true);
												setSelectedLeaveDeptMappings({
													leaveDeptMapping:
														leavePolicy.leave_group_dept_mappings.map(
															(leaveDeptMapping) => ({
																leaveDeptMappingId: leaveDeptMapping.id,
																department_id: leaveDeptMapping.department.id,
															})
														),
													department_id:
														leavePolicy.leave_group_dept_mappings.map(
															(leaveDeptMapping) =>
																leaveDeptMapping.department.id
														),
													leaveTypes: leavePolicy.org_leave_types,
												});
											}}
										>
											<PersonAddIcon className={datagridStyles.editIcon} />
										</IconButton>
										<IconButton
											className={`${datagridStyles.listEditIconButton} ${leavePolicyStyles.IconButton}`}
											onClick={() => {
												navigateToDetailsPage(leavePolicy.id);
											}}
										>
											<EditIcon className={datagridStyles.editIcon} />
										</IconButton>
										{!leavePolicy.is_default && (
											<IconButton
												className={`${datagridStyles.listEditIconButton} ${leavePolicyStyles.IconButton} ${leavePolicyStyles.deleteIconButton}`}
											>
												<DeleteIcon
													onClick={() => {
														if (
															leavePolicy?.employees_aggregate?.aggregate
																?.count &&
															leavePolicy.employees_aggregate.aggregate?.count >
																0
														) {
															setIsWarningModalShown(true);
															return;
														}
														setSelectedLeavePolicy(leavePolicy.id);
														setIsDeleteModalShown(true);
														setLeavePolicyName(leavePolicy.label);
													}}
													className={datagridStyles.editIcon}
												/>
											</IconButton>
										)}
									</Box>
								</Box>
							</Box>
					  ))
					: 'No Leave Policies'}
			</Box>
			<LeavePolicyForm
				open={isLeavePolicyFormShown}
				onClose={() => {
					setIsLeavePolicyFormShown(false);
				}}
				refetch={() => {
					refetchLeaveGroups();
				}}
				leavePolicyLabel={leavePolicyLabel}
			/>
			<EmployeesMappingForm
				open={isEmployeeMappingFormShown}
				onClose={() => {
					setSelectedLeavePolicy('');
					setIsEmployeeMappingFormShown(false);
				}}
				leavePolicyId={selectedLeavePolicy}
				leaveDeptMappings={selectedLeaveDeptMappings}
				refetch={() => {
					refetchLeaveGroups();
				}}
			/>

			<ProjectDeleteModal //reused project delete modal
				mutationLoading={isDeleteLeavePolicyMutationLoading || false}
				open={isDeleteModalShown}
				onClose={() => {
					setSelectedLeavePolicy('');
					setIsDeleteModalShown(false);
				}}
				heading={` Delete Leave Policy "${leavePolicyName || ''}" !`}
				confirmationMessage={LEAVE_POLICY_DELETE_CONFIRMATION_MESSAGE}
				onDelete={() => {
					handleDeleteLeavePolicy();
				}}
			/>
			<WarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
				}}
				warningMessage={'Unable to delete as there is associated data'}
			/>
			<EmployeeListModal
				open={isEmployeesListModalShown}
				onClose={() => {
					setSelectedLeavePolicy('');
					setSearchedEmployee('');
					setSelectedLeavePolicyEmployees([]);
					setIsEmployeesListModalShown(false);
				}}
				employees={selectedLeavePolicyEmployees}
				onSearchChange={(searchText: string) => {
					setSearchedEmployee(searchText);
				}}
			/>
		</>
	);
};

export default LeavePolicyManagement;
