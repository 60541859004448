import React, { useContext, useEffect, useState } from 'react';
import { TextInput, required, number, useNotify, minValue } from 'react-admin';
import { Form } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { Typography, Box, ThemeProvider } from '@material-ui/core';
import { settingsStyle } from '../../Settings/settingsStyle';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';
import SettingsDetailContainer from '../../Settings/SettingsDetailContainer.component';
import {
	useGetPmsReviewCommentLimitQuery,
	useUpdateOrgPmsReviewCommentCharLimitMutation,
} from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import PmsEmailNotification from './PmsEmailNotification.component';
import GoalCategoryList from './GoalCategoryList.component';

export interface Props {
	numberOfCharacters: string;
}

const GeneralSettings = () => {
	const { orgId: organizationId } = useContext<any>(UserProfileContext);
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const [numberOfCharacters, setNumberOfCharacters] = useState<
		string | number | null
	>('');
	const notify = useNotify();
	const {
		data: getPmsReviewCommentLimit,
		refetch: reftechPmsReviewCommentLimit,
	} = useGetPmsReviewCommentLimitQuery({
		variables: {
			organizationId: organizationId,
		},
		fetchPolicy: 'network-only',
	});
	const [
		updateOrgPmsReviewCommentCharLimit,
		{ loading: isUpdatePmsReviewCommentLimit },
	] = useUpdateOrgPmsReviewCommentCharLimitMutation();
	const onHandleSubmit = (value: Props) => {
		updateOrgPmsReviewCommentCharLimit({
			variables: {
				id: organizationId,
				commentCharLimit: value?.numberOfCharacters,
			},
		}).then((data) => {
			notify('Review Comment Limit updated successfully');
			reftechPmsReviewCommentLimit();
		});
	};

	useEffect(() => {
		if (
			!getPmsReviewCommentLimit ||
			!getPmsReviewCommentLimit?.organization_by_pk ||
			!getPmsReviewCommentLimit?.organization_by_pk
				?.pms_review_comment_char_limit
		) {
			return;
		}
		setNumberOfCharacters(
			getPmsReviewCommentLimit?.organization_by_pk
				?.pms_review_comment_char_limit
		);
	}, [getPmsReviewCommentLimit]);
	return (
		<>
			<SettingsDetailContainer heading={'Review Comment'}>
				<ThemeProvider theme={modalFormTheme}>
					<Box className={styles.formContainer}>
						<Form
							initialValues={{ numberOfCharacters }}
							onSubmit={onHandleSubmit}
						>
							{({ handleSubmit, invalid, pristine }) => (
								<form onSubmit={handleSubmit}>
									<Box className={styles.inputContainer}>
										<Box className={styles.inputLabel}>
											<Typography className={classes.label}>
												Character Limit*
											</Typography>
										</Box>
										<Box className={styles.input}>
											<TextInput
												source='numberOfCharacters'
												fullWidth={true}
												label={''}
												validate={[required(), number(), minValue(1)]}
											/>
										</Box>
									</Box>
									<Box
										className={classes.buttonContainer}
										style={{ padding: 0 }}
									>
										<ButtonWithLoader
											title='Save'
											isLoading={isUpdatePmsReviewCommentLimit}
											isDisabled={invalid || pristine}
										/>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</ThemeProvider>
			</SettingsDetailContainer>
			<GoalCategoryList />
			<PmsEmailNotification />
		</>
	);
};

export default GeneralSettings;
