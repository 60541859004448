import React, { useContext, useState } from 'react';

import {
	Box,
	Typography,
	Button,
	Drawer,
	Tooltip,
	ThemeProvider,
	CircularProgress,
	IconButton,
} from '@material-ui/core';
import {
	drawerFilterStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import { Form } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import { documentUploadStyles, pmsViewDetailsStyle } from '../pms.styles';
import { FileField, FileInput, TextInput, useNotify } from 'react-admin';
import {
	fileUploadTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	useDeleteGoalCommentDocumentMutation,
	useGetGoalMappingCommentQuery,
	useGetPmsReviewCommentLimitQuery,
	useUpdateUserGoalCommentMutation,
} from '../../../generated/graphql';
import Loader from '../SharedComponents/Loader.component';
import {
	COMMENT_AND_DOCUMENT_UPDATED_TEXT,
	COMMENT_UPDATED_FAILED_TEXT,
	COMMENT_UPDATED_TEXT,
	DOCUMENT_DELETED_FAILED,
	DOCUMENT_DELETED_SUCCESS,
	DOCUMENT_UPLOAD_ACCEPTED_FILES,
	UNSUPPORTED_FILE_ERROR,
} from '../constant';
import { head } from 'lodash';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import { uploadFile } from '../../../Api/restApi.config';
import { UserProfileContext } from '../../../App';
import { preventSubsequentClick } from '../../../Utils/string.util';
import WarningConfirmationModal from '../../../SharedComponents/WarningConfirmationModal.component';
import { ENABLE_OVERTIME_CONFIRMATION } from '../../Settings/constant';
import { validateGoalCommentDescription } from '../pms.utils';

interface GoalCommentProps {
	open: boolean;
	onClose: () => void;
	onCommentSaved: (value: { id: string; comment: string }) => void;
	goalMapping: {
		id: any;
		user_comment: string;
		isDisabled: boolean;
		goal: string;
		index: number;
	};
}

const GoalCommentModal = (props: GoalCommentProps) => {
	const { open, onClose, goalMapping, onCommentSaved } = props;
	const { id: userId, orgId: organizationId } =
		useContext<any>(UserProfileContext);
	const [uploadedFileInfo, setUploadedFileInfo] = useState<any>(null);
	const [loadingState, setLoadingState] = useState<boolean>(false);
	const [isDocumentUploading, setIsDocumentUploading] =
		useState<boolean>(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const drawerFilterStyles = drawerFilterStyle();
	const formStyles = modalFormStyle();
	const customStyles = documentUploadStyles();
	const pmsDetailsStyles = pmsViewDetailsStyle();
	const ellipsis = ellipsisStyle();
	const notify = useNotify();

	// API
	const {
		data: goalData,
		loading: isGoalMappingDataLoading,
		refetch: refetchGoalMapping,
	} = useGetGoalMappingCommentQuery({
		variables: {
			goalId: goalMapping.id,
		},
		fetchPolicy: 'network-only',
	});
	const [updateUserGoalComment, { loading: isUpdateCommentLoading }] =
		useUpdateUserGoalCommentMutation();
	const { data: getPmsReviewCommentLimit } = useGetPmsReviewCommentLimitQuery({
		variables: {
			organizationId: organizationId,
		},
		fetchPolicy: 'network-only',
	});
	const [deletDocument, { loading: isDeleteDocumentLoading }] =
		useDeleteGoalCommentDocumentMutation();
	const handleSubmit = async (formValue: any) => {
		if (uploadedFileInfo) {
			setIsDocumentUploading(true);
			const appraisalCycleId = head(
				goalData?.pms_pms_appraisal_goal_mapping
			)?.appraisal_cycle_id;
			const goalMappingId = head(goalData?.pms_pms_appraisal_goal_mapping)?.id;
			const data = new FormData();

			const formFields: any = {
				uploadType: 'PMSSelfReviewDocumentUpload',
				id: userId,
				appraisalCycleId: appraisalCycleId,
				userGoalMappingId: goalMappingId,
				file: uploadedFileInfo,
			};

			for (const key in formFields) {
				data.append(key, formFields[key]);
			}
			try {
				const response = await uploadFile(data);
				if (!response.data.errors) {
					const data = response.data.url;
					setIsDocumentUploading(false);
					updateUserGoalComment({
						variables: {
							goalMappingId: goalMapping.id,
							goalMappingData: {
								user_comment: formValue.user_comment,
								doc_url: data,
								file_name: uploadedFileInfo?.name,
							},
						},
					}).then((res) => {
						if (!res.errors) {
							notify(COMMENT_AND_DOCUMENT_UPDATED_TEXT);
							onCommentSaved({
								id: goalMapping.id,
								comment: formValue?.user_comment,
							});
							setUploadedFileInfo(null);
							refetchGoalMapping();
						}
					});
				}
			} catch (err) {
				notify('File Upload Failed', 'warning');
				setIsDocumentUploading(false);
			}
			return;
		}
		updateUserGoalComment({
			variables: {
				goalMappingId: goalMapping.id,
				goalMappingData: {
					user_comment: formValue?.user_comment
						? formValue.user_comment.trim()
						: null,
				},
			},
		})
			.then((res) => {
				if (!res.errors) {
					notify(COMMENT_UPDATED_TEXT);
					onCommentSaved({
						id: goalMapping.id,
						comment: formValue?.user_comment,
					});
					refetchGoalMapping();
				}
			})
			.catch((error) => {
				notify(COMMENT_UPDATED_FAILED_TEXT, 'warning');
			});
	};
	const disableButton = () => {
		setLoadingState(false);
	};
	const handleDownload = () => {
		setLoadingState(true);
		let link = document.createElement('a');
		link.href = head(goalData?.pms_pms_appraisal_goal_mapping)?.doc_url || '';
		preventClick();
		link.click();
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);

	const getFileName = () => {
		if (!goalData) {
			return;
		}
		const fileName =
			head(goalData?.pms_pms_appraisal_goal_mapping)?.file_name || '';
		return fileName || 'File';
	};

	const handleDeleteDocument = () => {
		deletDocument({
			variables: {
				goalMappingId: goalMapping.id,
			},
		})
			.then((res) => {
				if (!res.errors) {
					notify(DOCUMENT_DELETED_SUCCESS);
					setIsWarningModalShown(false);
					setUploadedFileInfo(null);
					refetchGoalMapping();
				}
			})
			.catch((error) => {
				if (error) notify(DOCUMENT_DELETED_FAILED, 'warning');
			});
	};
	if (!open) return null;
	return (
		<>
			<Drawer open={open} anchor='right' onClose={onClose}>
				<Box
					className={drawerFilterStyles.drawerContainer}
					style={{
						width: '500px',
					}}
				>
					<Box display='flex' flexDirection='column'>
						<Box className={drawerFilterStyles.HeaderSection}>
							<Typography className={drawerFilterStyles.drawerHeading}>
								Comment For Goal
							</Typography>
							<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
						</Box>
					</Box>
					<ThemeProvider theme={modalFormTheme}>
						{isGoalMappingDataLoading ? (
							<Box className={drawerFilterStyles.noData}>
								<Box className={drawerFilterStyles.loading}>Loading</Box>
								<CircularProgress />
							</Box>
						) : (
							<Form
								onSubmit={handleSubmit}
								initialValues={{
									user_comment:
										head(goalData?.pms_pms_appraisal_goal_mapping)
											?.user_comment || '',
									doc_url:
										head(goalData?.pms_pms_appraisal_goal_mapping)?.doc_url ||
										null,
								}}
							>
								{({ handleSubmit, form, invalid, values, pristine }) => {
									return (
										<form onSubmit={handleSubmit}>
											<div>
												<div
													style={{
														marginTop: '20px',
														marginBottom: '20px',
													}}
												>
													<Tooltip title={goalMapping.goal} placement='top'>
														<div
															className={`${pmsDetailsStyles.goal} ${ellipsis.ellipsis}`}
															style={{
																fontSize: '16px',
																width: '400px',
															}}
														>
															{goalMapping.goal}
														</div>
													</Tooltip>
												</div>

												<div
													style={{
														marginBottom: '20px',
													}}
												>
													<p className={formStyles.label}>Include Note</p>
													<TextInput
														source='user_comment'
														fullWidth={true}
														label={false}
														validate={(value) =>
															validateGoalCommentDescription(
																value,
																getPmsReviewCommentLimit?.organization_by_pk
																	?.pms_review_comment_char_limit ?? undefined
															)
														}
														multiline
														disabled={goalMapping?.isDisabled}
													/>
												</div>
											</div>
											{head(goalData?.pms_pms_appraisal_goal_mapping)
												?.doc_url ? (
												<Box marginBottom='30px'>
													<Typography className={customStyles.heading}>
														Uploaded Documents
													</Typography>
													<Box
														display='flex'
														alignItems='center'
														justifyContent={'flex-start'}
														className={customStyles.document}
													>
														<Box>
															<IconButton
																color='primary'
																onClick={() => {}}
																aria-label='download'
															>
																<DescriptionIcon
																	className={customStyles.icon}
																/>
															</IconButton>
														</Box>
														<Box className={customStyles.fileName}>
															{getFileName()}
														</Box>
														<Box
															display='flex'
															className={customStyles.actionContainer}
														>
															<Box className={customStyles.actions}>
																<Tooltip title='Download'>
																	<IconButton
																		className={customStyles.listEditIconButton}
																		color='primary'
																		onClick={() => {}}
																		aria-label='download'
																	>
																		{loadingState ? (
																			<CircularProgress
																				className={customStyles.icon}
																				style={{
																					height: '20px',
																					width: '20px',
																					color: '#4285F4',
																				}}
																			/>
																		) : (
																			<GetAppIcon
																				onClick={handleDownload}
																				className={customStyles.downloadIcon}
																			/>
																		)}
																	</IconButton>
																</Tooltip>
																{!goalMapping?.isDisabled && (
																	<Tooltip title='Delete'>
																		<IconButton
																			className={
																				customStyles.listEditIconButton
																			}
																			color='primary'
																			onClick={() =>
																				setIsWarningModalShown(true)
																			}
																		>
																			<DeleteIcon
																				className={customStyles.deleteIcon}
																			/>
																		</IconButton>
																	</Tooltip>
																)}
															</Box>
														</Box>
													</Box>
												</Box>
											) : (
												!goalMapping?.isDisabled && (
													<Box>
														<Box display='flex'>
															<Typography className={customStyles.heading}>
																Upload Documents
															</Typography>
															<Tooltip
																title={`Supported types: ${DOCUMENT_UPLOAD_ACCEPTED_FILES}`}
																placement='bottom'
															>
																<InfoIcon
																	className={
																		pmsDetailsStyles.documentUploadInfoIcon
																	}
																/>
															</Tooltip>
														</Box>

														<>
															<ThemeProvider theme={fileUploadTheme}>
																<FileInput
																	options={{
																		disabled: goalMapping?.isDisabled,
																		onDropRejected() {
																			notify(UNSUPPORTED_FILE_ERROR, 'warning');
																		},
																	}}
																	onChange={(value) => {
																		if (!value) {
																			setUploadedFileInfo(null);
																		}
																		setUploadedFileInfo(value);
																	}}
																	multiple={false}
																	source='doc_url'
																	accept={DOCUMENT_UPLOAD_ACCEPTED_FILES}
																>
																	<FileField source='doc_url' title='title' />
																</FileInput>
															</ThemeProvider>
														</>
													</Box>
												)
											)}
											<Box className={drawerFilterStyles.buttonContainer}>
												<Box mb={'10px'}>
													<Button
														className={
															invalid ||
															pristine ||
															isDocumentUploading ||
															goalMapping?.isDisabled ||
															isUpdateCommentLoading
																? formStyles.disabledButton
																: formStyles.saveButton
														}
														variant='outlined'
														color='primary'
														type='submit'
														disabled={
															invalid ||
															pristine ||
															isDocumentUploading ||
															goalMapping?.isDisabled ||
															isUpdateCommentLoading
														}
														startIcon={
															isUpdateCommentLoading ||
															(isDocumentUploading && <Loader />)
														}
													>
														Save
													</Button>
												</Box>
												<Box mb={'10px'}>
													<Button
														onClick={() => onClose()}
														className={drawerFilterStyles.cancelButton}
														variant='outlined'
													>
														Cancel
													</Button>
												</Box>
											</Box>
										</form>
									);
								}}
							</Form>
						)}
					</ThemeProvider>
				</Box>
			</Drawer>
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={() => setIsWarningModalShown(false)}
				warningMessage={ENABLE_OVERTIME_CONFIRMATION}
				onSubmit={() => handleDeleteDocument()}
				isLoading={isDeleteDocumentLoading}
			/>
		</>
	);
};

export default GoalCommentModal;
