import React, { useState, useEffect } from 'react';
import {
	TextInput,
	required,
	number,
	useNotify,
	useRefresh,
	minValue,
} from 'react-admin';

import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	DialogActions,
	DialogContent,
	modalFormStyle,
} from '../../../Layout/styles';
import InfoIcon from '@material-ui/icons/Info';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	makeStyles,
	CircularProgress,
	IconButton,
	Tooltip,
	Checkbox,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LeaveTypeFormValues } from './LeaveManagement.model';
import { useUpsertLeaveTypeMutation } from '../../../generated/graphql';
import { ERR_LEAVE_TYPE_NOT_UNIQUE } from '../../constant';
import { isFloat } from '../../../Utils/string.util';
import {
	DEFAULT_AMOUNT_WARNING_MESSAGE,
	INTERVAL_WARNING_MESSAGE,
	INCREMENT_ZERO_ERROR_MESSAGE,
	INTERVAL_ZERO_ERROR_MESSAGE,
	START_OF_THE_MONTH_LEAVE_CREDIT_INFO,
} from '../constant';
import ListWarningModal from '../../../SharedComponents/WarningModalWithList.component';
import { isEmpty } from 'lodash';

const leaveFormStyle = makeStyles({
	actionContainer: {
		paddingBottom: '10px',
	},
	checkBoxContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	checkBox: {
		marginRight: '8px',
		width: '14px',
		height: '14px',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px !important',
		color: '#EA4335',
		marginTop: '2px',
		marginBottom: '40px',
		height: '5px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
		marginTop: '6px',
	},
	infoIcon: {
		width: '12px',
		height: '12px',
	},
});

interface LeaveTypeFormProps {
	onClose: () => void;
	open: boolean;
	initialValues?: LeaveTypeFormValues;
	leavePolicyName?: string;
	leavePolicyId: string;
}
export interface FormValues {
	__typename?: string;
	id?: string;
	leave_type?: string;
	default_amount?: number;
	leave_increments?: number | string;
	interval?: number | string;
	is_carry_forward?: boolean;
	carry_forward_max_limit?: number;
	is_negative_bal_allowed?: boolean;
	is_leave_sandwich_type?: boolean;
	is_LOP?: boolean;
	leave_group_id?: string;
	org_id?: string;
}
export const LeaveTypeForm = (props: LeaveTypeFormProps) => {
	const { open, onClose, initialValues, leavePolicyName, leavePolicyId } =
		props;
	const [isLeaveTypeUniqueViolation, setIsLeaveTypeUniqueViolation] =
		useState(false);
	const [isIntervalLessThanZerorError, setIsIntervalLessThanZeroError] =
		useState(false);
	const [isStartOfTheMonthAccrualEnabled, setIsStartOfTheMonthAccrualEnabled] =
		useState(false);
	const [isIncrementLessThanZerorError, setIsIncrementLessThanZeroError] =
		useState(false);
	useState(false);
	const [isWaringModalShown, setIsWarningModalShown] = useState(false);
	const [currentFormValues, setCurrentFormValues] =
		useState<LeaveTypeFormValues>({});
	const formStyles = modalFormStyle();
	const leaveFormStyles = leaveFormStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const toasterMessage = initialValues?.id
		? 'Leave type updated successfully'
		: 'Leave type created successfully';

	const [
		upsertLeaveType,
		{ error: leavePolicyFormError, loading: isUpsertLeaveLoading },
	] = useUpsertLeaveTypeMutation();

	useEffect(() => {
		if (!leavePolicyFormError) {
			return;
		}
		if (leavePolicyFormError?.message.includes(ERR_LEAVE_TYPE_NOT_UNIQUE)) {
			setIsLeaveTypeUniqueViolation(true);
		}
	}, [leavePolicyFormError]);

	useEffect(() => {
		if (!initialValues) {
			return;
		}
		setIsStartOfTheMonthAccrualEnabled(
			initialValues?.is_leave_credit_on_start_of_month || false
		);
	}, [initialValues]);

	const handleSubmit = async (leaveTypeFormValues: LeaveTypeFormValues) => {
		if (!leaveTypeFormValues) {
			return;
		}
		setCurrentFormValues(leaveTypeFormValues);
		if (
			Number(leaveTypeFormValues?.interval) <= 0 &&
			Number(leaveTypeFormValues?.leave_increments) > 0 &&
			!isStartOfTheMonthAccrualEnabled
		) {
			setIsIntervalLessThanZeroError(true);
			return;
		}
		if (
			Number(leaveTypeFormValues?.interval) > 0 &&
			Number(leaveTypeFormValues?.leave_increments) <= 0
		) {
			setIsIncrementLessThanZeroError(true);
			return;
		}
		if (
			!isEmpty(initialValues) &&
			(leaveTypeFormValues?.interval !== initialValues?.interval ||
				leaveTypeFormValues?.default_amount !== initialValues?.default_amount)
		) {
			setIsWarningModalShown(true);
			return;
		}
		onUpsertLeaveType(leaveTypeFormValues);
	};

	const validateleaveTypeFormValues = async () => {
		const leaveTypeFormErrors = {} as LeaveTypeFormValues;
		if (isLeaveTypeUniqueViolation) {
			leaveTypeFormErrors.leave_type = 'Leave type name already exist!';
		}
		if (isIntervalLessThanZerorError) {
			leaveTypeFormErrors.interval = '';
		}
		if (isIncrementLessThanZerorError) {
			leaveTypeFormErrors.leave_increments = '';
		}
		return leaveTypeFormErrors;
	};

	const intervalValidation = (value: string) => {
		if (!isStartOfTheMonthAccrualEnabled) {
			if (!value) {
				return 'Required';
			}
			const num = Number(value);
			if (isFloat(num)) {
				return 'Value should be a integer';
			}
			if (num < 0) {
				return 'Minimum value should be zero';
			}
		}
		return undefined;
	};
	const handleClose = () => {
		setCurrentFormValues({});
		setIsWarningModalShown(false);
		setIsIncrementLessThanZeroError(false);
		setIsIntervalLessThanZeroError(false);
		setIsLeaveTypeUniqueViolation(false);
		setIsStartOfTheMonthAccrualEnabled(false);
		onClose();
	};
	const onUpsertLeaveType = (leaveTypeFormValues: LeaveTypeFormValues) => {
		const {
			__typename,
			org_id,
			default_amount,
			leave_increments,
			carry_forward_max_limit,
			leave_type,
			...formValues
		} = leaveTypeFormValues;
		upsertLeaveType({
			variables: {
				data: {
					...formValues,
					leave_type: leaveTypeFormValues?.leave_type?.trim(),
					default_amount: isFloat(Number(leaveTypeFormValues?.default_amount))
						? parseFloat(Number(leaveTypeFormValues?.default_amount).toFixed(3))
						: leaveTypeFormValues?.default_amount,
					leave_increments: isFloat(
						Number(leaveTypeFormValues?.leave_increments)
					)
						? parseFloat(
								Number(leaveTypeFormValues?.leave_increments).toFixed(3)
						  )
						: leaveTypeFormValues?.leave_increments,
					carry_forward_max_limit: isFloat(
						Number(leaveTypeFormValues?.carry_forward_max_limit)
					)
						? parseFloat(
								Number(leaveTypeFormValues?.carry_forward_max_limit).toFixed(3)
						  )
						: leaveTypeFormValues?.carry_forward_max_limit,
					leave_group_id: leavePolicyId,
				},
			},
		})
			.then((response) => {
				if (!response.errors) {
					notify(toasterMessage);
					refresh();
					setCurrentFormValues({});
					setIsWarningModalShown(false);
					setIsIncrementLessThanZeroError(false);
					setIsIntervalLessThanZeroError(false);
					setIsLeaveTypeUniqueViolation(false);
					setIsStartOfTheMonthAccrualEnabled(false);
					onClose();
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog open={open} onClose={handleClose} disableBackdropClick>
				<Box className={formStyles.container}>
					<Box className={formStyles.headerContainer}>
						<Typography className={formStyles.heading}>
							{initialValues?.id
								? `Edit Leave Type: ${leavePolicyName}`
								: `Add Leave Type: ${leavePolicyName}`}
						</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={handleClose} />
					</Box>
					<Form
						initialValues={
							initialValues?.id
								? initialValues
								: {
										is_carry_forward: true,
										is_LOP: false,
										is_negative_bal_allowed: false,
										is_leave_sandwich_type: true,
										is_leave_credit_on_start_of_month: false,
								  }
						}
						validate={validateleaveTypeFormValues}
						onSubmit={handleSubmit}
						mutators={{
							resetInterval: (args, state, utils) => {
								utils.changeValue(state, 'interval', () => 0);
							},
						}}
					>
						{({ handleSubmit, invalid, pristine, form }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<DialogContent>
										<Typography className={formStyles.label}>
											Leave Type*
										</Typography>
										<TextInput
											source='leave_type'
											label=''
											validate={required()}
											onChange={() => {
												setIsLeaveTypeUniqueViolation(false);
											}}
										/>
										<Box className={formStyles.multipleInputContainer}>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Default Amount*
												</Typography>
												<TextInput
													source='default_amount'
													label=''
													InputProps={{
														endAdornment: 'days',
													}}
													validate={[required(), number()]}
												/>
											</Box>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Leave Increment*
												</Typography>
												<TextInput
													source='leave_increments'
													label=''
													InputProps={{
														endAdornment: 'days',
													}}
													onChange={() => {
														setIsIncrementLessThanZeroError(false);
														setIsIntervalLessThanZeroError(false);
													}}
													validate={[
														required(),
														number(),
														minValue(0, 'Minimum value should be zero'),
													]}
													helperText={false}
												/>
												{isIncrementLessThanZerorError && (
													<p className={leaveFormStyles.errorText}>
														{INCREMENT_ZERO_ERROR_MESSAGE}
													</p>
												)}
											</Box>
										</Box>
										<Box className={formStyles.multipleInputContainer}>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Interval
													{`${!isStartOfTheMonthAccrualEnabled ? '*' : ''}`}
												</Typography>
												<TextInput
													source='interval'
													onChange={() => {
														setIsIntervalLessThanZeroError(false);
														setIsIncrementLessThanZeroError(false);
													}}
													label=''
													InputProps={{
														endAdornment: 'days',
													}}
													validate={intervalValidation}
													helperText={false}
													disabled={isStartOfTheMonthAccrualEnabled}
												/>
												{isIntervalLessThanZerorError && (
													<p className={leaveFormStyles.errorText}>
														{INTERVAL_ZERO_ERROR_MESSAGE}
													</p>
												)}
											</Box>
											<Box className={formStyles.multipleInput}>
												<Typography className={formStyles.label}>
													Carry Forward Limit*
												</Typography>
												<TextInput
													source='carry_forward_max_limit'
													label=''
													validate={[required(), number()]}
												/>
											</Box>
										</Box>
										<Box className={leaveFormStyles.checkBoxContainer}>
											<Field
												component='input'
												type='checkbox'
												name='is_carry_forward'
												className={leaveFormStyles.checkBox}
											/>
											<Typography className={formStyles.label}>
												Is Carry Forward
											</Typography>
										</Box>
										<Box className={leaveFormStyles.checkBoxContainer}>
											<Box display='flex'>
												<Field name='is_leave_credit_on_start_of_month'>
													{(props: any) => (
														<>
															<Checkbox
																value={isStartOfTheMonthAccrualEnabled}
																icon={
																	<CheckBoxOutlineBlankIcon fontSize='small' />
																}
																onChange={(event) => {
																	if (event?.target?.checked) {
																		form.mutators.resetInterval();
																	}
																	setIsStartOfTheMonthAccrualEnabled(
																		event.target.checked
																	);
																	props.input.onChange(event.target.checked);
																}}
																checkedIcon={<CheckBoxIcon style={{color:'#4285F4'}} fontSize='small' />}
																style={{ marginLeft: -12 }}
																checked={isStartOfTheMonthAccrualEnabled}
															/>
														</>
													)}
												</Field>
												<Typography
													style={{ marginTop: '7px' }}
													className={formStyles.label}
												>
													Accrue Leave on Start of Every Month
												</Typography>
												<Tooltip title={START_OF_THE_MONTH_LEAVE_CREDIT_INFO}>
													<IconButton className={leaveFormStyles.iconContainer}>
														<InfoIcon className={leaveFormStyles.infoIcon} />
													</IconButton>
												</Tooltip>
											</Box>
										</Box>
										<Box className={leaveFormStyles.checkBoxContainer}>
											<Field
												component='input'
												type='checkbox'
												name='is_negative_bal_allowed'
												className={leaveFormStyles.checkBox}
											/>
											<Typography className={formStyles.label}>
												Is Allow Negative Balance
											</Typography>
										</Box>

										<Box className={leaveFormStyles.checkBoxContainer}>
											<Field
												component='input'
												type='checkbox'
												name='is_leave_sandwich_type'
												className={leaveFormStyles.checkBox}
											/>
											<Typography className={formStyles.label}>
												Exclude Holidays & Weekends
											</Typography>
										</Box>

										<Box className={leaveFormStyles.checkBoxContainer}>
											<Field
												component='input'
												type='checkbox'
												name='is_LOP'
												className={leaveFormStyles.checkBox}
											/>
											<Typography className={formStyles.label}>
												Is Leave Without Pay
											</Typography>
										</Box>
									</DialogContent>
									<DialogActions>
										<Box
											className={`${formStyles.buttonContainer}${leaveFormStyles.actionContainer}`}
										>
											<Button
												onClick={handleClose}
												className={formStyles.cancelButton}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || pristine || isUpsertLeaveLoading
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={invalid || pristine || isUpsertLeaveLoading}
												type='submit'
												startIcon={
													isUpsertLeaveLoading && (
														<CircularProgress
															style={{
																height: '20px',
																width: '20px',
																color: '#ffffff',
															}}
														/>
													)
												}
											>
												Save
											</Button>
										</Box>
									</DialogActions>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
			<ListWarningModal
				open={isWaringModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
				}}
				heading={'Note!'}
				warningList={[DEFAULT_AMOUNT_WARNING_MESSAGE, INTERVAL_WARNING_MESSAGE]}
				onConfirm={() => {
					onUpsertLeaveType(currentFormValues);
				}}
				isLoading={isUpsertLeaveLoading}
			/>
		</ThemeProvider>
	);
};

export default LeaveTypeForm;
