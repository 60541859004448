import React, { useState } from 'react';
import { TextInput, required, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box, Dialog, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { settingsStyle } from '../../Settings/settingsStyle';
import { useUpsertGoalCategoryMutation } from '../../../generated/graphql';
import {
	GOAL_CATEGORY_UPDATED_MESSAGE,
	GOAL_CATEGORY_CREATED_MESSAGE,
} from '../constant';
interface FormValue {
	id?: string;
	name?: string;
}
interface Props {
	onClose: () => void;
	open: boolean;
	refetch: () => void;
	isEditMutation: boolean;
	initialValues?: FormValue;
}
const GoalCategoryForm = (props: Props) => {
	const { open, onClose, refetch, isEditMutation, initialValues } = props;
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const [upsertGoalCategoryMutation, { loading: isLoadingGoalCategory }] =
		useUpsertGoalCategoryMutation();
	const [isNameUniqueViolationError, setIsNameUniqueViolationError] =
		useState(false);
	const onHandleSubmit = (formValues: FormValue) => {
		if (!formValues) {
			return;
		}
		upsertGoalCategoryMutation({
			variables: {
				goalCategory: {
					id: formValues?.id,
					name: formValues?.name,
				},
			},
		})
			.then((response: any) => {
				if (!response.error) {
					if (formValues?.id) {
						notify(GOAL_CATEGORY_UPDATED_MESSAGE);
						refetch();
						closeModal();
					} else {
						notify(GOAL_CATEGORY_CREATED_MESSAGE);
						refetch();
						closeModal();
					}
				}
			})
			.catch((error: any) => {
				if (!error) {
					return;
				}
				if (error?.message.includes('UQ_845e6a89c6752be4350157b1e55')) {
					setIsNameUniqueViolationError(true);
				}
			});
	};
	const validateGoalCategoryFormValues = async () => {
		const goalCategoryFormErrors = {} as FormValue;
		if (isNameUniqueViolationError) {
			goalCategoryFormErrors.name = 'name already exist!';
		}
		return goalCategoryFormErrors;
	};
	const closeModal = () => {
		onClose();
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={closeModal}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={settingsStyles.container}>
					<Box className={modalFormStyles.headerContainer}>
						<Tooltip
							title={initialValues?.id ? `${initialValues?.name}` : ''}
							placement={'top'}
						>
							<Typography
								className={`${modalFormStyles.heading} ${ellipsisStyles.ellipsis}`}
							>
								{isEditMutation
									? `Edit: ${initialValues?.name}`
									: `Add Goal Category`}
							</Typography>
						</Tooltip>
						<CloseIcon
							className={modalFormStyles.closeIcon}
							onClick={closeModal}
						/>
					</Box>
					<Form
						initialValues={initialValues}
						onSubmit={onHandleSubmit}
						validate={validateGoalCategoryFormValues}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box className={modalFormStyles.formContainer}>
									<Typography className={modalFormStyles.label}>
										Name*
									</Typography>
									<TextInput
										source='name'
										label=''
										validate={required()}
										onChange={() => setIsNameUniqueViolationError(false)}
									/>
								</Box>
								<Box
									className={modalFormStyles.buttonContainer}
									style={{ padding: 0 }}
								>
									<ButtonWithLoader
										title={isEditMutation ? 'Update' : 'Add'}
										isLoading={isLoadingGoalCategory}
										isDisabled={invalid || pristine || isLoadingGoalCategory}
									/>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default GoalCategoryForm;
