import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	Card,
	CardContent,
	ThemeProvider,
	Table,
	TableBody,
	TableHead,
	TableRow,
	createStyles,
	TableCell,
	withStyles,
	TableContainer,
	Typography,
	Theme,
	makeStyles,
	Tooltip,
	TextField,
	Checkbox,
} from '@material-ui/core';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import { Form } from 'react-final-form';
import { SelectInput } from 'react-admin';
import { Autocomplete } from '@material-ui/lab';

import TablePagination from '@material-ui/core/TablePagination';
import { head, isEmpty, orderBy, uniqBy } from 'lodash';
import {
	exportToCsv,
	formatEmployeeId,
	getPrefix,
	isFloat,
} from '../../../Utils/string.util';
import {
	dropDownEllipsisStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import _ from 'lodash';
import {
	useGetHolidayListForResourceQuery,
	useGetUsersAndTimesheetQuery,
	useGetResourceLeaveQuery,
	useGetLocationsByOrgIdQuery,
} from '../../../generated/graphql';
import { DateTimeUtil } from '../../../Utils/date-time.util';
import { UserProfileContext } from '../../../App';
import {
	defaultersListColumns,
	defaultersListColumnsWithLocation,
	timesheetTypes,
} from '../../Projects/constant';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '10px',
			padding: '12px',
		},
	})
)(TableCell);

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	tableContainer: {
		maxHeight: '400px',
		minHeight: '200px',
		overflowY: 'auto',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '50px',
	},
	noDataLabel: {
		fontSize: '12px',
		fontFamily: 'Manrope-semiBold',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '75px',
	},
	exportButtonDiv: {
		marginTop: '23px',
		marginRight: '8px',
	},
	dateCell: {
		display: 'block',
		fontSize: '10px',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		fontFamily: 'Manrope-semibold',
	},
	weekEndCell: {
		color: '#e63a2e',
	},
	timeSheetWithDate: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	timeSheetWithoutDate: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	headerCell: {
		minWidth: '120px',
		padding: '12px',
	},
	circle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
		visibility: 'hidden',
	},
	holidayCircle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
	},
	monthCell: {
		width: '10px',
	},
	exportIcon: {
		width: '20px',
		height: '17px',
		marginBottom: '5px',
	},
	timeSheetWithDateWithLeave: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#e63a2e',
		display: 'flex',
		alignItems: 'center',
		justifyContent: ' center',
	},
	multipleInput: {
		width: '150',
		marginLeft: '15px',
	},
	dropdownLabel: {
		marginTop: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}));
interface Props {
	projectId?: string[];
	startDate: string;
	endDate: string;
	setTotalHours: (value: number) => void;
	setTotalUtilization: (value: number) => void;
}
interface Resource {
	id: string;
	name: string;
}

interface option {
	id: String;
	name?: String | null | undefined;
}

export interface User {
	first_name?: string | null;
	last_name?: string | null;
	id?: string;
}
export interface TeamMember {
	userByEmployeeId?: User;
}
export interface ProjectTask {
	title?: string;
	id?: string;
}

export interface TimesheetStatus {
	label?: string;
	id?: string;
}

export interface TimesheetSubmission {
	timesheet_status?: TimesheetStatus;
}

export interface Currency {
	symbol?: string | null;
}

export interface Project {
	currency?: Currency | null;
}

export interface ProjectResourceMapping {
	id?: string;
	resource_cost?: number;
	project?: Project;
}

export interface UserByEmployeeId {
	full_name?: string | null;
	project_resource_mappings?: ProjectResourceMapping[];
}
export interface Project {
	name?: string;
}
export interface TimeSheet {
	id?: string;
	task_id?: string;
	is_billable?: boolean;
	working_hours?: string;
	date?: string;
	project_task?: ProjectTask | null;
	timesheet_submission?: TimesheetSubmission | null;
	userByEmployeeId?: UserByEmployeeId | null;
	project?: Project;
}
interface TimesheetTableList {
	projectName?: string;
	taskName?: string;
	user?: string | null | undefined;
	workingHours?: string;
	date?: string;
	timesheetStatus?: string;
	resourceCost?: string;
	totalCost?: string | number;
	id?: any;
	userId?: any;
	notes?: string;
	timesheet?: any[];
	totalHours: number;
	currencySymbol?: string;
}

export const TimesheetDefaultersList = (props: Props) => {
	const { startDate, endDate, setTotalHours, setTotalUtilization } = props;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const ellipsis = ellipsisStyle();
	const classes = useStyles();
	const styles = modalFormStyle();
	const dropdownStyles = dropDownEllipsisStyle();
	const [timeSheetTableList, setTimeSheetTableList] = useState<any[]>([]);
	const [assigneeList, setAssigneeList] = useState<any[]>([]);
	const [monthList, setMonthList] = useState<any[]>([]);
	const [choosedAssignee, setChoosedAssignee] = useState<string | null>(null);
	const [billableStatus, setBillableStatus] = useState<boolean | string>('All');

	const [selectedLocationOptionValue, setSelectedLocationOptionValue] =
		useState<option[]>([]);

	const [selectedLocationIdList, setSelectedLocationIdList] = useState<
		String[]
	>([]);
	const [locationDropDown, setLocationDropDown] = useState<option[]>([]);

	const {
		orgId: organizationId,
		isOvertimeEnabled,
		dateFormat,
		noOfLocations,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		permissions,
		accountEmployeeUserIds,
		reporteeUserList,
		accountEmployeesDropdownOptions,
		id: userId,
	} = useContext<any>(UserProfileContext);
	const [timesheetType, setTimesheetType] = useState<string>('All');
	const { data: leaveList } = useGetResourceLeaveQuery({
		variables: {
			startDate: dayjs(startDate).format('YYYY-MM-DD'),
			endDate: dayjs(endDate).format('YYYY-MM-DD'),
		},
	});
	const { data: location } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});

	const getLocationFilter = () => {
		if (noOfLocations && noOfLocations > 0) {
			if (isEmpty(selectedLocationOptionValue)) {
				return [];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue?.find((option) => option?.id === 'All')
			) {
				return [{}];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},
					{
						contractors: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
		}
		return [{}];
	};
	useEffect(() => {
		if (!startDate && !endDate) {
			return;
		}
		setPage(0);
		const dateList = [];
		let currentDate = dayjs(startDate);
		const stopDate = dayjs(endDate);
		while (currentDate <= stopDate) {
			dateList.push({
				date: dayjs(currentDate).format('YYYY-MM-DD'),
				day: dayjs(currentDate).date(),
				month: dayjs(currentDate).format('MMM'),
			});
			currentDate = dayjs(currentDate).add(1, 'day');
		}
		setMonthList(dateList);
	}, [startDate, endDate]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const onResourceChange = (
		event: React.ChangeEvent<{}>,
		newValue: Resource
	) => {
		setPage(0);
		if (newValue === null) {
			setChoosedAssignee(null);
		}
		setChoosedAssignee(newValue?.id);
	};

	const { data: holidayList } = useGetHolidayListForResourceQuery({
		variables: {
			startDate: dayjs(startDate).format('YYYY-MM-DD'),
			endDate: dayjs(endDate).format('YYYY-MM-DD'),
		},
		fetchPolicy: 'network-only',
	});
	const { data: resourceList, refetch } = useGetUsersAndTimesheetQuery({
		variables: {
			filters: {
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				timesheet_submission: {
					timesheet_status: { value: { _in: ['submitted', 'approved'] } },
				},
				is_billable:
					billableStatus === 'All'
						? {}
						: billableStatus === 'true'
						? { _eq: true }
						: billableStatus === 'false'
						? { _eq: false }
						: {},
			},
			overtimeFilters: {
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				timesheet_overtime_submission: {
					timesheet_status: { value: { _in: ['submitted', 'approved'] } },
				},
				is_billable:
					billableStatus === 'All'
						? {}
						: billableStatus === 'true'
						? { _eq: true }
						: billableStatus === 'false'
						? { _eq: false }
						: {},
			},
			user: {
				id: permissions?.appPermissions?.ui?.viewSubAccountDetails
					? choosedAssignee
						? { _eq: choosedAssignee }
						: {
								_in: [
									...(accountEmployeeUserIds || []),
									...(reporteeUserList?.map((usr: { id: string }) => usr?.id) ||
										[]),
								],
						  }
					: choosedAssignee !== null
					? { _eq: choosedAssignee }
					: {},
				is_active: { _eq: true },

				_or: getLocationFilter(),
			},
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		refetch();
	}, [startDate, endDate, refetch]);

	useEffect(() => {
		if (!resourceList || !holidayList) {
			return;
		}
		if (permissions?.appPermissions?.ui?.viewSubAccountDetails) {
			const allReporteeList = reporteeUserList?.map(
				(user: { id: string; full_name: string }) => {
					return {
						id: user?.id,
						name: user?.full_name,
					};
				}
			);
			const resourceOptionList = [
				...(allReporteeList || []),
				...(accountEmployeesDropdownOptions || []),
			]
				.filter((user: { id: string; name: string }) => user?.id !== userId)
				?.map((user: { id: string; name: string }) => {
					return {
						id: user?.id,
						name: user?.name,
					};
				});
			const sortedResourceList = orderBy(
				uniqBy([...resourceOptionList], 'id'),
				[(user) => user?.name?.toUpperCase()],
				['asc']
			);
			setAssigneeList(sortedResourceList);
		} else {
			const resourceOptionList = resourceList?.user?.map((user) => {
				return {
					id: user?.id,
					name: user?.full_name,
				};
			});
			const sortedResourceList = orderBy(
				resourceOptionList,
				[(user) => user?.name?.toUpperCase()],
				['asc']
			);
			setAssigneeList(sortedResourceList);
		}
		const holidayListWithoutWeekends = holidayList?.org_holidays?.filter(
			(holiday) =>
				dayjs(holiday?.date).get('day') !== 0 &&
				dayjs(holiday?.date).get('day') !== 6 &&
				holiday.is_restricted !== true
		);
		// Consider all holidays as holidays If there is no location created in the system
		const holidaysWithoutWeekendsAndDuplicates = uniqBy(
			holidayListWithoutWeekends,
			function (e) {
				return e.date;
			}
		);

		const workingHours = head(resourceList?.organization)?.working_hours;
		// const holidayCount = holidayListWithoutWeekends?.length || 0;
		const buisnessDays = DateTimeUtil.getBusinessDaysBetween(
			dayjs(startDate),
			dayjs(endDate)
		);
		// const totalDays = buisnessDays - holidayCount;
		const tableData = resourceList?.user?.map((user) => {
			const userLocationId =
				user?.user_type === 'employee'
					? user?.employee?.org_location?.id
					: head(user?.contractors)?.org_location?.id || '--';
			const userLocationHolidays = holidayListWithoutWeekends.filter(
				(hol) => hol?.org_location?.id === userLocationId
			);

			// Taken user location based holidays and make it unique by date field
			const userLocationHolidaysWithoutDuplicate = uniqBy(
				userLocationHolidays,
				function (e) {
					return e.date;
				}
			);
			const holidayCount =
				noOfLocations > 0
					? userLocationHolidaysWithoutDuplicate.length
					: holidaysWithoutWeekendsAndDuplicates?.length || 0;
			const totalDays = buisnessDays - holidayCount;
			const timesheetAggregateData = isOvertimeEnabled
				? timesheetType === 'overtime'
					? user?.timesheetOvertime
					: timesheetType === 'timesheet'
					? user?.timesheets
					: [...user?.timesheets, ...user?.timesheetOvertime]
				: user?.timesheets;
			const timesheetData = _.chain(timesheetAggregateData)
				.groupBy((item) => `${item?.date}`)
				.map((timeSheetEntry) => {
					const data: Duration = timeSheetEntry
						.map((timeSheet) => timeSheet?.working_hours?.split(':'))
						.map((hoursAndMinutes: string[]) =>
							dayjs.duration({
								hours: Number(hoursAndMinutes[0]),
								minutes: Number(hoursAndMinutes[1]),
								seconds: Number(hoursAndMinutes[2]),
							})
						)
						.reduce(
							(total: Duration, durations: Duration) => total.add(durations),
							dayjs.duration({
								hours: 0,
								minutes: 0,
								seconds: 0,
							})
						);

					return {
						date: head(timeSheetEntry)?.date,
						workingHours: data.asHours(),
					};
				})
				.value();
			const totalHours = timesheetData.reduce(function (acc, obj: any) {
				return acc + Number(obj.workingHours);
			}, 0);
			const utilizationPercentage =
				(totalHours / (workingHours * totalDays)) * 100;
			const employeeProjectList =
				user?.project_resource_mappings &&
				user?.project_resource_mappings?.length > 0
					? user?.project_resource_mappings?.map(
							(allocation) => allocation?.project?.name
					  )
					: [];

			const formattedEmployeeId = formatEmployeeId(
				user?.employee?.emp_id,

				empIdLength,
				isFixedEmpIdLengthEnabled
			);
			const formattedConsultantId = formatEmployeeId(
				head(user?.contractors)?.contractor_id,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);
			return {
				timesheet: timesheetData,
				fullName: user?.full_name,
				type: user?.user_type
					? user?.user_type === 'employee'
						? 'Employee'
						: 'Consultant'
					: '--',
				org_location:
					user?.user_type === 'employee'
						? user?.employee?.org_location?.name
						: head(user?.contractors)?.org_location?.name || '--',
				employeeId: user?.user_type === 'employee' ? user?.employee?.id : null,
				reportingTo:
					user?.user_type === 'contract_employee'
						? head(user?.contractors)?.reportee?.full_name || '--'
						: user?.employee?.reportsTo?.full_name || '--',
				Status:
					user?.user_type === 'contract_employee'
						? head(user?.contractors)?.contractorStatus?.label || '--'
						: user?.employee?.employee_status?.label || '--',
				reportingToEmail:
					user?.user_type === 'contract_employee'
						? head(user?.contractors)?.reportee?.email || '--'
						: user?.employee?.reportsTo?.email || '--',
				department:
					user?.user_type === 'contract_employee'
						? head(user?.contractors)?.department?.name || '--'
						: user?.employee?.department?.name || '--',
				totalHours: totalHours
					? isFloat(totalHours)
						? totalHours.toFixed(1)
						: totalHours
					: 0,
				utilization: utilizationPercentage
					? isFloat(utilizationPercentage)
						? utilizationPercentage.toFixed(1)
						: utilizationPercentage
					: 0,
				email: user?.email || '',
				employeeContractorId:
					user?.user_type === 'contract_employee'
						? head(user?.contractors)?.contractor_id
							? `${
									getPrefix(
										head(user?.contractors)?.contractor_prefix?.name,
										head(user?.contractors)?.contractor_prefix?.value
									) || ''
							  }${formattedConsultantId}`
							: '--'
						: user?.employee?.emp_id
						? `${
								getPrefix(
									user?.employee?.employee_prefix?.name,
									user?.employee?.employee_prefix?.value
								) || ''
						  }${formattedEmployeeId}`
						: '--',
				projectList: employeeProjectList.toString(),
			};
		});
		const sortedTimesheetData = orderBy(
			tableData,
			[(user) => user?.fullName?.toUpperCase()],
			['asc']
		);
		setTimeSheetTableList(sortedTimesheetData);
		const totalHours = tableData.reduce(function (acc, obj) {
			return acc + Number(obj.totalHours);
		}, 0);

		// Need to finalize the total utilization calculation
		// const totalUtilization =
		// 	(totalHours / (workingHours * totalDays * tableData?.length)) * 100;

		// Temperory Calculation
		const totalUtilization =
			tableData.reduce(function (acc, obj) {
				return acc + Number(obj.utilization);
			}, 0) / tableData.length;
		setTotalHours(
			isFloat(totalHours) ? Number(totalHours?.toFixed(1)) : totalHours
		);
		setTotalUtilization(
			isFloat(totalUtilization)
				? Number(totalUtilization?.toFixed(1))
				: totalUtilization
		);
	}, [
		resourceList,
		startDate,
		endDate,
		setTotalHours,
		setTotalUtilization,
		holidayList,
		isOvertimeEnabled,
		timesheetType,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		noOfLocations,
		reporteeUserList,
		accountEmployeesDropdownOptions,
		permissions,
		userId
	]);

	useEffect(() => {
		if (isEmpty(location?.org_location)) {
			return;
		}
		const locationOptionList = location?.org_location?.map((value) => {
			return {
				id: value?.id || '--',
				name: value?.name || '--',
			};
		});
		const defaultLocationIdList = locationOptionList?.map(
			(resource: { id: string; name: string }) => resource?.id
		);
		const sortedLocationOption = orderBy(locationOptionList, ['name'], ['asc']);
		setLocationDropDown([
			...(sortedLocationOption || []),
			{
				id: 'Unassigned',
				name: 'Unassigned',
			},
		]);
		setSelectedLocationIdList(defaultLocationIdList || []);
		setSelectedLocationOptionValue([
			{ id: 'All', name: 'All' },
			...(locationOptionList || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
	}, [location]);

	const generateCSV = () => {
		if (timeSheetTableList?.length === 0) {
			return;
		}
		const month = monthList?.map((date) => `${date?.month} ${date?.day}`);
		const tableHeaders = [...defaultersListColumns, ...month];
		const tableHeadersWithLocation = [
			...defaultersListColumnsWithLocation,
			...month,
		];
		const timesheetRows: any = timeSheetTableList.map((user) => {
			const timesheetDailyHours = monthList.map((date) => {
				const timesheetDate = user?.timesheet?.find(
					(timesheet: any) => timesheet.date === date.date
				);
				if (timesheetDate) {
					return timesheetDate?.workingHours;
				} else {
					return '';
				}
			});

			return noOfLocations && noOfLocations > 0
				? [
						user?.employeeContractorId,
						user?.fullName,
						user?.email,
						user?.type,
						user?.org_location || '--',
						user?.Status,
						user?.reportingTo,
						user?.reportingToEmail,
						user?.department,
						user?.projectList,
						user?.totalHours,
						user?.utilization,
						...timesheetDailyHours,
				  ]
				: [
						user?.employeeContractorId,
						user?.fullName,
						user?.email,
						user?.type,
						user?.Status,
						user?.reportingTo,
						user?.reportingToEmail,
						user?.department,
						user?.projectList,
						user?.totalHours,
						user?.utilization,
						...timesheetDailyHours,
				  ];
		});
		const timeSheetExportData = [
			noOfLocations && noOfLocations > 0
				? tableHeadersWithLocation
				: tableHeaders,
			...timesheetRows,
		];
		exportToCsv(
			`Timesheet_All_Resource_Report_From_${dayjs(startDate).format(
				dateFormat
			)}_TO_${dayjs(endDate).format(dateFormat)}.csv`,
			timeSheetExportData
		);
	};

	const getLocationFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLocationOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${classes.dropdownLabel} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const checkHoliday = (date: string) => {
		if (!date && !holidayList) {
			return;
		}
		const holiday = holidayList?.org_holidays.filter(
			(holiday) => holiday?.date === date
		);
		const month = dayjs(date).format('dddd').slice(0, 1);
		const holidayTitle =
			holiday &&
			holiday?.length > 1 &&
			holiday?.map(
				(item) =>
					`${
						item?.org_location?.name
							? `${item?.org_location?.name}: ${item?.name}`
							: `${item?.name}`
					}`
			);

		return (
			<Box display='flex'>
				<Tooltip title={dayjs(date).format('dddd') || ''} placement='right'>
					<div className={classes.circle}>{month}</div>
				</Tooltip>
				{holiday && head(holiday)?.date && (
					<Tooltip
						title={
							holidayTitle
								? holidayTitle.join(',')
								: head(holiday)?.date
								? head(holiday)?.org_location?.name
									? `${head(holiday)?.org_location?.name}: ${
											head(holiday)?.name
									  }`
									: `Holiday: ${head(holiday)?.name}`
								: ''
						}
						placement='right'
					>
						<div className={classes.holidayCircle}>H</div>
					</Tooltip>
				)}
			</Box>
		);
	};
	return (
		<>
			<ThemeProvider theme={insightChartTheme}>
				<Card>
					<Box>
						<Form
							initialValues={{
								billable: billableStatus,
								timesheet_type: timesheetType,
							}}
							onSubmit={() => {}}
						>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.header}>
											<Box display='flex'>
												<Box display='block'>
													<Box className={styles.label}>Resource</Box>
													<Box>
														<Autocomplete
															options={assigneeList || []}
															onChange={onResourceChange}
															style={{ width: 220 }}
															renderOption={(option: any) => (
																<Tooltip
																	title={`${option?.name || '- -'}`}
																	placement='right'
																>
																	<Typography
																		className={`${dropdownStyles.ellipsis}`}
																	>
																		{`${option?.name || '- -'}`}
																	</Typography>
																</Tooltip>
															)}
															getOptionLabel={(option: any) =>
																`${option?.name}`
															}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextField
																		{...params}
																		label=''
																		placeholder='Search resource'
																	/>
																</ThemeProvider>
															)}
														/>
													</Box>
												</Box>

												<Box marginLeft='10px' display='block'>
													<Box className={styles.label}>Billable</Box>
													<Box>
														<SelectInput
															source='billable'
															label=''
															choices={[
																{ id: 'All', name: 'All Task' },
																{ id: 'true', name: 'Billable Task' },
																{ id: 'false', name: 'Non-billable Task' },
															]}
															onChange={(e: any) => {
																setPage(0);
																setBillableStatus(e?.target?.value);
															}}
														/>
													</Box>
												</Box>
												{isOvertimeEnabled && (
													<Box display='block' marginLeft='5px'>
														<Box className={styles.label}>Task type</Box>
														<Box>
															<SelectInput
																key={timesheetType}
																source='timesheet_type'
																label=''
																choices={timesheetTypes}
																onChange={(e: any) => {
																	setTimesheetType(e?.target?.value);
																}}
															/>
														</Box>
													</Box>
												)}
												{noOfLocations && noOfLocations > 0 && (
													<Box
														className={classes.multipleInput}
														minWidth='180px'
													>
														<Box className={styles.label}>Location</Box>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedLocationOptionValue &&
																selectedLocationOptionValue?.length > 0
																	? selectedLocationOptionValue
																	: []
															}
															style={{ width: 180 }}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter((status) => status.id !== 'All')
																	.map((status) => status?.id);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																	]);
																	const allStatusIds = locationDropDown?.map(
																		(status) => status?.id
																	);
																	setSelectedLocationIdList(allStatusIds);

																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	locationDropDown?.length !==
																		selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(status) => status?.id !== 'All'
																	);
																	setSelectedLocationOptionValue(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource) => resource?.id
																	);
																	setSelectedLocationIdList(currentIds);

																	return;
																}
																if (
																	selectedIds?.length ===
																		locationDropDown?.length &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																	]);
																	const responseIds = locationDropDown?.map(
																		(status) => status?.id
																	);
																	setSelectedLocationIdList(responseIds);

																	return;
																}
																if (
																	selectedLocationOptionValue.find(
																		(option) => option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedLocationIdList([]);
																	setSelectedLocationOptionValue([]);

																	return;
																}
																const selectedId = value?.map(
																	(status) => status?.id
																);
																setSelectedLocationIdList(selectedId);
																setSelectedLocationOptionValue(value);
															}}
															options={
																locationDropDown && locationDropDown?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...locationDropDown,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getLocationFilterLimits}
															ChipProps={{
																style: { display: 'none' },
															}}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextField
																		{...params}
																		label=''
																		placeholder={''}
																	/>
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${dropdownStyles.ellipsis}`}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												)}
											</Box>
											<Box className={classes.exportButtonDiv}>
												<ExportButtonWithLoader
													generateCSV={generateCSV}
													isDisabled={!timeSheetTableList?.length}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
					<CardContent>
						<Box className={classes.tableContainer}>
							{timeSheetTableList && timeSheetTableList?.length > 0 ? (
								<TableContainer>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<CustomTableCell className={classes.headerCell}>
													RESOURCE
												</CustomTableCell>
												<CustomTableCell className={classes.headerCell}>
													TYPE
												</CustomTableCell>
												{noOfLocations && noOfLocations > 0 && (
													<CustomTableCell className={classes.headerCell}>
														LOCATION
													</CustomTableCell>
												)}
												<CustomTableCell className={classes.headerCell}>
													REPORTING TO
												</CustomTableCell>
												<CustomTableCell className={classes.headerCell}>
													DEPARTMENT
												</CustomTableCell>
												<CustomTableCell className={classes.headerCell}>
													TOTAL HOURS
												</CustomTableCell>
												<CustomTableCell className={classes.headerCell}>
													UTILIZATION(%)
												</CustomTableCell>
												{monthList &&
													monthList?.map((month) => (
														<TableCell className={classes.monthCell}>
															<Tooltip
																title={
																	dayjs(month?.date).format('dddd') || '--'
																}
																placement='top'
															>
																<Box className={classes.dateCell}>
																	<Box
																		display='flex'
																		className={
																			Number(dayjs(month?.date).format('d')) ===
																				0 ||
																			Number(dayjs(month?.date).format('d')) ===
																				6
																				? classes.weekEndCell
																				: ''
																		}
																	>
																		<span>{month?.month}</span>
																		<span>{`-${month?.day}`}</span>
																	</Box>
																	<span>{checkHoliday(month?.date)}</span>
																</Box>
															</Tooltip>
														</TableCell>
													))}
											</TableRow>
										</TableHead>

										<TableBody>
											{timeSheetTableList &&
												timeSheetTableList?.length > 0 &&
												timeSheetTableList
													?.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map(
														(timeSheet) =>
															timeSheetTableList?.length > 0 && (
																<TableRow hover={true} key={timeSheet?.id}>
																	<CustomTableCell>
																		<Tooltip title={`${timeSheet?.fullName}`}>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{timeSheet?.fullName}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		{timeSheet?.type}
																	</CustomTableCell>
																	{noOfLocations && noOfLocations > 0 && (
																		<CustomTableCell key={timeSheet?.id}>
																			<Tooltip
																				title={`${
																					timeSheet?.org_location
																						? timeSheet?.org_location
																						: '--'
																				}`}
																			>
																				<Typography
																					className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{timeSheet?.org_location
																						? timeSheet?.org_location
																						: '--'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																	)}

																	<CustomTableCell>
																		<Tooltip
																			title={`${timeSheet?.reportingTo}`}
																		>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{timeSheet?.reportingTo}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip title={`${timeSheet?.department}`}>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{timeSheet?.department}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		{isFloat(timeSheet?.totalHours)
																			? Number(timeSheet?.totalHours).toFixed(1)
																			: timeSheet?.totalHours}
																	</CustomTableCell>
																	<CustomTableCell>
																		{timeSheet?.utilization || 0}
																	</CustomTableCell>
																	{monthList &&
																		monthList?.map((month) => {
																			const dateArray = timeSheet?.timesheet;
																			const leaveMapping =
																				leaveList?.employee_leaving_mapping?.find(
																					(leave: any) =>
																						leave?.employee_id ===
																						timeSheet?.employeeId
																				);
																			const mappingTimeSheet = dateArray?.find(
																				(item: any) =>
																					item?.date === month?.date
																			);
																			if (mappingTimeSheet) {
																				return (
																					<TableCell>
																						<div
																							className={
																								leaveMapping?.from_date ===
																									month?.date ||
																								(month?.date >
																									leaveMapping?.from_date &&
																									month?.date <=
																										leaveMapping?.to_date)
																									? `${classes.timeSheetWithDateWithLeave} ${classes.weekEndCell}`
																									: classes.timeSheetWithDate
																							}
																						>
																							{isFloat(
																								mappingTimeSheet?.workingHours
																							)
																								? Number(
																										mappingTimeSheet?.workingHours
																								  ).toFixed(1)
																								: mappingTimeSheet?.workingHours ||
																								  ''}
																						</div>
																					</TableCell>
																				);
																			} else {
																				return (
																					<TableCell>
																						<div
																							className={
																								leaveMapping?.from_date ===
																									month?.date ||
																								(month?.date >
																									leaveMapping?.from_date &&
																									month?.date <=
																										leaveMapping?.to_date)
																									? `${classes.timeSheetWithoutDate} ${classes.weekEndCell}`
																									: `${classes.timeSheetWithoutDate}`
																							}
																						>
																							--
																						</div>
																					</TableCell>
																				);
																			}
																		})}
																</TableRow>
															)
													)}
										</TableBody>
									</Table>
									<Box display='flex' justifyContent='flex-end'>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											count={timeSheetTableList?.length || 0}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</Box>
								</TableContainer>
							) : (
								<Box className={classes.noData}>
									<Typography className={classes.noDataLabel}>
										No data found
									</Typography>
								</Box>
							)}
						</Box>
					</CardContent>
				</Card>
			</ThemeProvider>
		</>
	);
};
