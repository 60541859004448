import React, { useContext, useEffect, useState } from 'react';
import {
	editableColumnsStyle,
	editableColumnsTheme,
	ellipsisStyle,
} from '../../../Layout/styles';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { Box, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { head, isEmpty } from 'lodash';
import { UserProfileContext } from '../../../App';
import { useNotify, useRefresh } from 'ra-core';
import {
	useGetPmsEmailNotificationListQuery,
	useUpdateOrganisationEmailEnabledMutation,
	useUpdatePmsNotificationMutation,
} from '../../../generated/graphql';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import EmailNotificationWarningModal from '../../Settings/Organisation/EmailNotificationWarningModal.component';
import { tableListStyle } from '../../Settings/settingsStyle';
import RecipientListModal from './PmsRecipientListModal.component';
import SettingsDetailContainer from '../../Settings/SettingsDetailContainer.component';
import { EMAIL_RECIPIENT_NOT_EDITABLE } from '../constant';

export interface RecipientRole {
	id?: string | undefined;
	name?: string[] | undefined;
}
export interface RecipientData {
	role: RecipientRole[];
}

const PmsEmailNotification = () => {
	const { orgId } = useContext<any>(UserProfileContext);
	const [isOrgEnable, setIsOrgEnable] = useState(false);
	const [isEmailEnable, setIsEmailEnable] = useState(false);
	const [isAddNewRoleModalShown, setIsAddNewRoleModalShown] = useState(false);
	const [isOrganizationEnable, setIsOrganizationEnable] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [emailNotification, setEmailNotiification] = useState({
		id: null,
		is_enabled: false,
	});
	const [currentNotificationId, setCurrentNotificationId] = useState<
		string | null | undefined
	>(null);
	const [currentNotificationReceivers, setCurrentNotificationReceivers] =
		useState<string[]>();
	const editableColumnStyles = editableColumnsStyle();
	const classes = tableListStyle();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const { data: emailList, refetch } = useGetPmsEmailNotificationListQuery({
		variables: {
			orgId: orgId,
		},
	});
	const [updateOrganizationEmailEnabled] =
		useUpdateOrganisationEmailEnabledMutation();
	const [updateEmailNotiification] = useUpdatePmsNotificationMutation();
	useEffect(() => {
		if (isEmpty(emailList?.organization)) {
			setIsOrgEnable(false);
			return;
		}
		const isOrgEnable: boolean =
			head(emailList?.organization)?.is_email_enabled || false;
		setIsOrgEnable(isOrgEnable);
	}, [emailList]);

	const handleEmailEnabled = async () => {
		try {
			const response = await updateOrganizationEmailEnabled({
				variables: {
					organisationId: orgId,
					data: {
						is_email_enabled: isOrganizationEnable,
					},
				},
			});
			if (response?.data?.update_organization_by_pk?.id) {
				refresh();
				notify(
					`Organization Email Notification ${
						response?.data?.update_organization_by_pk?.is_email_enabled
							? 'enabled'
							: 'disabled'
					} successfully`
				);
				setIsWarningModalShown(false);
				setIsEmailEnable(false);
				refetch();
				return;
			}
		} catch (error: any) {
			console.log(JSON.stringify(error));
			return;
		}
	};
	const handleEmailNotiification = async () => {
		try {
			const response = await updateEmailNotiification({
				variables: {
					id: emailNotification?.id,
					data: {
						is_enabled: emailNotification?.is_enabled,
					},
				},
			});
			if (response?.data?.update_pms_pms_email_notification_by_pk?.id) {
				refresh();
				notify(
					`${response?.data?.update_pms_pms_email_notification_by_pk?.name} 
					Email Notification ${
						response?.data?.update_pms_pms_email_notification_by_pk?.is_enabled
							? 'Enabled'
							: 'Disabled'
					} Successfully`
				);
				setIsWarningModalShown(false);
				setIsEmailEnable(false);
				refetch();
				return;
			}
		} catch (error: any) {
			console.log(JSON.stringify(error));
			return;
		}
	};

	return (
		<SettingsDetailContainer heading={'PMS Email Notification'}>
			<Box className={editableColumnStyles.contentContainer}>
				<Typography className={editableColumnStyles.description}>
					Turn off to deactivate email for entire organization
				</Typography>
				<ThemeProvider theme={editableColumnsTheme}>
					<div>
						<Box
							className={editableColumnStyles.inputContainer}
							display='flex'
							flexDirection='column'
						>
							<>
								<div style={{ display: 'flex' }}>
									<FormGroup>
										<FormControlLabel
											control={
												<Switch
													name='is_email_enabled'
													color='primary'
													checked={isOrgEnable}
													onChange={(event: any) => {
														setIsWarningModalShown(true);
														setIsOrganizationEnable(event?.target.checked);
													}}
												/>
											}
											label='Enable Organisation Email Notification'
										/>
									</FormGroup>
								</div>
								<Box
									marginTop={'35px'}
									className={
										!head(emailList?.organization)?.is_email_enabled
											? editableColumnStyles.disableDiv
											: ''
									}
								>
									{emailList &&
									emailList?.pms_pms_email_notification?.length > 0
										? emailList.pms_pms_email_notification.map((value) => (
												<Box
													className={editableColumnStyles.emailPolicyContainer}
													key={value.id}
												>
													<Box display='flex' alignItems='center'>
														<Box display='flex' mr={1}>
															<FormGroup>
																<FormControlLabel
																	control={
																		<Switch
																			name='is_email_enabled'
																			color='primary'
																			disabled={
																				!head(emailList?.organization)
																					?.is_email_enabled
																			}
																			checked={
																				head(emailList?.organization)
																					?.is_email_enabled &&
																				value?.is_enabled
																					? true
																					: false
																			}
																			onChange={(event: any) => {
																				setIsWarningModalShown(true);
																				setIsEmailEnable(true);
																				setEmailNotiification({
																					id: value?.id,
																					is_enabled: event.target.checked,
																				});
																			}}
																		/>
																	}
																	label={''}
																/>
															</FormGroup>
														</Box>
														<Box>
															<Typography className={classes.label}>
																Name
															</Typography>
															<Tooltip
																title={`${value.name}`}
																placement='right'
															>
																<Typography
																	className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
																>
																	{value?.name}
																</Typography>
															</Tooltip>
														</Box>
													</Box>
													<Box>
														<Typography className={classes.label}>
															Description
														</Typography>
														<Tooltip
															title={`${value.description || '--'}`}
															placement='right'
														>
															<Typography
																className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
															>
																{value?.description || '--'}
															</Typography>
														</Tooltip>
													</Box>
													<Box>
														<div
															className={classes.employeesContainer}
															onClick={() => {
																if (!value?.is_recipient_editable) {
																	notify(
																		EMAIL_RECIPIENT_NOT_EDITABLE,
																		'warning'
																	);
																	return;
																}
																setIsAddNewRoleModalShown(true);
																setCurrentNotificationId(value?.id);
																setCurrentNotificationReceivers(
																	value?.receivers
																);
															}}
														>
															<PeopleOutlineIcon
																className={classes.peoplesIcon}
															/>
															<Typography
																className={`${classes.rowContent} ${classes.employeesCount}`}
															>
																{value?.receivers?.length}
															</Typography>
														</div>
													</Box>
													<Box>
														<Typography className={classes.label}></Typography>
														<Tooltip
															title={`${value.email_type?.label}`}
															placement='left-start'
														>
															<Typography
																className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
															>
																{value?.email_type?.value ===
																'scheduledTrigger' ? (
																	<AccessAlarmIcon
																		fontSize='small'
																		className={editableColumnStyles.emailType}
																	/>
																) : (
																	<OfflineBoltOutlinedIcon
																		fontSize='small'
																		className={editableColumnStyles.emailType}
																	/>
																)}
															</Typography>
														</Tooltip>
													</Box>
												</Box>
										  ))
										: 'No Email Notification'}
								</Box>
							</>
						</Box>
					</div>
				</ThemeProvider>

				<RecipientListModal
					open={isAddNewRoleModalShown}
					onClose={() => {
						setIsAddNewRoleModalShown(false);
					}}
					onSuccess={refetch}
					emailNotificationId={currentNotificationId}
					emailNotificationReceiver={currentNotificationReceivers}
					name={'Recipient List'}
				/>
				<EmailNotificationWarningModal
					open={isWarningModalShown}
					onClose={() => {
						setIsWarningModalShown(false);
						setIsEmailEnable(false);
					}}
					confirmationMessage={'Are you sure you want to proceed?'}
					onSave={isEmailEnable ? handleEmailNotiification : handleEmailEnabled}
				/>
			</Box>
		</SettingsDetailContainer>
	);
};

export default PmsEmailNotification;
