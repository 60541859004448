import dayjs from 'dayjs';
import { PMS_DATE_FORMAT } from './constant';

export const getEndDate = (date: Date) => {
	if (!date) {
		return '- -';
	}
	const today = dayjs().format('YYYY-MM-DD');
	const dateDiff = dayjs(date).diff(today);

	if (dateDiff < 0) {
		return dayjs(date).format(PMS_DATE_FORMAT);
	}

	if (dateDiff === 0) {
		return 'Today';
	}

	if (dateDiff <= 7) {
		return `${dateDiff} days left`;
	}

	return dayjs(date).format(PMS_DATE_FORMAT);
};

export const validateGoalCommentDescription = (
	value: string,
	maxLength?: string | number
) => {
	const min =
		typeof maxLength === 'string' ? parseInt(maxLength, 10) : maxLength;
	if (!value) {
		return 'Required';
	}
	if (value.trim() === '') {
		return 'Required';
	}
	if (min !== undefined && !isNaN(min) && value.trim().length < min) {
		return `Must be ${min} characters at least`;
	}
	return undefined;
};
