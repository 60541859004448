import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Avatar,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	Checkbox,
	Box,
	Tooltip,
	ThemeProvider,
	makeStyles,
	TextField as TextComponent,
} from '@material-ui/core';

import {
	OVERTIME_APPROVAL_SUCCESS_TEXT,
	OVERTIME_APPROVAL_MODAL_BUTTON_LABEL,
	OVERTIME_REJECT_MODAL_BUTTON_LABEL,
	OVERTIME_REJECT_SUCCESS_TEXT,
} from './constant';

import { modalFormStyle } from '../../../Layout/styles';

import {
	customAutoCompleteSearchBarTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';

import {
	useGetTimesheetStatusQuery,
	GetSubmittedOvertimeQuery,
	useGetPendingApprovalUsersForOvertimeQuery,
	useGetPendingOvertimeListForApprovalQuery,
	useApproveOvertimeMutation,
	useRejectOvertimeEntriesMutation,
} from '../../../generated/graphql';
import { UserProfileContext } from '../../../App';
import { Loading } from 'ra-ui-materialui';
import dayjs from 'dayjs';
import { OvertimeSubmission, User } from './Overtime.model';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ConfirmationModal from '../../../SharedComponents/ConfirmationModal.component';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useNotify } from 'react-admin';
import {
	TimesheetStyles,
	TimesheetHistoryPendingApprovalStyle,
} from '../TimesheetStyles';
import { ellipsisStyle, autocompleteStyle } from '../../../Layout/styles';
import {
	getTotalNonBillableHours,
	getTotalBillableHours,
	getTimesheetEntriesBillablePercentage,
	getTotalHours,
} from './Overtime.utils';
import { head, isEmpty, orderBy, uniq } from 'lodash';
import no_data_found from '../../../assets/no_data_found.png';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from '@material-ui/lab';
import TimesheetOvertimePendingApprovalDetailsModal from './TimesheetOvertimePendingApprovalDetailsModal.component';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

interface Props {
	refetch: () => void;
}
export const TimesheetOvertimePendingApproval = (props: Props) => {
	const useStyles = makeStyles((theme) => ({
		title: {
			fontSize: '12px',
			fontFamily: 'Manrope-medium',
			color: '#292929',
			width: '90px',
		},
		projectName: {
			fontSize: '12px',
			width: '112px',
			fontFamily: 'Manrope-medium',
			color: '#292929',
		},
		budget: {
			fontSize: '12px',
			fontFamily: 'Manrope-medium',
			color: '#292929',
		},
		startDate: {
			fontSize: '12px',
			fontFamily: 'Manrope-medium',
			color: '#292929',
			width: '80px',
		},
		filterButton: {
			marginRight: '11px',
			width: '89px',
			height: '36px',
			background: '#FFFFFF 0% 0% no-repeat padding-box',
			border: '1px solid #E0E0E0',
			borderRadius: '4px',
			opacity: 1,
			fontSize: '12px',
			font: 'normal normal bold 12px/37px Manrope',
			color: '#4285F4',
			textTransform: 'none',
		},
		addButton: {
			'&:hover': {
				background: '#4285F4',
			},
			width: '140px',
			height: '36px',
			fontSize: '12px',
			background: ' #4285F4 0% 0% no-repeat padding-box',
			color: '#FFFFFF',
			textTransform: 'capitalize',
			fontFamily: 'Manrope-Bold',
			marginRight: '10px',
		},
		pageTitle: {
			fontFamily: 'Manrope-extrabold',
			marginTop: '20px',
			textTransform: 'uppercase',
			fontSize: '14px',
			marginBottom: '12px',
		},
		listStyle: {
			marginTop: '-50px',
		},
		statusContainer: {
			background: '#EBF3FF 0% 0% no-repeat padding-box',
			border: '1px solid #E0E0E0',
			borderRadius: '4px',
			paddingLeft: '10px',
			fontSize: '12px',
			fontFamily: 'Manrope-medium',
			width: '80px',
		},
		filterStyle: {
			marginTop: '-64px',
			marginBottom: '5px',
		},
		progressBar: {
			display: 'flex',
			alignItems: 'center',
		},
		percentage: {
			marginLeft: '10px',
		},
		completed: {
			backgroundColor: '#E9F7EC',
			'& span': {
				color: '#34A853',
			},
		},
		lost: {
			backgroundColor: '#9b99995c',
			'& span': {
				color: '#5e5e5e',
			},
		},
		inProgress: {
			backgroundColor: '#EBF3FF',
			'& span': {
				color: '#4285F4',
			},
		},
		pending: {
			backgroundColor: '#FFF3F2',
			'& span': {
				color: '#EA4335',
			},
		},
		notStarted: {
			backgroundColor: '#FFF3F2',
			'& span': {
				color: '#EA4335',
			},
		},
		riskGreen: {
			display: 'block',
			width: '20px',
			height: '20px',
			backgroundColor: '#388E3C',
			borderRadius: '50%',
		},
		riskRed: {
			display: 'block',
			width: '20px',
			height: '20px',
			backgroundColor: '#ef5350',
			borderRadius: '50%',
		},
		riskAmber: {
			backgroundColor: '#ffbf006e',
			'& span': {
				color: '#ff8f00',
			},
		},
		editButtonContainer: {
			position: 'absolute',
			right: '6px',
			top: '6px',
			opacity: 1,
			borderRadius: '100px',
		},
		editButton: {
			background: '#FFFFFF 0% 0% no-repeat padding-box',
			border: '1px solid #E0E0E0',
			opacity: 1,
			width: '24px',
			height: '24px',
			borderRadius: '100px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
			'&:hover ': {
				background: '#4285F4',
				'& $editIcon': {
					color: '#FFFFFF',
				},
			},
		},
		editIcon: {
			width: '12px',
			height: '12px',
			color: '#4285F4',
			'&:hover': {
				color: '#FFFFFF',
			},
		},
		editText: {
			fontFamily: 'Manrope-SemiBold',
			opacity: 1,
			color: 'black',
			letterSpacing: '0px',
			fontSize: '10px',
			paddingLeft: '5px',
		},
		deleteIcon: {
			color: 'rgb(220, 0, 78)',
			width: '10px',
			height: '10px',
		},
		projectFilterStatus: {
			background: '#ffffff',
			height: '36px',
			marginLeft: '10px',
			marginTop: '23px',
		},
		actionAdminContainer: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		rejectButton: {
			background: '#ED3D3D',
		},
		dropdownLabel: {
			marginTop: '7px',
			color: '#4e4e4e',
			marginLeft: '10px',
		},
	}));
	const { refetch } = props;
	const { id: userId } = useContext<any>(UserProfileContext);
	const modalFormStyles = modalFormStyle();
	const styles = modalFormStyle();
	const style = ellipsisStyle();
	const dropDownStyle = autocompleteStyle();
	const overtimePendingApprovalStyles = TimesheetHistoryPendingApprovalStyle();
	const OvertimeStyles = TimesheetStyles();
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState(false);
	const [submissionIds, setSubmissionIds] = useState<any>([]);
	const [isDetailsModalShown, setIsDetailsModalShown] = useState(false);
	const [selectedOvertime, setSelectedOvertime] = useState({});
	const [overtimeStatusOptions, setOvertimeStatusOptions] = useState<any>();
	const [submittedOvertime, setSubmittedOvertime] =
		useState<GetSubmittedOvertimeQuery>();
	const notify = useNotify();
	const classes = useStyles();
	const [isRejectOvertime, setIsRejectOvertime] = useState(false);
	const [isApproveOvertime, setIsApproveOvertime] = useState(false);
	const [reporte, setReportee] = useState<any>();
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<any>([]);
	const [selectedStatusOptionValue, setSelectedStatusOptionValues] =
		useState<any>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<any>([]);
	const [selectedStatusIdList, setSelectedStatusIdList] = useState<any>([]);

	const {
		data: pendingApprovalTimesheet,
		refetch: refetchSubmittedTimesheets,
		loading: submittedTimesheetsLoading,
	} = useGetPendingOvertimeListForApprovalQuery({
		variables: {
			filters: {
				is_active: { _eq: true },
				id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? {
								_in: selectedResourceIdList,
						  }
						: { _in: [] },
			},
			timesheetStatusId:
				selectedStatusIdList && selectedStatusIdList?.length > 0
					? {
							_in: selectedStatusIdList,
					  }
					: { _in: [] },
		},
		fetchPolicy: 'network-only',
	});

	const { data: reporteUserConsultant } =
		useGetPendingApprovalUsersForOvertimeQuery({
			variables: {
				userId,
			},
			fetchPolicy: 'network-only',
		});

	const { data: timesheetStatus } = useGetTimesheetStatusQuery();
	const [approveOvertime, { loading: isApproveOvertimeLoading }] =
		useApproveOvertimeMutation();
	const [rejectOvertime, { loading: isRejectOvertimeLoading }] =
		useRejectOvertimeEntriesMutation();

	useEffect(() => {
		if (isEmpty(timesheetStatus?.timesheet_status)) {
			return;
		}
		const timesheetStatusDropdownOptions = timesheetStatus?.timesheet_status
			?.filter((value) => value.value !== 'draft')
			.map((timesheet) => {
				if (!timesheet) {
					return;
				}
				return {
					id: timesheet.id,
					name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
				};
			});
		const pendingStatus = timesheetStatusDropdownOptions?.find(
			(status: any) => status?.name === 'Pending'
		);
		if (pendingStatus) {
			setSelectedStatusOptionValues([pendingStatus]);
			setSelectedStatusIdList([pendingStatus?.id]);
		}
		const sortedTimesheetStatusDropdownOptions = orderBy(
			timesheetStatusDropdownOptions,
			['name'],
			['asc']
		);
		setOvertimeStatusOptions(sortedTimesheetStatusDropdownOptions);
	}, [timesheetStatus]);

	useEffect(() => {
		if (!reporteUserConsultant) {
			return;
		}
		const filterUserList = reporteUserConsultant.timesheet_overtime.map(
			(value) => {
				return {
					id: value?.userByEmployeeId?.id,
					name: `${value?.userByEmployeeId?.full_name} `,
				};
			}
		);
		const idList = filterUserList?.map((resource) => resource?.id);
		setReportee(filterUserList);
		setSelectedResourceIdList(idList);
		setSelectedResourceOptionValues([
			{ id: 'All', name: 'All' },
			...filterUserList,
		]);
	}, [reporteUserConsultant]);

	useEffect(() => {
		if (!pendingApprovalTimesheet) {
			return;
		}
		const timesheets = pendingApprovalTimesheet.user
			.filter((user) => user.timesheetOvertimeSubmissions.length > 0)
			.map((user) => ({
				...user,
				timesheetSubmissions: user.timesheetOvertimeSubmissions.filter(
					(timesheetSubmission) =>
						timesheetSubmission.timesheet_overtimes.length > 0
				),
			}))
			.filter((value) => value?.timesheetSubmissions.length > 0);
		if (timesheets) {
			setSubmittedOvertime({ user: timesheets });
		}
	}, [pendingApprovalTimesheet]);

	const handleApproveOvertime = async () => {
		if (!timesheetStatus) {
			return;
		}
		const approvedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'approved'
		);
		const timesheetApproval = await approveOvertime({
			variables: {
				submissionIds: submissionIds,
				approvedBy: userId,
				timesheetStatusId: approvedTimesheetStatus?.id,
			},
		});
		if (!timesheetApproval.errors) {
			setSubmissionIds([]);
			refetchSubmittedTimesheets();
			setIsApproveOvertime(false);
			setIsRejectOvertime(false);
			setIsConfirmationModalShown(false);
			notify(OVERTIME_APPROVAL_SUCCESS_TEXT);
			refetch();
		}
	};

	const handleRejectOvertime = async () => {
		if (!timesheetStatus) {
			return;
		}
		const rejectedOvertimeStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'rejected'
		);
		const overtimeReject = await rejectOvertime({
			variables: {
				submissionIds: submissionIds,
				timesheetStatusId: rejectedOvertimeStatus?.id,
			},
		});
		if (!overtimeReject.errors) {
			setSubmissionIds([]);
			setIsRejectOvertime(false);
			setIsApproveOvertime(false);
			refetchSubmittedTimesheets();
			setIsConfirmationModalShown(false);
			notify(OVERTIME_REJECT_SUCCESS_TEXT);
			refetch();
		}
	};
	const getOvertimeEnteredTaskName = (overtime: any) => {
		const taskName = overtime?.task_id
			? overtime?.project_task?.title
			: overtime?.org_task_id
			? overtime?.org_task?.title
			: overtime?.master_project_task_id
			? overtime?.master_project_task?.title
			: '';

		return taskName;
	};

	const getOvertimeEnteredProjectName = (overtime: any | undefined) => {
		const projectName = overtime?.task_id
			? overtime?.project?.name
			: overtime?.org_task_id
			? overtime?.org_task?.task_category
			: '';

		return projectName;
	};

	const handleRowClick = (
		overtimeSubmission: OvertimeSubmission,
		userDetails: User
	) => {
		if (!overtimeSubmission?.timesheet_overtimes[0]?.date) {
			return;
		}
		setSelectedOvertime({
			user: {
				id: userDetails.id,
				name: userDetails?.name,
				profilePic: userDetails?.profilePic,
			},
			billablePercentage: getTimesheetEntriesBillablePercentage(
				overtimeSubmission.timesheet_overtimes
			),
			billableHours: getTotalBillableHours(
				overtimeSubmission.timesheet_overtimes
			),
			nonBillableHours: getTotalNonBillableHours(
				overtimeSubmission.timesheet_overtimes
			),
			totalHours: getTotalHours(overtimeSubmission?.timesheet_overtimes),
			timesheetSubmissionId: overtimeSubmission.id,
			timesheetStatus: overtimeSubmission?.timesheet_status?.value,
			date: overtimeSubmission?.timesheet_overtimes
				? head(overtimeSubmission?.timesheet_overtimes)?.date
				: '',
			projectName: overtimeSubmission?.timesheet_overtimes
				? getOvertimeEnteredProjectName(
						head(overtimeSubmission?.timesheet_overtimes)
				  )
				: '',
			taskName: overtimeSubmission?.timesheet_overtimes
				? getOvertimeEnteredTaskName(
						head(overtimeSubmission?.timesheet_overtimes)
				  )
				: '',
			notes: overtimeSubmission?.timesheet_overtimes
				? head(overtimeSubmission?.timesheet_overtimes)?.notes
				: '',
			enableActionButtons: overtimeSubmission?.timesheet_status?.value
				? overtimeSubmission?.timesheet_status?.value === 'submitted'
					? true
					: false
				: false,
		});
	};

	const onHandleSelectAll = (overtimeSubmissions: any) => {
		if (!overtimeSubmissions && !submissionIds) {
			return;
		}
		if (!isEmpty(submissionIds)) {
			const record = overtimeSubmissions
				?.filter(
					(overtimeSubmissions: any) =>
						overtimeSubmissions.timesheet_status.value !== 'approved' &&
						overtimeSubmissions.timesheet_status.value !== 'rejected'
				)
				.map((timesheet: any) => timesheet?.id);

			if (record.every((id: string) => submissionIds.includes(id))) {
				return true;
			} else return false;
		}
	};
	const getProjectResourceFilterLimits = (value: any) => {
		if (!value) {
			return;
		}
		const hasAll = selectedResourceOptionValue?.some(
			(option: any) => option.id === 'All'
		);
		const actualCount = hasAll ? value - 1 : value;

		return (
			<div className={`${dropDownStyle.dropdownValue} ${style.ellipsis}`}>
				{selectedResourceOptionValue &&
				selectedResourceOptionValue?.length === 1
					? selectedResourceOptionValue[0].name
					: `Selected ${actualCount} items`}
			</div>
		);
	};

	const getProjectStatusFilterLimits = (value: any) => {
		if (!value) {
			return;
		}
		const hasAll = selectedStatusOptionValue?.some(
			(option: any) => option.id === 'All'
		);
		const actualCount = hasAll ? value - 1 : value;

		return (
			<div className={`${dropDownStyle.dropdownValue} ${style.ellipsis}`}>
				{selectedStatusOptionValue && selectedStatusOptionValue?.length === 1
					? selectedStatusOptionValue[0].name
					: `Selected ${actualCount} items`}
			</div>
		);
	};

	return (
		<>
			<div className={overtimePendingApprovalStyles.container}>
				<div className={classes.actionAdminContainer}>
					<ThemeProvider theme={modalFormTheme}>
						<Box display='flex'>
							<Box width='200px' marginRight='15px'>
								<Box className={styles.label}>Search User</Box>
								<Autocomplete
									multiple
									limitTags={0}
									value={
										selectedResourceOptionValue &&
										selectedResourceOptionValue?.length > 0
											? selectedResourceOptionValue
											: []
									}
									selectOnFocus={true}
									disablePortal
									fullWidth={false}
									onChange={(event, value, reason) => {
										if (!value) {
											return;
										}
										const selectedIds = value
											?.filter((resource: any) => resource.id !== 'All')
											.map((resource: any) => resource?.id);
										if (
											value.find((option) => option.id === 'All') &&
											reason === 'select-option'
										) {
											setSelectedResourceOptionValues([
												{ id: 'All', name: 'All' },
												...reporte,
											]);
											const allResourceIds = reporte?.map(
												(resource: any) => resource?.id
											);
											setSelectedResourceIdList(allResourceIds);
											return;
										}
										if (
											value.find((option) => option.id === 'All') &&
											reason === 'remove-option' &&
											reporte?.length !== selectedIds?.length
										) {
											const currentOptions = value?.filter(
												(resource: any) => resource?.id !== 'All'
											);
											setSelectedResourceOptionValues(currentOptions);
											const currentIds = currentOptions?.map(
												(resource: any) => resource?.id
											);
											setSelectedResourceIdList(currentIds);
											return;
										}
										if (
											selectedIds?.length === reporte?.length &&
											reason === 'select-option'
										) {
											setSelectedResourceOptionValues([
												{ id: 'All', name: 'All' },
												...reporte,
											]);
											const reporteeIds = reporte?.map(
												(resource: any) => resource?.id
											);
											setSelectedResourceIdList(reporteeIds);
											return;
										}
										if (
											selectedResourceOptionValue.find(
												(option: any) => option?.id === 'All'
											) &&
											reason === 'remove-option'
										) {
											setSelectedResourceIdList([]);
											setSelectedResourceOptionValues([]);
											return;
										}
										const selectedId = value?.map((resource) => resource?.id);
										setSelectedResourceIdList(selectedId);
										setSelectedResourceOptionValues(value);
									}}
									options={
										reporte && reporte?.length > 0
											? [{ id: 'All', name: 'All' }, ...reporte]
											: []
									}
									disableCloseOnSelect={true}
									getLimitTagsText={getProjectResourceFilterLimits}
									ChipProps={{ style: { display: 'none' } }}
									renderInput={(params) => (
										<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
											<TextComponent {...params} label='' />
										</ThemeProvider>
									)}
									renderOption={(option, { selected }) => (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected}
												color='primary'
											/>

											<Tooltip
												title={`${option?.name || '- -'}`}
												placement='right'
											>
												<Typography
													className={`${style.ellipsis}`}
													style={{ fontSize: '12px' }}
												>
													{`${option?.name || '- -'}`}
												</Typography>
											</Tooltip>
										</>
									)}
									getOptionLabel={(option) => `${option?.name}`}
									getOptionSelected={(option, value) => option.id === value.id}
								/>
							</Box>
							<Box width='200px'>
								<Box className={styles.label}>Status</Box>
								<Autocomplete
									multiple
									limitTags={0}
									value={
										selectedStatusOptionValue &&
										selectedStatusOptionValue?.length > 0
											? selectedStatusOptionValue
											: []
									}
									selectOnFocus={true}
									disablePortal
									fullWidth={false}
									onChange={(event, value, reason) => {
										if (!value) {
											return;
										}
										const selectedIds = value
											?.filter((status: any) => status.id !== 'All')
											.map((status: any) => status?.id);
										if (
											value.find((option) => option.id === 'All') &&
											reason === 'select-option'
										) {
											setSelectedStatusOptionValues([
												{ id: 'All', name: 'All' },
												...overtimeStatusOptions,
											]);
											const allStatusIds = overtimeStatusOptions?.map(
												(status: any) => status?.id
											);
											setSelectedStatusIdList(allStatusIds);
											return;
										}
										if (
											value.find((option) => option.id === 'All') &&
											reason === 'remove-option' &&
											overtimeStatusOptions?.length !== selectedIds?.length
										) {
											const currentOptions = value?.filter(
												(status: any) => status?.id !== 'All'
											);
											setSelectedStatusOptionValues(currentOptions);
											const currentIds = currentOptions?.map(
												(status: any) => status?.id
											);
											setSelectedStatusIdList(currentIds);
											return;
										}
										if (
											selectedIds?.length === overtimeStatusOptions?.length &&
											reason === 'select-option'
										) {
											setSelectedStatusOptionValues([
												{ id: 'All', name: 'All' },
												...overtimeStatusOptions,
											]);
											const statusIds = overtimeStatusOptions?.map(
												(status: any) => status?.id
											);
											setSelectedStatusIdList(statusIds);
											return;
										}
										if (
											selectedStatusOptionValue.find(
												(option: any) => option.id === 'All'
											) &&
											reason === 'remove-option'
										) {
											setSelectedStatusIdList([]);
											setSelectedStatusOptionValues([]);
											return;
										}
										const selectedId = value?.map((status) => status.id);
										setSelectedStatusIdList(selectedId);
										setSelectedStatusOptionValues(value);
									}}
									options={
										overtimeStatusOptions && overtimeStatusOptions?.length > 0
											? [{ id: 'All', name: 'All' }, ...overtimeStatusOptions]
											: []
									}
									disableCloseOnSelect={true}
									getLimitTagsText={getProjectStatusFilterLimits}
									ChipProps={{ style: { display: 'none' } }}
									renderInput={(params) => (
										<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
											<TextComponent {...params} label='' />
										</ThemeProvider>
									)}
									renderOption={(option, { selected }) => (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected}
												color='primary'
											/>
											<Box
												style={{ fontSize: '12px' }}
											>{`${option?.name}`}</Box>
										</>
									)}
									getOptionLabel={(option) => `${option?.name}`}
									getOptionSelected={(option, value) => option.id === value.id}
								/>
							</Box>
						</Box>
					</ThemeProvider>
					<Box display='flex' marginTop='3px'>
						<Box marginRight='5px'>
							<Button
								disabled={submissionIds.length === 0}
								className={`${overtimePendingApprovalStyles.button}  
								${
									submissionIds.length === 0
										? modalFormStyles.disabledButton
										: `${modalFormStyles.rejectButton}`
								} 
								`}
								onClick={() => {
									setIsRejectOvertime(true);
									setIsApproveOvertime(false);
									setIsConfirmationModalShown(true);
								}}
							>
								Reject
							</Button>
						</Box>
						<Box>
							<Button
								disabled={submissionIds.length === 0}
								className={`${overtimePendingApprovalStyles.button}
								${
									submissionIds.length === 0
										? modalFormStyles.disabledButton
										: modalFormStyles.approveButton
								}
								`}
								onClick={() => {
									setIsRejectOvertime(false);
									setIsApproveOvertime(true);
									setIsConfirmationModalShown(true);
								}}
							>
								Approve
							</Button>
						</Box>
					</Box>
				</div>
			</div>
			{submittedTimesheetsLoading ? (
				<Box>
					<Loading />
				</Box>
			) : (
				<Box className={overtimePendingApprovalStyles.scrollableContainer}>
					{submittedOvertime && submittedOvertime.user.length > 0 ? (
						<div className={overtimePendingApprovalStyles.container}>
							<div>
								{submittedOvertime.user.map(
									(user, index) =>
										user.timesheetOvertimeSubmissions.length > 0 && (
											<div
												className={
													overtimePendingApprovalStyles.timesheetsContainer
												}
												key={index}
											>
												<div
													className={
														overtimePendingApprovalStyles.avatarContainer
													}
												>
													<Avatar
														className={overtimePendingApprovalStyles.avatar}
														src={`${user.profile_pic || ''}`}
													>
														{user?.first_name?.charAt(0).toUpperCase() || ''}
														{user?.last_name?.charAt(0).toUpperCase() || ''}
													</Avatar>
													<Typography
														className={
															overtimePendingApprovalStyles.employeeName
														}
													>
														{`${user.full_name || ''} `}
													</Typography>
												</div>
												<Table>
													<TableHead>
														<TableRow>
															<CustomTableCell>
																<Checkbox
																	name='checkedB'
																	color='primary'
																	checked={onHandleSelectAll(
																		user.timesheetOvertimeSubmissions
																	)}
																	onChange={(event: any) => {
																		if (event.currentTarget.checked === true) {
																			onHandleSelectAll(
																				user.timesheetOvertimeSubmissions
																			);
																			const record =
																				user.timesheetOvertimeSubmissions
																					?.filter(
																						(timesheetSubmission: any) =>
																							timesheetSubmission
																								.timesheet_status.value !==
																								'approved' &&
																							timesheetSubmission
																								.timesheet_status.value !==
																								'rejected'
																					)
																					.map((timesheet) => timesheet?.id);
																			setSubmissionIds(
																				uniq([...submissionIds, ...record])
																			);
																		} else {
																			const record =
																				user.timesheetOvertimeSubmissions
																					?.filter(
																						(timesheetSubmission: any) =>
																							timesheetSubmission
																								.timesheet_status.value !==
																								'approved' &&
																							timesheetSubmission
																								.timesheet_status.value !==
																								'rejected'
																					)
																					.map((timesheet) => timesheet?.id);
																			const ids = [...submissionIds].filter(
																				(e) => record?.includes(e) === false
																			);
																			setSubmissionIds(uniq(ids));
																		}
																	}}
																/>
															</CustomTableCell>
															<CustomTableCell>DATE</CustomTableCell>
															<CustomTableCell>STATUS</CustomTableCell>
															<CustomTableCell>TOTAL HOURS</CustomTableCell>
															<CustomTableCell>BILLABLE HOURS</CustomTableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{user.timesheetOvertimeSubmissions.map(
															(overtimeSubmission, index) =>
																overtimeSubmission.timesheet_overtimes.length >
																	0 && (
																	<TableRow
																		hover={true}
																		key={overtimeSubmission.id}
																		onClick={() =>
																			handleRowClick(overtimeSubmission, {
																				id: user.id,
																				name: `${user?.full_name} `,
																				profilePic: user.profile_pic,
																			})
																		}
																	>
																		<CustomTableCell
																			className={
																				overtimePendingApprovalStyles.dataActionContainer
																			}
																		>
																			{overtimeSubmission.timesheet_status
																				.value === 'approved' ? (
																				<CheckCircleIcon
																					className={
																						overtimePendingApprovalStyles.approvedIcon
																					}
																				/>
																			) : (
																				<Checkbox
																					id={`${overtimeSubmission.timesheet_status?.value}${index}`}
																					className={
																						overtimePendingApprovalStyles.checkBox
																					}
																					checked={
																						overtimeSubmission.timesheet_status
																							?.value !== 'approved' &&
																						overtimeSubmission.timesheet_status
																							?.value !== 'rejected' &&
																						submissionIds?.includes(
																							overtimeSubmission.id
																						)
																					}
																					disabled={
																						overtimeSubmission.timesheet_status
																							.value === 'rejected'
																							? true
																							: false
																					}
																					onChange={(e) => {
																						if (e.currentTarget.checked) {
																							setSubmissionIds(
																								uniq([
																									...submissionIds,
																									overtimeSubmission.id,
																								])
																							);
																						} else {
																							setSubmissionIds(
																								uniq(
																									submissionIds.filter(
																										(id: any) =>
																											id !==
																											overtimeSubmission.id
																									)
																								)
																							);
																						}
																					}}
																					name='checkedB'
																					color='primary'
																				/>
																			)}
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(overtimeSubmission, {
																					id: user.id,
																					name: `${user?.full_name} `,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			<div
																				className={
																					overtimePendingApprovalStyles.dateContainer
																				}
																			>
																				<DateRangeIcon
																					className={
																						overtimePendingApprovalStyles.calendarIcon
																					}
																				/>
																				{head(
																					overtimeSubmission?.timesheet_overtimes
																				)?.date
																					? dayjs(
																							head(
																								overtimeSubmission?.timesheet_overtimes
																							)?.date
																					  ).format('DD MMM YYYY')
																					: '--'}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(overtimeSubmission, {
																					id: user.id,
																					name: `${user?.full_name}`,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			<div
																				className={`${
																					OvertimeStyles.statusContainer
																				}
                                ${
																	overtimeSubmission.timesheet_status.value ===
																	'submitted'
																		? OvertimeStyles.submittedStatus
																		: overtimeSubmission.timesheet_status
																				.value === 'approved'
																		? OvertimeStyles.approvedStatus
																		: OvertimeStyles.rejectedStatus
																}
                              `}
																			>
																				{overtimeSubmission.timesheet_status
																					.value === 'submitted'
																					? 'Pending'
																					: overtimeSubmission.timesheet_status
																							.label}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(overtimeSubmission, {
																					id: user.id,
																					name: `${user?.full_name}`,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			<div
																				className={
																					overtimePendingApprovalStyles.totalHoursContainer
																				}
																			>
																				<AccessTimeIcon
																					className={
																						overtimePendingApprovalStyles.durationIcon
																					}
																				/>
																				{`${getTotalHours(
																					overtimeSubmission.timesheet_overtimes
																				)} hours`}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell
																			onClick={() => {
																				setIsDetailsModalShown(true);
																				handleRowClick(overtimeSubmission, {
																					id: user.id,
																					name: `${user?.full_name} `,
																					profilePic: user.profile_pic,
																				});
																			}}
																		>
																			{`${getTotalBillableHours(
																				overtimeSubmission.timesheet_overtimes
																			)} hours`}
																		</CustomTableCell>
																	</TableRow>
																)
														)}
													</TableBody>
												</Table>
											</div>
										)
								)}
							</div>
						</div>
					) : (
						<Box textAlign='center'>
							<img src={no_data_found} alt='no_data_found' />
						</Box>
					)}
				</Box>
			)}
			<ConfirmationModal
				isWarningConfirmation={isRejectOvertime}
				open={isConfirmationModalShown}
				onClose={() => {
					setIsConfirmationModalShown(false);
				}}
				confirmationMessage={
					isRejectOvertime
						? `Do you really want to reject ${submissionIds?.length} record(s)?`
						: `Do you really want to approve ${submissionIds?.length} record(s)?`
				}
				onSubmit={() => {
					if (isRejectOvertime) {
						handleRejectOvertime();
						return;
					}
					if (isApproveOvertime) {
						handleApproveOvertime();
						return;
					}
				}}
				submitButtonLabel={
					isRejectOvertime
						? OVERTIME_REJECT_MODAL_BUTTON_LABEL
						: OVERTIME_APPROVAL_MODAL_BUTTON_LABEL
				}
				loading={isRejectOvertimeLoading || isApproveOvertimeLoading}
			/>
			<TimesheetOvertimePendingApprovalDetailsModal
				open={isDetailsModalShown}
				onClose={() => {
					setIsDetailsModalShown(false);
					setSelectedOvertime({});
				}}
				overtime={selectedOvertime}
				onApprove={() => {
					refetchSubmittedTimesheets();
					refetch();
				}}
			/>
		</>
	);
};

export default TimesheetOvertimePendingApproval;
