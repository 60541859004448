import React, { useContext } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { UtilizationByMonth } from './UtilizationByMonth.component';
import TimesheetDefaultersListContainer from './TimesheetDefaultersListContainer.component';
import { DefaultersList } from './DefaultersList.component';
import { UserProfileContext } from '../../../App';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		marginTop: '16px',
		display: 'grid',
		gap: '20px',
	},
	chartContainer: {
		'&:nth-child(odd)': {
			width: '79vw',
		},
		'&:nth-child(even)': {
			width: '79vw',
		},
	},
}));
export const TimesheetInsights = () => {
	const styles = useStyles();
	const { permissions } = useContext<any>(UserProfileContext);
	return (
		<>
			<Box className={styles.container}>
				{!permissions?.appPermissions?.ui?.viewSubAccountDetails && (
					<Box>
						<UtilizationByMonth />
					</Box>
				)}
				<Box>
					<TimesheetDefaultersListContainer />
				</Box>
				<Box>
					<DefaultersList />
				</Box>
			</Box>
		</>
	);
};

export default TimesheetInsights;
