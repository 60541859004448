import React, { useContext, useEffect, useState } from 'react';
import {
	editableColumnsStyle,
	editableColumnsTheme,
	ellipsisStyle,
} from '../../../Layout/styles';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { Loading } from 'ra-ui-materialui';

import {
	Box,
	ThemeProvider,
	Tooltip,
	Typography,
	TextField as TextComponent,
} from '@material-ui/core';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { head, isEmpty } from 'lodash';
import { UserProfileContext } from '../../../App';
import { useNotify, useRefresh } from 'ra-core';
import {
	useGetEmailNotificationListQuery,
	useUpdateOrganisationEmailEnabledMutation,
	useUpdateEmailNotiificationMutation,
} from '../../../generated/graphql';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import EmailNotificationWarningModal from './EmailNotificationWarningModal.component';
import { tableListStyle } from '../settingsStyle';
import RecipientListModal from './RecieversListModal.component';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';

export interface RecipientRole {
	id?: string | undefined;
	name?: string[] | undefined;
}

export interface RecipientData {
	role: RecipientRole[];
}

const EmailNotification = () => {
	const { orgId } = useContext<any>(UserProfileContext);
	const [isOrgEnable, setIsOrgEnable] = useState(false);
	const [isEmailEnable, setIsEmailEnable] = useState(false);
	const [isAddNewRoleModalShown, setIsAddNewRoleModalShown] = useState(false);
	const [isOrganizationEnable, setIsOrganizationEnable] = useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [searchEmail, setSearchEmail] = useState('');
	const [emailNotification, setEmailNotiification] = useState({
		id: null,
		is_enabled: false,
	});

	const [currentNotificationId, setCurrentNotificationId] = useState<
		string | null | undefined
	>(null);
	const [currentNotificationReceivers, setCurrentNotificationReceivers] =
		useState<string[]>();
	const editableColumnStyles = editableColumnsStyle();
	const classes = tableListStyle();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const refresh = useRefresh();

	const {
		data: emailList,
		refetch,
		loading: isEmailListLoading,
	} = useGetEmailNotificationListQuery({
		variables: {
			orgId: orgId,
			name: `%${searchEmail || ''}%`,
		},
	});

	const [updateOrganizationEmailEnabled] =
		useUpdateOrganisationEmailEnabledMutation();

	const [updateEmailNotiification] = useUpdateEmailNotiificationMutation();

	useEffect(() => {
		if (isEmpty(emailList?.organization)) {
			setIsOrgEnable(false);
			return;
		}
		const isOrgEnable: boolean =
			head(emailList?.organization)?.is_email_enabled || false;
		setIsOrgEnable(isOrgEnable);
	}, [emailList]);

	const handleEmailEnabled = async () => {
		try {
			const response = await updateOrganizationEmailEnabled({
				variables: {
					organisationId: orgId,
					data: {
						is_email_enabled: isOrganizationEnable,
					},
				},
			});
			if (response?.data?.update_organization_by_pk?.id) {
				refresh();
				notify(
					`Organization Email Notification ${
						response?.data?.update_organization_by_pk?.is_email_enabled
							? 'enabled'
							: 'disabled'
					} successfully`
				);
				setIsWarningModalShown(false);
				setIsEmailEnable(false);
				refetch();
				return;
			}
		} catch (error: any) {
			console.log(JSON.stringify(error));
			return;
		}
	};

	const handleEmailNotiification = async () => {
		try {
			const response = await updateEmailNotiification({
				variables: {
					id: emailNotification?.id,
					data: {
						is_enabled: emailNotification?.is_enabled,
					},
				},
			});
			if (response?.data?.update_email_notification_by_pk?.id) {
				refresh();
				notify(
					`${response?.data?.update_email_notification_by_pk?.name} 
					Email Notification ${
						response?.data?.update_email_notification_by_pk?.is_enabled
							? 'Enabled'
							: 'Disabled'
					} Successfully`
				);
				setIsWarningModalShown(false);
				setIsEmailEnable(false);
				refetch();
				return;
			}
		} catch (error: any) {
			console.log(JSON.stringify(error));
			return;
		}
	};

	return (
		<Box className={editableColumnStyles.contentContainer}>
			<ThemeProvider theme={editableColumnsTheme}>
				<div>
					<Box
						className={editableColumnStyles.inputContainer}
						display='flex'
						flexDirection='column'
					>
						<>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row-reverse',
									justifyContent: 'space-between',
								}}
							>
								<ThemeProvider theme={searchBarTheme}>
									<form onSubmit={(event) => event.preventDefault()}>
										<Box width='250px' marginTop={'5px'}>
											<TextComponent
												placeholder='Search Email'
												label={false}
												fullWidth
												InputLabelProps={{ style: { fontSize: 0 } }}
												InputProps={{
													startAdornment: <SearchIcon />,
												}}
												onChange={(e) => {
													setSearchEmail(e?.target?.value);
												}}
												value={searchEmail}
											/>
										</Box>
									</form>
								</ThemeProvider>
								{!isEmailListLoading && (
									<Box>
										<Typography className={editableColumnStyles.description}>
											Turn off to deactivate email for entire organization
										</Typography>
										<FormGroup>
											<FormControlLabel
												control={
													<Switch
														name='is_email_enabled'
														color='primary'
														checked={isOrgEnable}
														onChange={(event: any) => {
															setIsWarningModalShown(true);
															setIsOrganizationEnable(event?.target.checked);
														}}
													/>
												}
												label='Enable Organisation Email Notification'
											/>
										</FormGroup>
									</Box>
								)}
							</div>
							<Box
								marginTop={'35px'}
								className={
									!head(emailList?.organization)?.is_email_enabled
										? editableColumnStyles.disableDiv
										: ''
								}
							>
								{emailList && emailList?.email_notification?.length > 0 ? (
									emailList.email_notification.map((value) => (
										<Box
											className={editableColumnStyles.emailPolicyContainer}
											key={value.id}
										>
											<Box display='flex' alignItems='center'>
												<Box display='flex' mr={1}>
													<FormGroup>
														<FormControlLabel
															control={
																<Switch
																	name='is_email_enabled'
																	color='primary'
																	disabled={
																		!head(emailList?.organization)
																			?.is_email_enabled
																	}
																	checked={
																		head(emailList?.organization)
																			?.is_email_enabled && value?.is_enabled
																			? true
																			: false
																	}
																	onChange={(event: any) => {
																		setIsWarningModalShown(true);
																		setIsEmailEnable(true);
																		setEmailNotiification({
																			id: value?.id,
																			is_enabled: event.target.checked,
																		});
																	}}
																/>
															}
															label={''}
														/>
													</FormGroup>
												</Box>
												<Box>
													<Typography className={classes.label}>
														Name
													</Typography>
													<Tooltip title={`${value.name}`} placement='right'>
														<Typography
															className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
														>
															{value?.name}
														</Typography>
													</Tooltip>
												</Box>
											</Box>
											<Box>
												<Typography className={classes.label}>
													Description
												</Typography>
												<Tooltip
													title={`${value.description || '--'}`}
													placement='right'
												>
													<Typography
														className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
													>
														{value?.description || '--'}
													</Typography>
												</Tooltip>
											</Box>
											<Box>
												<div
													className={classes.employeesContainer}
													onClick={() => {
														if (!value?.is_recipient_editable) {
															notify(
																'Email recipients cannot be edited !',
																'warning'
															);
															return;
														}
														setIsAddNewRoleModalShown(true);
														setCurrentNotificationId(value?.id);
														setCurrentNotificationReceivers(value?.receivers);
													}}
												>
													<PeopleOutlineIcon className={classes.peoplesIcon} />
													<Typography
														className={`${classes.rowContent} ${classes.employeesCount}`}
													>
														{value?.receivers?.length}
													</Typography>
												</div>
											</Box>
											<Box>
												<Typography className={classes.label}></Typography>
												<Tooltip
													title={`${value.email_type?.label}`}
													placement='left-start'
												>
													<Typography
														className={`${editableColumnStyles.emailName} ${ellipsisStyles.ellipsis}`}
													>
														{value?.email_type?.value === 'scheduledTrigger' ? (
															<AccessAlarmIcon
																fontSize='small'
																className={editableColumnStyles.emailType}
															/>
														) : (
															<OfflineBoltOutlinedIcon
																fontSize='small'
																className={editableColumnStyles.emailType}
															/>
														)}
													</Typography>
												</Tooltip>
											</Box>
										</Box>
									))
								) : isEmailListLoading ? (
									<Loading />
								) : (
									'No Email Notification'
								)}
							</Box>
						</>
					</Box>
				</div>
			</ThemeProvider>

			<RecipientListModal
				open={isAddNewRoleModalShown}
				onClose={() => {
					setIsAddNewRoleModalShown(false);
				}}
				onSuccess={refetch}
				emailNotificationId={currentNotificationId}
				emailNotificationReceiver={currentNotificationReceivers}
				name={'Recipient List'}
			/>
			<EmailNotificationWarningModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
					setIsEmailEnable(false);
				}}
				confirmationMessage={'Are you sure you want to proceed?'}
				onSave={isEmailEnable ? handleEmailNotiification : handleEmailEnabled}
			/>
		</Box>
	);
};

export default EmailNotification;
