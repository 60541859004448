import React, { useContext, useState } from 'react';

import {
	Button,
	Dialog,
	DialogContent,
	makeStyles,
	ThemeProvider,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import {
	AutocompleteInput,
	BooleanInput,
	NumberInput,
	ReferenceInput,
	SelectInput,
	TextInput,
	required,
	useNotify,
} from 'react-admin';

import { formStyle } from '../pms.styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import {
	END_DATE_ERROR,
	GOAL_MAPPING_SUCCESS_TEXT,
	GOAL_TARGET_PREFIX,
	GOAL_UNIT_OPTIONS,
	REQUIRED_ERROR_TEXT,
} from '../constant';
import Loader from './Loader.component';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';
import { useInsertGoalMappingMutation } from '../../../generated/graphql';

interface GoalMappingFormProps {
	onClose: () => void;
	open: boolean;
	onSuccess: () => void;
	appraisalCycleId: string;
	selectedUserIds: string[];
	assignedGoalIds: string[];
	allocatedGoalWeights: number[];
	initialGoalStatus: string;
	appraisalCycleStartDate?: string;
	appraisalCycleEndDate?: string;
}

interface GoalMappingFormValues {
	goal_id: string;
	unit: string | null;
	weightage: number;
	start_date: Date;
	end_date: Date;
	target: number;
	target_prefix: string;
}

const GOAL_WEIGHT_EXCEEDED_ERROR_TEXT =
	'Some User(s) goal weight is exceed more than 100%';

const GOAL_DUPLICATION_ERROR_TEXT = 'Some User(s) already have this goal';

const ERROR_INITIAL_VALUE = {
	isError: false,
	errorText: '',
};
const useStyles = makeStyles({
	box: {
		width: '100%',
		padding: '20px 20px',
		boxSizing: 'border-box',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '20px',
	},
	title: {
		margin: 0,
		font: 'normal normal 800 16px/25px Manrope',
	},
	closeIcon: {
		cursor: 'pointer',
	},
	errorText: {
		color: 'red',
		marginBottom: '12px',
	},
	row: {
		display: 'flex',
		gap: '16px',
	},
	fullWidth: {
		flex: 1,
	},
	fieldLabel: {
		marginBottom: '8px',
		font: 'normal normal bold 11px/24px Manrope',
	},
	displayFlex: {
		display: 'flex',
		gap: '16px',
		marginTop: '16px',
	},
	marginTop: {
		marginTop: '8px',
	},
});

function GoalMappingForm(props: GoalMappingFormProps) {
	const { dateFormat, id: userId } = useContext<any>(UserProfileContext);
	const {
		open,
		onClose,
		onSuccess,
		appraisalCycleId,
		assignedGoalIds,
		selectedUserIds,
		allocatedGoalWeights,
		initialGoalStatus,
		appraisalCycleStartDate,
		appraisalCycleEndDate,
	} = props;
	const formStyles = formStyle();
	const classes = useStyles();
	const notify = useNotify();

	// STATE
	const [endDateError, setEndDateError] = useState(false);
	const [error, setError] = useState<{ isError: boolean; errorText: string }>(
		ERROR_INITIAL_VALUE
	);

	// API
	const [insertGoalMapping, { loading: isGoalMappingLoading }] =
		useInsertGoalMappingMutation();

	const handleClose = () => {
		setError(ERROR_INITIAL_VALUE);
		setEndDateError(false);
		onClose();
	};

	const handleSubmit = (formValue: GoalMappingFormValues) => {
		if (!formValue) return;

		const allocatedWeightsMoreThanHundred = allocatedGoalWeights.filter(
			(goalWeight) => goalWeight + formValue.weightage > 100
		);

		if (dayjs(formValue.start_date).isAfter(dayjs(formValue.end_date))) {
			setEndDateError(true);
			return;
		}

		if (assignedGoalIds.includes(formValue.goal_id)) {
			setError({
				isError: true,
				errorText: GOAL_DUPLICATION_ERROR_TEXT,
			});
			return;
		}

		if (allocatedWeightsMoreThanHundred.length > 0) {
			setError({
				isError: true,
				errorText: GOAL_WEIGHT_EXCEEDED_ERROR_TEXT,
			});
			return;
		}

		insertGoalMapping({
			variables: {
				data: selectedUserIds.map((usrId) => ({
					goal_id: formValue.goal_id,
					appraisal_cycle_id: appraisalCycleId,
					user_id: usrId,
					assigned_by: userId,
					unit: formValue.unit || null,
					weightage: formValue.weightage,
					start_date: formValue.start_date || appraisalCycleStartDate || null,
					end_date: formValue.end_date || appraisalCycleEndDate || null,
					progress: 0,
					manager_review: 0,
					target: formValue.target,
					target_prefix: formValue.target_prefix,
					goal_status_id: initialGoalStatus,
					target_achieved: 0,
				})),
			},
		}).then((res) => {
			if (!res.errors) {
				setError(ERROR_INITIAL_VALUE);
				notify(GOAL_MAPPING_SUCCESS_TEXT);
				onSuccess();
			}
		});
	};

	const validateFormValues = (formValue: GoalMappingFormValues) => {
		const goalMappingFormErrors = {} as {
			goal_id: string;
			unit: string | null;
			weightage: string;
			start_date: string;
			end_date: string;
		};

		if (!formValue?.start_date) {
			goalMappingFormErrors.start_date = REQUIRED_ERROR_TEXT;
		}

		if (!formValue?.end_date) {
			goalMappingFormErrors.end_date = REQUIRED_ERROR_TEXT;
		}

		return goalMappingFormErrors;
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth='sm'
			>
				<div className={classes.box}>
					<div className={classes.header}>
						<p className={classes.title}>Add Goal</p>
						<CloseIcon className={classes.closeIcon} onClick={handleClose} />
					</div>

					<Form
						validate={validateFormValues}
						onSubmit={handleSubmit}
						initialValues={{
							start_date: appraisalCycleStartDate || null,
							end_date: appraisalCycleEndDate || null,
						}}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<DialogContent>
									{error.isError && (
										<p className={classes.errorText}>{error.errorText}</p>
									)}
									<div className={classes.row}>
										<div className={classes.fullWidth}>
											<p className={classes.fieldLabel}>Goal*</p>
											<ReferenceInput
												label=''
												reference='pms_pms_master_goal'
												source='goal_id'
												sort={{ field: 'name', order: 'ASC' }}
												filterToQuery={(searchText: string) => ({
													name: searchText,
												})}
												validate={required()}
												onChange={() => setError(ERROR_INITIAL_VALUE)}
											>
												<AutocompleteInput optionText='name' />
											</ReferenceInput>
										</div>
										<div style={{ width: '100px' }}>
											<p className={classes.fieldLabel}>Weight*</p>
											<NumberInput
												source='weightage'
												label=''
												max={100}
												min={0}
												onChange={() => setError(ERROR_INITIAL_VALUE)}
												validate={required()}
											/>
										</div>
									</div>
									<div className={classes.displayFlex}>
										<div className={classes.fullWidth}>
											<p className={classes.fieldLabel}>Unit</p>
											<SelectInput
												source='unit'
												label=''
												choices={GOAL_UNIT_OPTIONS}
											/>
										</div>
										<div className={classes.fullWidth}>
											<p className={classes.fieldLabel}>Target Type</p>
											<SelectInput
												source='target_prefix'
												label=''
												choices={GOAL_TARGET_PREFIX}
											/>
										</div>
										<div className={classes.fullWidth}>
											<p className={classes.fieldLabel}>Target</p>
											<NumberInput source='target' label='' />
										</div>
									</div>
									<div className={classes.displayFlex}>
										<div className={classes.fullWidth}>
											<p className={classes.fieldLabel}>Start Date*</p>
											<Field
												name='start_date'
												validate={(value) =>
													dayjs(value).isValid() ? undefined : 'Invalid Date'
												}
											>
												{(props) => (
													<CustomDateInput
														initialValue={
															props.input.value ||
															appraisalCycleStartDate ||
															null
														}
														name={props.input.name}
														onChange={(value) => {
															props.input.onChange(value);
															setEndDateError(false);
														}}
														dateFormat={dateFormat}
													/>
												)}
											</Field>
										</div>
										<div className={classes.fullWidth}>
											<p className={classes.fieldLabel}>End Date*</p>
											<Field
												name='end_date'
												validate={(value) =>
													dayjs(value).isValid() ? undefined : 'Invalid Date'
												}
											>
												{(props) => (
													<CustomDateInput
														initialValue={
															props.input.value || appraisalCycleEndDate || null
														}
														name={props.input.name}
														onChange={(value) => {
															props.input.onChange(value);
															setEndDateError(false);
														}}
														dateFormat={dateFormat}
													/>
												)}
											</Field>
											{endDateError && (
												<p style={{ color: 'red', marginTop: '4px' }}>
													{END_DATE_ERROR}
												</p>
											)}
										</div>
										<div className={classes.fullWidth}>
											<p className={classes.fieldLabel}>Is Locked</p>
											<BooleanInput label='' source='is_locked' />
										</div>
									</div>
									<div className={classes.marginTop}>
										<p className={classes.fieldLabel}>Description</p>
										<TextInput
											source='description'
											fullWidth={true}
											label=''
											multiline
										/>
									</div>
									<div className={formStyles.buttonContainer}>
										<Button
											onClick={handleClose}
											className={formStyles.cancelButton}
										>
											Cancel
										</Button>
										<Button
											className={
												invalid ||
												pristine ||
												isGoalMappingLoading ||
												error.isError
													? formStyles.disabledButton
													: formStyles.saveButton
											}
											disabled={
												invalid ||
												pristine ||
												isGoalMappingLoading ||
												error.isError
											}
											type='submit'
											startIcon={isGoalMappingLoading && <Loader />}
										>
											Add
										</Button>
									</div>
								</DialogContent>
							</form>
						)}
					</Form>
				</div>
			</Dialog>
		</ThemeProvider>
	);
}

export default GoalMappingForm;
