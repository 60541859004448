import React, { useState } from 'react';
import {
	List,
	Datagrid,
	FunctionField,
	useRefresh,
	useMutation,
	useNotify,
} from 'react-admin';
import { ellipsisStyle, modalFormStyle } from '../../../Layout/styles';
import {
	Box,
	Button,
	Typography,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
	makeStyles,
} from '@material-ui/core';
import { SearchIcon } from '../../../assets/SvgIcons';
import { PopOver } from '../../../SharedComponents/PopOver.component';
import { settingsStyle } from '../../Settings/settingsStyle';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import { searchBarTheme } from '../../../Layout/Theme.component';
import SettingsDetailContainer from '../../Settings/SettingsDetailContainer.component';
import GoalCategoryForm from './GoalCategoryForm.component';
import {
	GOAL_CATEGORY_DELETE_MESSAGE,
	GOAL_CATEGORY_DELETE_FAILED,
} from '../constant';

interface GoalCategory {
	id?: string;
	name?: string;
	org_id?: string;
}
const boxStyle = makeStyles({
	btnContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginRight: '30px',
	},
	name:{
		maxWidth: '1100px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
});
const GoalCategoryList = () => {
	const [isGoalCategoryFormSHown, setIsGoalCategoryFormSHown] = useState(false);
	const [editGoalCategoryDetails, setEditGoalCategoryDetails] =
		useState<GoalCategory>({});
	const [isGoalCategoryEdit, setIsGoalCategoryEdit] = useState(false);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [choosedGoalCategory, setChoosedGoalCategory] = useState(null);
	const [searchGoalCategory, setSearchGoalCategory] = useState('');
	const modalFormStyles = modalFormStyle();
	const settingsStyles = settingsStyle();
	const tooltipStyle = ellipsisStyle();
	const buttonContainerStyle = boxStyle();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const onEditGoalCategory = (goalCategory: GoalCategory) => {
		if (!goalCategory) {
			return;
		}
		const { org_id, ...restGoalCategory } = goalCategory;
		setEditGoalCategoryDetails({ ...restGoalCategory });
		setIsGoalCategoryEdit(true);
		setIsGoalCategoryFormSHown(true);
	};
	const deleteGoalCategory = () => {
		mutate(
			{
				type: 'delete',
				resource: 'pms_pms_goal_category',
				payload: {
					id: choosedGoalCategory,
				},
			},
			{
				onSuccess: () => {
					notify(GOAL_CATEGORY_DELETE_MESSAGE);
					refresh();
					setIsDeleteModalShown(false);
					setChoosedGoalCategory(null);
				},
				onFailure: (error: any) => {
					if (error?.message?.includes('Foreign key violation')) {
						notify(GOAL_CATEGORY_DELETE_FAILED, 'warning');
						setIsDeleteModalShown(false);
					}
				},
			}
		);
	};
	return (
		<>
			<SettingsDetailContainer heading={'Goal Category'}>
				<Box display={'flex'} justifyContent='space-between'>
					<ThemeProvider theme={searchBarTheme}>
						<form onSubmit={(event) => event.preventDefault()}>
							<Box width='250px' marginTop={'-1px'}>
								<TextComponent
									placeholder='Search Goal Category'
									label={false}
									fullWidth
									InputLabelProps={{ style: { fontSize: 0 } }}
									InputProps={{
										startAdornment: <SearchIcon />,
									}}
									onChange={(e) => {
										setSearchGoalCategory(e?.target?.value);
									}}
									value={searchGoalCategory}
								/>
							</Box>
						</form>
					</ThemeProvider>

					<Button
						style={{ width: '150px' }}
						variant='contained'
						className={modalFormStyles.saveButton}
						onClick={() => {
							setIsGoalCategoryFormSHown(true);
							setIsGoalCategoryEdit(false);
						}}
					>
						Add Goal Category
					</Button>
				</Box>

				<Box className={settingsStyles.content}>
					<List
						empty={false}
						resource='pms_pms_goal_category'
						basePath='/pms_pms_goal_category'
						bulkActionButtons={false}
						actions={false}
						title={' '}
						perPage={5}
						sort={{ field: 'name', order: 'ASC' }}
						filter={{ name: searchGoalCategory }}
					>
						<Datagrid rowClick={''}>
							<FunctionField
								label='NAME'
								render={(shift: any) => {
									return (
										<Tooltip title={`${shift.name}`} placement='right'>
											<Typography
												className={`${buttonContainerStyle.name} ${tooltipStyle.ellipsis}`}
											>
												{shift.name}
											</Typography>
										</Tooltip>
									);
								}}
							/>
							<FunctionField
								render={(goalCategory: any) => (
									<Box className={buttonContainerStyle.btnContainer}>
										<PopOver
											edit={() => onEditGoalCategory(goalCategory)}
											remove={() => {
												setIsDeleteModalShown(true);
												setChoosedGoalCategory(goalCategory?.id);
											}}
										/>
									</Box>
								)}
							/>
						</Datagrid>
					</List>
				</Box>
				<GoalCategoryForm
					open={isGoalCategoryFormSHown}
					onClose={() => setIsGoalCategoryFormSHown(false)}
					refetch={() => refresh()}
					initialValues={editGoalCategoryDetails}
					isEditMutation={isGoalCategoryEdit}
				/>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => setIsDeleteModalShown(false)}
					onDelete={deleteGoalCategory}
				/>
			</SettingsDetailContainer>
		</>
	);
};

export default GoalCategoryList;
