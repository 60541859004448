import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import {
	useGetAppraisalInsightsFilterOptionsQuery,
	useGetAppraisalReportForExportQuery,
	useGetAppraisalReportQuery,
	useGetPmsBellCurveChartQuery,
	useGetUsersBasedOnRatingFilterQuery,
} from '../../../generated/graphql';
import {
	appraisalDetailsStyle,
	formStyle,
	pmsDatagridStyle,
	appraisalInsightsStyle,
} from '../pms.styles';

import {
	Box,
	ThemeProvider,
	TextField as TextComponent,
	Button,
	InputAdornment,
	TextField,
	CircularProgress,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { DataGridTheme } from '../pmsTheme';
import {
	autoCompleteTheme,
	searchBarTheme,
} from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';
import Loader from '../SharedComponents/Loader.component';
import { chain, head, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { UserProfileContext } from '../../../App';
import { getExperience } from '../../../Utils/date-time.util';
import { FilterStateInterface, ViewGoalsDetails } from '../pms.module';
import { viewGoalsInitialState } from '../constant';
import ViewGoals from '../SharedComponents/ViewGoals.component';
import { AutocompleteSearchStyles } from '../../../Layout/styles';
import { Autocomplete } from '@material-ui/lab';
import { Form, Field } from 'react-final-form';
import PMSAppraisalBellCurveChart from '../Admin/PMSAppraisalBellCurveChart.component';
import PMSBarChart from '../Admin/PMSBarChart.component';
import {
	exportToCsv,
	formatEmployeeId,
	getPrefix,
	preventSubsequentClick,
} from '../../../Utils/string.util';
import { APPRAISAL_CYCLE_INSIGHT_EXPORT_COLUMNS } from '../Admin/constant';

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Employees'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

function TeamCycleInsights() {
	const {
		dateFormat,
		id: userId,
		accountProjectsOptions,
		accountEmployeeUserIds,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		permissions,
	} = useContext<any>(UserProfileContext);
	const { appraisalCycleId }: { appraisalCycleId: string } = useParams();

	const appraisalDetailsStyles = appraisalDetailsStyle();
	const dataGridStyles = pmsDatagridStyle();
	const autoCompleteSearchStyles = AutocompleteSearchStyles();
	const formStyles = formStyle();
	const appraisalInsightStyles = appraisalInsightsStyle();

	// States
	const [searchedText, setSearchedText] = useState<string>('');
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [isViewGoalsModalShown, setIsViewGoalsModalShown] = useState(false);
	const [selectedViewGoals, setSelectedViewGoals] = useState<ViewGoalsDetails>(
		viewGoalsInitialState
	);
	const [filterState, setFilterState] = useState<FilterStateInterface>({});
	const [chartFilterState, setChartFilterState] =
		useState<FilterStateInterface>({});
	const [ratingFilterObj, setRatingFilterObj] = useState({});
	const [managerRatingFieldFilter, setManagerRatingFieldFilter] = useState({});
	const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);

	const getFilterObject = () => {
		let departmentFilter = {};
		let userAppraisalStatus = {};
		let projectFilter = {};
		let userFilter = {};

		if (filterState?.department_id) {
			departmentFilter = {
				_or: [
					filterState.department_id
						? {
								user: {
									employee: {
										department_id: {
											_eq: filterState.department_id.id,
										},
									},
								},
						  }
						: {},
					filterState.department_id
						? {
								user: {
									contractors: {
										department_id: {
											_eq: filterState.department_id.id,
										},
									},
								},
						  }
						: {},
				],
			};
		}

		if (filterState.status_id) {
			userAppraisalStatus = {
				user_appraisal_status_id: {
					_eq: filterState.status_id.id,
				},
			};
		}

		if (filterState?.project_id) {
			projectFilter = {
				user: {
					project_resource_mappings: {
						project: {
							id: {
								_eq: filterState?.project_id.id,
							},
						},
					},
				},
			};
		}

		return {
			...userFilter,
			...departmentFilter,
			...userAppraisalStatus,
			...projectFilter,
		};
	};

	const getChartFilterObject = () => {
		let departmentFilter = {};
		let userFilter = {};

		if (permissions?.appPermissions?.ui?.viewSubAccountDetails) {
			userFilter = {
				_or: [
					{ reviewer: { _eq: userId } },
					{
						user: {
							project_resource_mappings: {
								employee_id: {
									_in: accountEmployeeUserIds,
								},
							},
						},
					},
				],
			};
		} else {
			userFilter = {
				_or: [{ reviewer: { _eq: userId } }],
			};
		}

		if (chartFilterState?.department_id) {
			departmentFilter = {
				_or: [
					chartFilterState.department_id
						? {
								user: {
									employee: {
										department_id: {
											_eq: chartFilterState.department_id.id,
										},
									},
								},
						  }
						: {},
					chartFilterState.department_id
						? {
								user: {
									contractors: {
										department_id: {
											_eq: chartFilterState.department_id.id,
										},
									},
								},
						  }
						: {},
				],
			};
		}

		return { ...userFilter, ...departmentFilter };
	};

	// API
	const {
		data: appraisalDetail,
		loading: isAppraisalDetailLoading,
		refetch: refetchAppraisalDetails,
	} = useGetAppraisalReportQuery({
		variables: {
			appraisalCycleId: appraisalCycleId,
			where: {
				_and: [
					permissions?.appPermissions?.ui?.viewSubAccountDetails
						? {
								_or: [
									{ reviewer: { _eq: userId } },
									{
										user: {
											project_resource_mappings: {
												employee_id: {
													_in: accountEmployeeUserIds,
												},
											},
										},
									},
								],
						  }
						: {
								_or: [{ reviewer: { _eq: userId } }],
						  },
					{
						user: {
							full_name: {
								_ilike: `%${searchedText}%`,
							},
						},
					},
					{
						appraisal_cycle_id: {
							_eq: appraisalCycleId,
						},
					},
					getFilterObject(),
					ratingFilterObj,
				],
			},
			limit: rowsPerPage,
			offset: page * rowsPerPage,
		},
		fetchPolicy: 'network-only',
	});

	const { data: appraisalCycleForExport } = useGetAppraisalReportForExportQuery(
		{
			variables: {
				appraisalCycleId: appraisalCycleId,
				where: {
					_and: [
						permissions?.appPermissions?.ui?.viewSubAccountDetails
							? {
									_or: [
										{ reviewer: { _eq: userId } },
										{
											user: {
												project_resource_mappings: {
													employee_id: {
														_in: accountEmployeeUserIds,
													},
												},
											},
										},
									],
							  }
							: {
									_or: [{ reviewer: { _eq: userId } }],
							  },

						{
							user: {
								full_name: {
									_ilike: `%${searchedText}%`,
								},
							},
						},
						{
							appraisal_cycle_id: {
								_eq: appraisalCycleId,
							},
						},
						getFilterObject(),
						ratingFilterObj,
					],
				},
			},
			fetchPolicy: 'network-only',
		}
	);
	const { data: filterOptions } = useGetAppraisalInsightsFilterOptionsQuery({
		variables: {
			appraisalCycleId: appraisalCycleId,
		},
	});

	const { data: ratingFilterUserList } = useGetUsersBasedOnRatingFilterQuery({
		variables: {
			where: {
				_and: [
					{
						appraisal_cycle_id: {
							_eq: appraisalCycleId,
						},
					},
					managerRatingFieldFilter,
				],
			},
		},
		fetchPolicy: 'network-only',
	});
	const { data: chartData } = useGetPmsBellCurveChartQuery({
		variables: {
			appraisalCycleId: appraisalCycleId,
			where: {
				appraisal_cycle_id: {
					_eq: appraisalCycleId,
				},
				...getChartFilterObject(),
			},
		},
	});

	useEffect(() => {
		if (!appraisalDetail || !filterState.rating_id) {
			setRatingFilterObj({});
			setManagerRatingFieldFilter({});
			return;
		}
		const minRating = filterState.rating_id.min;
		const maxRating = filterState.rating_id.max;

		setRatingFilterObj({
			appraisalRatings: {
				manager_rating_value: {
					_gte: minRating,
					_lte: maxRating,
				},
			},
		});
		setManagerRatingFieldFilter({
			manager_rating_value: {
				_gte: minRating,
				_lte: maxRating,
			},
		});
	}, [appraisalDetail, filterState]);

	const getChartData = () => {
		if (!chartData || !filterOptions) {
			return [];
		}

		const userData = chartData.pms_pms_appraisal_user_mapping
			.filter(
				(userMapping) =>
					head(userMapping.appraisalRatings)?.manager_rating_value
			)
			.map((pmsUserMapping) => {
				return {
					rating:
						head(pmsUserMapping.appraisalRatings)?.manager_rating_value || 0,
				};
			});

		const finalChartData = chartData.pms_pms_appraisal_rating_scale.map(
			(ratingScale) => ({
				name: ratingScale.label,
				employees: userData.filter(
					(userRating) =>
						ratingScale.min <= userRating.rating &&
						userRating.rating <= ratingScale.max
				).length,
			})
		);

		return finalChartData;
	};

	const getBellCurveChartData = () => {
		if (!chartData || !filterOptions) {
			return [];
		}

		const ratingRanges =
			head(appraisalDetail?.appraisalCycle)
				?.pms_appraisal_rating_scales.map((ratingScale) => [
					ratingScale.min,
					ratingScale.max,
				])
				.flat(Infinity) || [];

		const minRatingRange = ratingRanges.sort(
			(a, b) => Number(a) - Number(b)
		)[0];
		const maxRatingRange = ratingRanges.sort(
			(a, b) => Number(b) - Number(a)
		)[0];

		const userData = chartData.pms_pms_appraisal_user_mapping
			.filter(
				(userMapping) =>
					head(userMapping.appraisalRatings)?.manager_rating_value
			)
			.map((pmsUserMapping) => {
				return {
					rating:
						head(pmsUserMapping.appraisalRatings)?.manager_rating_value || 0,
				};
			});

		const formattedData = chain(userData)
			.groupBy((userRating) => userRating.rating)
			.value();

		const labels = [
			...Object.keys(formattedData),
			minRatingRange,
			maxRatingRange,
		];

		const finalChartData = labels
			.map((rating) => ({
				name: rating,
				employees: formattedData[rating]?.length || 0,
			}))
			.sort((a, b) => Number(a.name) - Number(b.name));

		return finalChartData;
	};

	const columns = [
		{ name: 'name', label: 'NAME' },
		{ name: 'email', label: 'EMAIL' },
		{
			name: 'reviewer',
			label: 'REVIEWER',
			options: {
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'review_status',
			label: 'REVIEW STATUS',
		},
		{
			name: 'reporting_manager',
			label: 'REPORTING MANAGER',
			options: {
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'goals',
			label: 'GOALS',
			options: {
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const joinDate =
						value.user?.user.user_type === 'employee'
							? value.user?.user?.employee?.join_date
							: value.user?.user.contractors[0].join_date;

					const priorExperience =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.prior_experience
							: '00:00:00';

					const jobLevel =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.job_level?.level || '- -'
							: value.user?.user.contractors[0]?.job_level?.level || '- -';

					const department =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.department?.name || '- -'
							: value.user?.user.contractors[0]?.department?.name || '- -';

					const status =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.employee_status?.label || '- -'
							: value.user?.user.contractors[0]?.contractorStatus?.label ||
							  '- -';

					const userDetails = {
						userName: value.user?.user.full_name || '- -',
						experience: joinDate
							? getExperience({
									join_date: joinDate,
									prior_experience: priorExperience,
							  }).totalExp
							: '0',
						reviewer: value.user.reviewer?.full_name || '- -',
						jobLevel: jobLevel || '- -',
						department: department || '- -',
						status: status || '- -',
						profilePic: value.user.user?.profile_pic,
						userAppraisalStatus: value.user.userAppraisalStatus || '',
					};

					const goalsDetails = value.goals.map((goal: any) => {
						return {
							id: goal.id,
							name: goal.pms_master_goal.name || '- -',
							description: goal.pms_master_goal.description || '- -',
							weightage: goal.weightage,
							startDate: dayjs(goal.start_date).format(dateFormat),
							endDate: dayjs(goal.end_date).format(dateFormat),
						};
					});

					return (
						<p
							className={dataGridStyles.link}
							onClick={() => {
								setSelectedViewGoals({
									userDetails: userDetails,
									goals: goalsDetails,
								});

								setIsViewGoalsModalShown(true);
							}}
						>{`View Goals(${value.goals.length})`}</p>
					);
				},
			},
		},
		{
			name: 'allocated_weight',
			label: 'ALLOCATED WEIGHT',
			options: {
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <p>{`${value}%`}</p>;
				},
			},
		},
		{
			name: 'self_review_score',
			label: 'SELF REVIEW SCORE',
		},
		{
			name: 'self_rating',
			label: 'SELF RATING',
		},
		{
			name: 'manager_review_score',
			label: 'Manager REVIEW SCORE',
		},
		{
			name: 'manager_rating',
			label: 'Manager RATING',
		},
	];

	const options: MUIDataTableOptions = {
		filter: false,
		responsive: 'standard',
		print: false,
		searchAlwaysOpen: false,
		search: false,
		serverSide: true,
		viewColumns: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		selectableRowsHeader: false,
		selectableRowsHideCheckboxes: true,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [5, 10, 20, 30],
		download: false,
		page: page,
		textLabels: {
			body: {
				noMatch: isAppraisalDetailLoading ? <Loader /> : 'No data found',
			},
		},
		count: appraisalDetail?.userCount.aggregate?.count || 0,
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
	};
	const handleExport = () => {
		if (isEmpty(appraisalCycleForExport?.pms_pms_appraisal_user_mapping)) {
			return;
		}
		const appraisalCycleData =
			appraisalCycleForExport?.pms_pms_appraisal_user_mapping.map(
				(pmsUserMapping) => {
					const selfReviewScore =
						head(pmsUserMapping.appraisalRatings)?.self_rating_value || 0;

					const selfReviewRating =
						head(pmsUserMapping.appraisalRatings)?.self_rating || '- -';

					const mangerReviewScore =
						head(pmsUserMapping.appraisalRatings)?.manager_rating_value || 0;
					const mangerReviewRating =
						head(pmsUserMapping.appraisalRatings)?.manager_rating || '- -';
					const existingEmployeeId =
						pmsUserMapping.user?.employee?.emp_id || '';
					const existingContractorId =
						head(pmsUserMapping.user?.contractors)?.contractor_id || '';
					const formattedContractorId = formatEmployeeId(
						existingContractorId,
						empIdLength,
						isFixedEmpIdLengthEnabled
					);
					const formattedEmployeeId = formatEmployeeId(
						existingEmployeeId,
						empIdLength,
						isFixedEmpIdLengthEnabled
					);
					const isEmployee =
						pmsUserMapping.user?.user_type === 'employee' ? true : false;
					const empId = isEmployee
						? pmsUserMapping.user?.employee?.emp_id
							? `${
									getPrefix(
										pmsUserMapping.user?.employee?.employee_prefix?.name,
										pmsUserMapping.user?.employee?.employee_prefix?.name
									) || ''
							  }${formattedEmployeeId || ''}`
							: '--'
						: head(pmsUserMapping.user?.contractors)?.contractor_id
						? `${
								getPrefix(
									head(pmsUserMapping.user?.contractors)?.contractor_prefix
										?.name,
									head(pmsUserMapping.user?.contractors)?.contractor_prefix
										?.name
								) || ''
						  }${
								pmsUserMapping.user?.contractors &&
								head(pmsUserMapping.user?.contractors)?.contractor_id
									? formattedContractorId
									: ''
						  }`
						: '--';
					const designation = isEmployee
						? pmsUserMapping.user?.employee?.designation?.title || '--'
						: head(pmsUserMapping.user?.contractors)?.designation?.title ||
						  '--';
					const department = isEmployee
						? pmsUserMapping.user?.employee?.department?.name || '--'
						: head(pmsUserMapping.user?.contractors)?.department?.name || '--';

					const location = isEmployee
						? pmsUserMapping.user?.employee?.org_location?.name || '--'
						: head(pmsUserMapping.user?.contractors)?.org_location?.name ||
						  '--';
					const jobLevel = isEmployee
						? pmsUserMapping?.user?.employee?.job_level?.level || '--'
						: head(pmsUserMapping?.user?.contractors)?.job_level?.level || '--';
					return [
						empId,
						pmsUserMapping.user?.full_name || '- -',
						pmsUserMapping.user?.email,
						designation,
						jobLevel,
						department,
						location,
						pmsUserMapping.userByReviewer?.full_name || '- -',
						pmsUserMapping.pms_user_appraisal_status?.label || '- -',
						pmsUserMapping.user?.user_type === 'employee'
							? pmsUserMapping.user?.employee?.reportsTo?.full_name || '- -'
							: pmsUserMapping.user?.contractors[0]?.reportee?.full_name ||
							  '- -',
						pmsUserMapping.user.appraisalGoalMappings.reduce((sum, value) => {
							return value.weightage + sum;
						}, 0) || 0,
						Number(selfReviewScore.toFixed(1)),
						selfReviewRating || '- -',
						Number(mangerReviewScore.toFixed(1)),
						mangerReviewRating || '- -',
						pmsUserMapping.user?.appraisalFeedbacks[0]?.self_comment || '- -',
						pmsUserMapping.user?.appraisalFeedbacks[0]?.reviewer_comment ||
							'- -',
					];
				}
			);
		if (
			filterState?.rating_id &&
			ratingFilterUserList?.pms_pms_appraisal_feedback
		) {
			const userIdList = ratingFilterUserList?.pms_pms_appraisal_feedback?.map(
				(user) => user?.user?.id
			);
			const filteredUsersAppraisalCycleData = appraisalCycleData
				?.filter((user) => userIdList?.includes(user[0]))
				.map((item) => item.slice(1));
			const exportData = [
				APPRAISAL_CYCLE_INSIGHT_EXPORT_COLUMNS,
				...[...(filteredUsersAppraisalCycleData || [])],
			];
			exportToCsv(`Employee_Appraisal_Report.csv`, exportData);
			return;
		}
		const exportData = [
			APPRAISAL_CYCLE_INSIGHT_EXPORT_COLUMNS,
			...[...(appraisalCycleData || [])],
		];
		exportToCsv(`Employee_Appraisal_Report.csv`, exportData);
	};
	const disableButton = () => {
		setButtonDisabled(false);
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);

	const onExportClick = () => {
		if (!isButtonDisabled) {
			setButtonDisabled(true);
			handleExport();
			preventClick();
		}
	};
	const getTableData = () => {
		if (!appraisalDetail) return [];

		const userData = appraisalDetail.pms_pms_appraisal_user_mapping.map(
			(pmsUserMapping) => {
				const selfReviewScore =
					head(pmsUserMapping.appraisalRatings)?.self_rating_value || 0;

				const selfReviewRating =
					head(pmsUserMapping.appraisalRatings)?.self_rating || '- -';

				const mangerReviewScore =
					head(pmsUserMapping.appraisalRatings)?.manager_rating_value || 0;
				const mangerReviewRating =
					head(pmsUserMapping.appraisalRatings)?.manager_rating || '- -';

				return {
					name: pmsUserMapping.user.full_name || '- -',
					email: pmsUserMapping.user.email,
					reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
					review_status:
						pmsUserMapping.pms_user_appraisal_status?.label || '- -',
					reporting_manager:
						pmsUserMapping.user.user_type === 'employee'
							? pmsUserMapping.user.employee?.reportsTo?.full_name || '- -'
							: pmsUserMapping.user.contractors[0].reportee?.full_name || '- -',
					userId: pmsUserMapping.user.id,
					allocated_weight:
						pmsUserMapping.user.appraisalGoalMappings.reduce((sum, value) => {
							return value.weightage + sum;
						}, 0) || 0,
					goals: {
						goals: pmsUserMapping.user.appraisalGoalMappings,
						user: {
							user: pmsUserMapping.user,
							reviewer: pmsUserMapping.userByReviewer,
							userAppraisalStatus:
								pmsUserMapping.pms_user_appraisal_status.value,
						},
					},
					self_review_score: Number(selfReviewScore.toFixed(1)),
					self_rating: selfReviewRating || '- -',
					manager_review_score: Number(mangerReviewScore.toFixed(1)),
					manager_rating: mangerReviewRating || '- -',
				};
			}
		);
		if (
			filterState?.rating_id &&
			ratingFilterUserList?.pms_pms_appraisal_feedback
		) {
			const userList = ratingFilterUserList?.pms_pms_appraisal_feedback?.map(
				(user) => user?.user?.id
			);
			const filteredUsers = userData?.filter((user) =>
				userList?.includes(user?.userId)
			);
			return filteredUsers || [];
		}
		return userData;
	};

	return (
		<div>
			<p className={appraisalDetailsStyles.listHeading}>Appraisal Report</p>
			<div
				style={{
					display: 'flex',
					alignItems: 'end',
					justifyContent: 'space-between',
					width: '50%',
					margin: '10px 0px 10px 0px',
				}}
			>
				<Form onSubmit={() => {}}>
					{() => (
						<form>
							<Box display={'flex'} alignContent={'center'}>
								{/* Department */}
								<div>
									<p className={formStyles.label}>Department</p>
									<Field name='department_id'>
										{(props: any) => (
											<ThemeProvider theme={autoCompleteTheme}>
												<div className={appraisalInsightStyles.filterDropDown}>
													<Autocomplete
														id='department_id'
														classes={{
															option: autoCompleteSearchStyles.options,
															groupLabel: autoCompleteSearchStyles.groupLabel,
															inputRoot: autoCompleteSearchStyles.inputRoot,
															noOptions: autoCompleteSearchStyles.noOptions,
															focused: autoCompleteSearchStyles.focused,
															input: autoCompleteSearchStyles.input,
															endAdornment:
																autoCompleteSearchStyles.endAdornment,
														}}
														getOptionSelected={(option, value) =>
															option.id === props.input.value
														}
														onChange={(event, newValue) => {
															props.input.onChange(newValue?.id);
															setChartFilterState({
																...chartFilterState,
																department_id: newValue,
															});
														}}
														value={filterState.department_id}
														options={
															filterOptions?.department.map((department) => ({
																id: department.id,
																name: department.name,
															})) || []
														}
														getOptionLabel={(option) => option?.name || ''}
														renderInput={(params) => (
															<TextField
																{...params}
																label=''
																placeholder='Search Department'
																InputProps={{
																	...params.InputProps,
																	startAdornment: (
																		<InputAdornment
																			position='start'
																			classes={{
																				root: autoCompleteSearchStyles.inputAdronment,
																				positionStart:
																					autoCompleteSearchStyles.inputAdronmentPositionStart,
																			}}
																		>
																			<SearchIcon
																				className={
																					autoCompleteSearchStyles.searchIconPositionStart
																				}
																			/>
																		</InputAdornment>
																	),
																}}
															/>
														)}
													/>
												</div>
											</ThemeProvider>
										)}
									</Field>
								</div>
							</Box>
						</form>
					)}
				</Form>
				<div></div>
			</div>
			<div className={appraisalInsightStyles.chartContainer}>
				<PMSAppraisalBellCurveChart
					data={getBellCurveChartData()}
					totalEmployees={chartData?.pms_pms_appraisal_user_mapping.length || 0}
				/>

				<PMSBarChart
					data={getChartData()}
					totalEmployees={chartData?.pms_pms_appraisal_user_mapping.length || 0}
				/>
			</div>
			<div
				className={appraisalDetailsStyles.listContainer}
				style={{
					marginTop: '20px',
				}}
			>
				<Box display={'flex'} alignItems={'center'}>
					<p className={appraisalDetailsStyles.listHeading}>Employees Report</p>
					{isAppraisalDetailLoading && (
						<Box>
							<Loader />
						</Box>
					)}
				</Box>
				<Box display='flex' justifyContent='space-between' marginBottom='20px'>
					<Box display='flex' alignItems='end' sx={{ gridGap: '8px' }}>
						<Box>
							<CustomSearchRender
								searchText={searchedText}
								setSearchedText={(val: string) => {
									setSearchedText(val);
									setPage(0);
								}}
							/>
						</Box>

						<Form onSubmit={() => {}}>
							{() => (
								<form>
									<Box display={'flex'} alignContent={'center'}>
										{/* Department */}
										<div>
											<p className={formStyles.label}>Department</p>
											<Field name='department_id'>
												{(props: any) => (
													<ThemeProvider theme={autoCompleteTheme}>
														<div
															className={appraisalInsightStyles.filterDropDown}
														>
															<Autocomplete
																id='department_id'
																classes={{
																	option: autoCompleteSearchStyles.options,
																	groupLabel:
																		autoCompleteSearchStyles.groupLabel,
																	inputRoot: autoCompleteSearchStyles.inputRoot,
																	noOptions: autoCompleteSearchStyles.noOptions,
																	focused: autoCompleteSearchStyles.focused,
																	input: autoCompleteSearchStyles.input,
																	endAdornment:
																		autoCompleteSearchStyles.endAdornment,
																}}
																getOptionSelected={(option, value) =>
																	option.id === props.input.value
																}
																onChange={(event, newValue) => {
																	props.input.onChange(newValue?.id);
																	setFilterState({
																		...filterState,
																		department_id: newValue,
																	});
																}}
																value={filterState.department_id}
																options={
																	filterOptions?.department.map(
																		(department) => ({
																			id: department.id,
																			name: department.name,
																		})
																	) || []
																}
																getOptionLabel={(option) => option?.name || ''}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label=''
																		placeholder='Search Department'
																		InputProps={{
																			...params.InputProps,
																			startAdornment: (
																				<InputAdornment
																					position='start'
																					classes={{
																						root: autoCompleteSearchStyles.inputAdronment,
																						positionStart:
																							autoCompleteSearchStyles.inputAdronmentPositionStart,
																					}}
																				>
																					<SearchIcon
																						className={
																							autoCompleteSearchStyles.searchIconPositionStart
																						}
																					/>
																				</InputAdornment>
																			),
																		}}
																	/>
																)}
															/>
														</div>
													</ThemeProvider>
												)}
											</Field>
										</div>

										{/* Appraisal Rating */}
										<div>
											<p className={formStyles.label}>Manager Rating</p>
											<Field name='rating_id'>
												{(props: any) => (
													<ThemeProvider theme={autoCompleteTheme}>
														<div
															className={appraisalInsightStyles.filterDropDown}
														>
															<Autocomplete
																id='rating_id'
																classes={{
																	option: autoCompleteSearchStyles.options,
																	groupLabel:
																		autoCompleteSearchStyles.groupLabel,
																	inputRoot: autoCompleteSearchStyles.inputRoot,
																	noOptions: autoCompleteSearchStyles.noOptions,
																	focused: autoCompleteSearchStyles.focused,
																	input: autoCompleteSearchStyles.input,
																	endAdornment:
																		autoCompleteSearchStyles.endAdornment,
																}}
																getOptionSelected={(option, value) =>
																	option.id === props.input.value
																}
																onChange={(event, newValue) => {
																	props.input.onChange(newValue?.id);
																	setFilterState({
																		...filterState,
																		rating_id: newValue,
																	});
																}}
																value={filterState.rating_id}
																options={
																	filterOptions?.pms_pms_appraisal_rating_scale.map(
																		(ratingScale) => ({
																			id: ratingScale.id,
																			name: ratingScale.label,
																			min: ratingScale.min,
																			max: ratingScale.max,
																		})
																	) || []
																}
																getOptionLabel={(option) => option?.name || ''}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label=''
																		placeholder='Search Rating'
																		InputProps={{
																			...params.InputProps,
																			startAdornment: (
																				<InputAdornment
																					position='start'
																					classes={{
																						root: autoCompleteSearchStyles.inputAdronment,
																						positionStart:
																							autoCompleteSearchStyles.inputAdronmentPositionStart,
																					}}
																				>
																					<SearchIcon
																						className={
																							autoCompleteSearchStyles.searchIconPositionStart
																						}
																					/>
																				</InputAdornment>
																			),
																		}}
																	/>
																)}
															/>
														</div>
													</ThemeProvider>
												)}
											</Field>
										</div>

										{/* Appraisal Status */}
										<div>
											<p className={formStyles.label}>Status</p>
											<Field name='status_id'>
												{(props: any) => (
													<ThemeProvider theme={autoCompleteTheme}>
														<div
															className={appraisalInsightStyles.filterDropDown}
														>
															<Autocomplete
																id='status_id'
																classes={{
																	option: autoCompleteSearchStyles.options,
																	groupLabel:
																		autoCompleteSearchStyles.groupLabel,
																	inputRoot: autoCompleteSearchStyles.inputRoot,
																	noOptions: autoCompleteSearchStyles.noOptions,
																	focused: autoCompleteSearchStyles.focused,
																	input: autoCompleteSearchStyles.input,
																	endAdornment:
																		autoCompleteSearchStyles.endAdornment,
																}}
																getOptionSelected={(option, value) =>
																	option.id === props.input.value
																}
																onChange={(event, newValue) => {
																	props.input.onChange(newValue?.id);
																	setFilterState({
																		...filterState,
																		status_id: newValue,
																	});
																}}
																value={filterState.status_id}
																options={
																	filterOptions?.pms_pms_user_appraisal_status.map(
																		(status) => ({
																			id: status.id,
																			name: status.label,
																		})
																	) || []
																}
																getOptionLabel={(option) => option?.name || ''}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label=''
																		placeholder='Search Status'
																		InputProps={{
																			...params.InputProps,
																			startAdornment: (
																				<InputAdornment
																					position='start'
																					classes={{
																						root: autoCompleteSearchStyles.inputAdronment,
																						positionStart:
																							autoCompleteSearchStyles.inputAdronmentPositionStart,
																					}}
																				>
																					<SearchIcon
																						className={
																							autoCompleteSearchStyles.searchIconPositionStart
																						}
																					/>
																				</InputAdornment>
																			),
																		}}
																	/>
																)}
															/>
														</div>
													</ThemeProvider>
												)}
											</Field>
										</div>

										{/* Project */}
										{permissions?.appPermissions?.ui?.viewSubAccountDetails && (
											<div>
												<p className={formStyles.label}>Project</p>
												<Field name='project_id'>
													{(props: any) => (
														<ThemeProvider theme={autoCompleteTheme}>
															<div
																className={
																	appraisalInsightStyles.filterDropDown
																}
															>
																<Autocomplete
																	id='project_id'
																	classes={{
																		option: autoCompleteSearchStyles.options,
																		groupLabel:
																			autoCompleteSearchStyles.groupLabel,
																		inputRoot:
																			autoCompleteSearchStyles.inputRoot,
																		noOptions:
																			autoCompleteSearchStyles.noOptions,
																		focused: autoCompleteSearchStyles.focused,
																		input: autoCompleteSearchStyles.input,
																		endAdornment:
																			autoCompleteSearchStyles.endAdornment,
																	}}
																	getOptionSelected={(option, value) =>
																		option.id === props.input.value
																	}
																	onChange={(event, newValue) => {
																		props.input.onChange(newValue?.id);
																		setFilterState({
																			...filterState,
																			project_id: newValue,
																		});
																	}}
																	value={filterState.project_id}
																	options={accountProjectsOptions || []}
																	getOptionLabel={(option) =>
																		option?.name || ''
																	}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label=''
																			placeholder='Search Project'
																			InputProps={{
																				...params.InputProps,
																				startAdornment: (
																					<InputAdornment
																						position='start'
																						classes={{
																							root: autoCompleteSearchStyles.inputAdronment,
																							positionStart:
																								autoCompleteSearchStyles.inputAdronmentPositionStart,
																						}}
																					>
																						<SearchIcon
																							className={
																								autoCompleteSearchStyles.searchIconPositionStart
																							}
																						/>
																					</InputAdornment>
																				),
																			}}
																		/>
																	)}
																/>
															</div>
														</ThemeProvider>
													)}
												</Field>
											</div>
										)}
										<Box
											display='flex'
											marginTop={'23px'}
											sx={{ gridGap: '8px' }}
										>
											<Box>
												<Box>
													<Button
														className={
															isEmpty(getTableData()) || isButtonDisabled
																? dataGridStyles.disabledExportButton
																: dataGridStyles.exportButton
														}
														endIcon={<ArrowUpwardIcon />}
														disabled={isEmpty(getTableData())}
														onClick={onExportClick}
														startIcon={
															isButtonDisabled && (
																<CircularProgress
																	style={{
																		height: '20px',
																		width: '20px',
																		color: '#FFFFFF',
																	}}
																/>
															)
														}
													>
														Export
													</Button>
												</Box>
											</Box>
										</Box>
									</Box>
								</form>
							)}
						</Form>
					</Box>
				</Box>
				<div className={dataGridStyles.horizontalScrollWrapper}>
					<ThemeProvider theme={DataGridTheme}>
						<MUIDataTable
							title='Appraisal Users'
							data={getTableData()}
							columns={columns}
							options={options}
						/>
					</ThemeProvider>
				</div>
			</div>
			<ViewGoals
				open={isViewGoalsModalShown}
				onClose={() => setIsViewGoalsModalShown(false)}
				userDetails={selectedViewGoals.userDetails}
				goals={selectedViewGoals.goals || []}
				refetch={() => {
					refetchAppraisalDetails();
				}}
				disabled={true}
			/>
		</div>
	);
}

export default TeamCycleInsights;
