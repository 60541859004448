import React, { useState } from 'react';
import {
	Typography,
	makeStyles,
	Box,
	Tabs,
	Tab,
	Theme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	ThemeProvider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { accordionMenuTheme } from '../../../Layout/Theme.component';
import PMSCommentSetting from './GeneralSettings';

const useStyles = makeStyles((theme: Theme) => ({
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '10px',
	},
	container: {
		flexGrow: 1,
		display: 'flex',
		height: 224,
	},
	menuContainer: {
		width: '248px',
		maxHeight: 'fit-content',
		background: '#ECF1F4',
		borderRadius: '4px',
		padding: '12px',
	},
	contentContainer: {
		borderRadius: '4px',
		width: '100%',
		overflowY: 'auto',
		maxHeight: '82vh',
		overflowX: 'hidden',
		'& > div > div': {
			padding: '0px',
		},
	},
}));

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}
function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} width='100%'>
					{children}
				</Box>
			)}
		</div>
	);
}
function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}
interface ExpansionDetails {
	isOrganisationExpanded?: boolean;
}
export const Settings = () => {
	const classes = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
	const [initialExpansion, setInitialExpansion] = useState<ExpansionDetails>({
		isOrganisationExpanded: true,
	});
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTabIndex(newValue);
	};
	const getSelectedIndexWithinTabRange = (
		indexes: number[],
		selectedIndex: number
	) => {
		return indexes.includes(selectedIndex) ? selectedIndex : false;
	};
	return (
		<>
			<Typography className={classes.pageTitle}>Settings</Typography>
			<Box className={classes.container}>
				<ThemeProvider theme={accordionMenuTheme}>
					<Box className={classes.menuContainer}>
						<Accordion
							expanded={initialExpansion.isOrganisationExpanded}
							onChange={() => {
								setInitialExpansion({
									...initialExpansion,
									isOrganisationExpanded:
										!initialExpansion.isOrganisationExpanded,
								});
							}}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>PMS</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Tabs
									orientation='vertical'
									value={getSelectedIndexWithinTabRange(
										[0, 1, 2, 3],
										selectedTabIndex
									)}
									onChange={handleChange}
									TabIndicatorProps={{
										style: {
											display: 'none',
										},
									}}
								>
									<Tab value={0} label='General' {...a11yProps(0)} />
								</Tabs>
							</AccordionDetails>
						</Accordion>
					</Box>
				</ThemeProvider>
				<Box className={classes.contentContainer}>
					<TabPanel value={selectedTabIndex} index={0}>
						<PMSCommentSetting />
					</TabPanel>
				</Box>
			</Box>
		</>
	);
};

export default Settings;
