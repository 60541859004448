import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab } from '@material-ui/core';
import { Project } from '../../ResourcePlanner/ResourcePlanner.model';
import ProjectDetailList from './ProjectDetailList.component';
import ProjectSkill from '../../ResourcePlanner/Details/ProjectSkill.component';
import ProjectMilestoneList from '../../ResourcePlanner/Details/ProjectMilestoneList.component';
import DocumentUploader from '../../ResourcePlanner/Details/DocumentUploader.component';
import { AddAllocation } from '../../ResourcePlanner/Details/AddAllocation';
import ProjectHistory from './ProjectHistory.component';
import { ProjectCommentsTab } from './Comments/ProjectCommentList.component';

const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme : any) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: '10px',
		marginLeft: theme.spacing(1.8),
		fontFamily: 'Manrope-Medium',
		fontSize: '12px !important',
		letterSpacing: '0px',
		color: '#898989 ',
		'&$selected': {
			color: '#292929',
			fontFamily: 'Manrope-Bold',
		},
	},
}))((props: any) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '584px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));

export const ProjectDetailsContainer = ({
	project,
	refetchProject,
}: {
	project: Project;
	refetchProject: () => void;
}) => {
	const classes = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [projectDetails, setProjectDetails] = useState({});

	const handleChange = (event: any, newValue: number) => {
		setSelectedTabIndex(newValue);
	};

	useEffect(() =>{
		setProjectDetails({
			created_at: project?.created_at,
			createdBy: project?.userByCreatedByUser,
			id: project?.id,
		})
	},[project])

	return (
		<Box className={classes.root}>
			<CustomTabs value={selectedTabIndex} onChange={handleChange}>
				<CustomTab key={0} label='Details' />
				<CustomTab key={1} label='Resources' />
				<CustomTab key={2} label='Demand' />
				<CustomTab key={3} label='Milestones' />
				<CustomTab key={4} label='Documents' />
				<CustomTab key={5} label='Notes' />
				<CustomTab key={6} label='History' />
			</CustomTabs>

			<CustomTabPanel value={selectedTabIndex} index={0}>
				<ProjectDetailList />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={1}>
				<AddAllocation
					projectId={project?.id}
					start_date={project?.start_date}
					end_date={project?.end_date}
					budget={project?.total_cost}
					currencySymbol={project?.currency?.symbol}
					refetchProject={() => {
						refetchProject();
					}}
					effort_estimate = {project?.effort_estimate}
				/>
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={2}>
				<ProjectSkill />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={3}>
				<ProjectMilestoneList />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={4}>
				<DocumentUploader />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={5}>
				<ProjectCommentsTab projectId={project?.id || null} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={6}>
				<ProjectHistory record={projectDetails}/>
			</CustomTabPanel>
		</Box>
	);
};

export default ProjectDetailsContainer;
