import React, { useState } from 'react';

import {
	Button,
	Dialog,
	DialogContent,
	ThemeProvider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BooleanInput, TextInput, useNotify, maxLength } from 'react-admin';
import { modalFormTheme } from '../../../Layout/Theme.component';

import { Form } from 'react-final-form';
import { formStyle } from '../pms.styles';
import { useInsertMasterGoalsMutation } from '../../../generated/graphql';
import { GOAL_CREATED, GOAL_UPDATED } from '../constant';
import { GoalFormInitialValues } from '../pms.module';
import {
	CREATE_GOAL_BUTTON_LABEL,
	GOAL_VALUE_CHANGE_CONFIRMATION_TEXT,
	REGEX_FAILED_ERROR_TEXT,
	regexNameValidation,
	UNABLE_TO_CHANGE_GOAL_IS_ACTIVE_VALUE_MSG,
	UPDATE_GOAL_BUTTON_LABEL,
	BLUE_CIRCULAR_PROGRESS_LOADER,
} from './constant';
import WarningConfirmationModal from '../../../SharedComponents/WarningConfirmationModal.component';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import Loader from '../SharedComponents/Loader.component';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';

interface GoalFormProps {
	onClose: () => void;
	open: boolean;
	initialValues?: GoalFormInitialValues | null;
	onSuccess: () => void;
	hasMappedUser: boolean;
	goalCategoryOptions: { id: any; name: string }[];
}

interface GoalFormValues {
	name: string;
	description: string;
	goal_category_id: string;
	is_active: boolean;
}
function GoalForm(props: GoalFormProps) {
	const {
		open,
		onClose,
		onSuccess,
		goalCategoryOptions,
		hasMappedUser,
		initialValues,
	} = props;
	const formStyles = formStyle();
	const notify = useNotify();

	// State
	const [isWarningConfirmationModalShown, setIsWarningConfirmationModalShown] =
		useState<boolean>(false);
	const [isInfoModalShown, setIsInfoModalShown] = useState<boolean>(false);

	// API
	const [insertGoals, { loading: isGoalMutationLoading }] =
		useInsertMasterGoalsMutation();

	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return REGEX_FAILED_ERROR_TEXT;
		}
		return undefined;
	};

	const handleSubmit = (formValues: GoalFormValues) => {
		if (!formValues) return;
		const { name, description, ...restFormValues } = formValues;
		insertGoals({
			variables: {
				data: [
					initialValues?.id
						? {
								id: initialValues?.id,
								name: name?.trim(),
								description: description?.trim(),
								...restFormValues,
						  }
						: {
								name: name?.trim(),
								description: description?.trim(),
								...restFormValues,
						  },
				],
			},
		}).then((response: any) => {
			if (!response.error) {
				notify(initialValues?.id ? GOAL_UPDATED : GOAL_CREATED);
				onSuccess();
				onClose();
			}
		});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={'sm'}
				disableBackdropClick
			>
				<div className={formStyles.container}>
					<div className={formStyles.headerContainer}>
						<p className={formStyles.heading}>
							{initialValues?.id ? `Edit Goal` : `Create Goal`}
						</p>
						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</div>
					<Form
						initialValues={
							initialValues?.id ? { ...initialValues } : { is_active: true }
						}
						onSubmit={handleSubmit}
					>
						{({ handleSubmit, invalid, pristine, form }) => (
							<>
								<form onSubmit={handleSubmit}>
									<div>
										<DialogContent>
											<p className={formStyles.label}>Name*</p>
											<TextInput
												label=''
												source='name'
												validate={[validateName, maxLength(120)]}
											/>

											<p className={formStyles.label}>Category</p>
											<AutocompleteSearch
												name={'goal_category_id'}
												onChange={(id) => {
													const selectedOption: any =
														goalCategoryOptions.find(
															(option) => option.id === id
														) || null;
													form.change('goal_category_id', selectedOption);
												}}
												placeholder={'Search Category'}
												value={
													goalCategoryOptions.find(
														(option) =>
															option.id ===
															form.getState().values.goal_category_id
													) || null
												}
												option={goalCategoryOptions || []}
											/>

											<div className={formStyles.multipleInput}>
												<p className={formStyles.label}>Is Active</p>
												<BooleanInput
													source='is_active'
													label=''
													onChange={(value: boolean) => {
														if (hasMappedUser) {
															form.change('is_active', !value);
															setIsInfoModalShown(true);
															return;
														}
														setIsWarningConfirmationModalShown(true);
													}}
												/>
											</div>

											<p className={formStyles.label}>Description*</p>
											<TextInput
												source='description'
												fullWidth={true}
												label={''}
												multiline
												validate={[validateName, maxLength(500)]}
											/>
											<div className={formStyles.buttonContainer}>
												<Button
													className={formStyles.cancelButton}
													onClick={onClose}
												>
													Cancel
												</Button>
												<Button
													type='submit'
													disabled={
														invalid || pristine || isGoalMutationLoading
													}
													className={
														invalid || pristine || isGoalMutationLoading
															? formStyles.disabledButton
															: formStyles.saveButton
													}
													startIcon={
														isGoalMutationLoading && (
															<Loader color={BLUE_CIRCULAR_PROGRESS_LOADER} />
														)
													}
												>
													{initialValues?.id
														? UPDATE_GOAL_BUTTON_LABEL
														: CREATE_GOAL_BUTTON_LABEL}
												</Button>
											</div>
										</DialogContent>
									</div>
								</form>
								<WarningConfirmationModal
									open={isWarningConfirmationModalShown}
									onClose={() => {
										const currentIsActive = form.getState()?.values?.is_active;
										form.change('is_active', !currentIsActive);
										setIsWarningConfirmationModalShown(false);
									}}
									warningMessage={GOAL_VALUE_CHANGE_CONFIRMATION_TEXT}
									onSubmit={() => {
										setIsWarningConfirmationModalShown(false);
									}}
								/>
							</>
						)}
					</Form>
				</div>
			</Dialog>
			<WarningModal
				open={isInfoModalShown}
				onClose={() => {
					setIsInfoModalShown(false);
				}}
				warningMessage={UNABLE_TO_CHANGE_GOAL_IS_ACTIVE_VALUE_MSG}
			/>
		</ThemeProvider>
	);
}

export default GoalForm;
