import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Typography,
	Button,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { modalFormStyle } from '../../../Layout/styles';
import { modalFormTheme } from '../../../Layout/Theme.component';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { AutocompleteArrayInput, required, useNotify } from 'react-admin';
import {
	useGetRecipientListQuery,
	useGetUserEmailGroupQuery,
	useUpdatePmsEmailReceiversListMutation,
} from '../../../generated/graphql';
import {
	EMAIL_RECIPIENT_SAVE_FAILED,
	RECIPIENT_SAVED_SUCCESS,
} from '../constant';
const mappedListModalStyle = makeStyles({
	content: {
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginRight: '10px',
	},
	Container: {
		borderBottom: '1px solid #E0E0E0',
		padding: '8px',
		display: 'flex',
		alignItems: 'center',
	},
	name: {
		color: '#292929',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		marginRight: '10px',
		maxWidth: '44%',
	},
	email: {
		color: '#5C5C5C',
		fontSize: '10px !important',
		fontFamily: 'Manrope-medium',
		marginRight: '10px',
		maxWidth: '44%',
	},
	container: {
		width: '442px',
		height: '250x',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
	},

	editIcon: {
		width: '12px',
		height: '12px',
		marginRight: '255px',
	},
	timesheetDeadline: {
		margin: '7px 12px 0 10px',
		fontFamily: 'Manrope-semibold',
	},
});

interface Recipient {
	receiverList: string[] | undefined;
}
export interface AutocompleteOptions {
	id: string;
	name: string;
}

interface MappedListModalProps {
	onClose: () => void;
	open: boolean;
	name: string;
	onSuccess: () => void;
	emailNotificationId: string | null | undefined;
	emailNotificationReceiver: string[] | undefined;
}

const PmsRecipientListModal = (props: MappedListModalProps) => {
	const {
		open,
		onClose,
		onSuccess,
		name,
		emailNotificationId,
		emailNotificationReceiver,
	} = props;
	const formStyles = modalFormStyle();
	const notify = useNotify();
	const mappedListModalStyles = mappedListModalStyle();
	const classes = modalFormStyle();
	const [source, setSource] = useState<AutocompleteOptions[] | undefined>();
	const [updateEmailReceiversList] = useUpdatePmsEmailReceiversListMutation();
	const { data: emailUserGroup } = useGetUserEmailGroupQuery({
		variables: {
			name: `%${''}%`,
		},
		fetchPolicy: 'network-only',
	});
	const { data: role } = useGetRecipientListQuery({
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		const emailGroup =
			emailUserGroup?.user_email_group?.map((value) => {
				return { id: value?.id, name: `${value?.name} (g)` };
			}) ?? [];

		const roles =
			role?.role?.map((value) => {
				return { id: value?.id, name: value?.name };
			}) ?? [];

		const newRole = [...roles, ...emailGroup];

		setSource(newRole);
	}, [emailUserGroup, role]);

	const onHandleSubmit = (receivers: Recipient) => {
		updateEmailReceiversList({
			variables: {
				id: emailNotificationId,
				receiverList: receivers.receiverList || [],
			},
		})
			.then(() => {
				notify(RECIPIENT_SAVED_SUCCESS);
				onSuccess();
				onClose();
			})
			.catch((error: any) => {
				notify(EMAIL_RECIPIENT_SAVE_FAILED, 'warning');
				return;
			});
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={mappedListModalStyles.container}>
					<Box className={formStyles.headerContainer} marginBottom={'20px'}>
						<Typography className={formStyles.heading}>
							{name ? name : ''}
						</Typography>
						<Tooltip title='(g) indicates user email group' placement='left'>
							<IconButton className={mappedListModalStyles.iconContainer}>
								<InfoIcon className={mappedListModalStyles.editIcon} />
							</IconButton>
						</Tooltip>

						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</Box>

					<Form
						initialValues={{ receiverList: emailNotificationReceiver }}
						onSubmit={onHandleSubmit}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<Box>
									<AutocompleteArrayInput
										source='receiverList'
										onChange={() => {}}
										label=''
										choices={source}
										validate={required('')}
									/>
								</Box>
								<Box className={classes.buttonContainer} style={{ padding: 0 }}>
									<Button type='submit' className={classes.saveButton}>
										SAVE
									</Button>
								</Box>
							</form>
						)}
					</Form>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default PmsRecipientListModal;
